import { Values } from '../types';

export const tabs = {
  results: 'Results',
  liked: 'Liked',
  export: 'Export',
  history: 'History',
  writeForMe: 'Write For Me',
  versionHistory: 'Version History',
  editorSettings: 'Editor Settings',
} as const;
export type PlaygroundTabTypes = Values<typeof tabs>;

export const exportTypes = {
  markdown: 'Markdown (.md)',
  html: 'Html (.html)',
  docx: 'Word Document (.docx)',
};

export type ExportTypes = Values<typeof exportTypes>;

export const outputLengths = {
  short: 'short',
  medium: 'medium',
  long: 'long',
  ultra: 'ultra',
} as const;
export type OutputLengthTypes = Values<typeof outputLengths>;

export function maxTokenLength(lengthType: OutputLengthTypes): number {
  switch (lengthType) {
    case outputLengths.long:
      return 150;
    case outputLengths.medium:
      return 100;
    case outputLengths.short:
      return 50;
    default:
      return 300;
  }
}

export type UndoObject = { before: string; after: string };
