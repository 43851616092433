import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from 'src/assets/generalClose.svg';
import styles from './Modal.module.scss';
import { useApi } from 'src/api';
import FormInput from '../../FormInput';
import ModalWrapper from '../../ModalWrapper';

type Props = {
  setModal: (a: boolean) => void;
  nameToUpdate: string;
  projectId: string;
};

export default function UpdateProjectNameModal({
  setModal,
  nameToUpdate,
  projectId,
}: Props): JSX.Element {
  const [projectName, setProjectName] = useState(nameToUpdate);
  const api = useApi();
  const isDisabled =
    projectName.length === 0 ||
    projectName === nameToUpdate ||
    projectName.length === 0;
  function updateProjectName() {
    api.updateProjectName({ projectId, newProjectName: projectName });
    setModal(false);
  }
  return (
    <ModalWrapper setShowChildrenModal={setModal}>
      <div className={styles.Modal}>
        <div className={styles.Header}>
          <div className={styles.ModalTitle}>Rename Folder</div>
          <CloseIcon className={styles.Close} onClick={() => setModal(false)} />
        </div>
        <hr className={styles.Hr} />
        <FormInput
          input={projectName}
          onChange={e => setProjectName(e.target.value)}
          password={false}
          className={styles.FormInput}
          onKeyDown={e => {
            if (e.key === 'Enter' && !isDisabled) {
              updateProjectName();
            }
          }}
          placeholderText='Add a folder name'
          autoFocus
        />
        <hr className={styles.Hr} />
        <div className={styles.ButtonContainer}>
          <button
            className={classNames(isDisabled && styles.Disabled, styles.Button)}
            disabled={isDisabled}
            onClick={updateProjectName}
          >
            Update Name
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}
