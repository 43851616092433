import { Project } from 'src/modules/Project';
import { createActionsBuilder, ExtractActions } from './utils/request';

type GetProjectMeta = {
  projectId: string;
};
type GetProjectResponse = Project;

export const getProject = createActionsBuilder<
  GetProjectMeta,
  GetProjectResponse
>()('GET_PROJECT_PENDING', 'GET_PROJECT_SUCCESS', 'GET_PROJECT_FAILURE');
export type GetProjectActions = ExtractActions<typeof getProject>;

type GetProjectsMeta = {
  userId: string;
};
type GetProjectsResponse = Project[];

export const getProjects = createActionsBuilder<
  GetProjectsMeta,
  GetProjectsResponse
>()('GET_PROJECTS_PENDING', 'GET_PROJECTS_SUCCESS', 'GET_PROJECTS_FAILURE');
export type GetProjectsActions = ExtractActions<typeof getProjects>;

type CreateProjectMeta = {
  projectName: string;
};
type CreateProjectResponse = Project;

export const createProject = createActionsBuilder<
  CreateProjectMeta,
  CreateProjectResponse
>()(
  'CREATE_PROJECT_PENDING',
  'CREATE_PROJECT_SUCCESS',
  'CREATE_PROJECT_FAILURE',
);
export type CreateProjectActions = ExtractActions<typeof createProject>;

type UpdateProjectNameMeta = {
  newProjectName: string;
  projectId: string;
};
type UpdateProjectNameResponse = Project;

export const updateProjectName = createActionsBuilder<
  UpdateProjectNameMeta,
  UpdateProjectNameResponse
>()(
  'UPDATE_PROJECT_NAME_PENDING',
  'UPDATE_PROJECT_NAME_SUCCESS',
  'UPDATE_PROJECT_NAME_FAILURE',
);
export type UpdateProjectNameActions = ExtractActions<typeof updateProjectName>;

type DeleteProjectMeta = {
  userId: string;
  projectId: string;
};
type DeleteProjectResponse = string;

export const deleteProject = createActionsBuilder<
  DeleteProjectMeta,
  DeleteProjectResponse
>()(
  'DELETE_PROJECT_PENDING',
  'DELETE_PROJECT_SUCCESS',
  'DELETE_PROJECT_FAILURE',
);
export type DeleteProjectActions = ExtractActions<typeof deleteProject>;

export type Actions =
  | GetProjectActions
  | GetProjectsActions
  | CreateProjectActions
  | UpdateProjectNameActions
  | DeleteProjectActions;
