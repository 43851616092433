import styles from './UpgradeModal.module.scss';
import { useSelector } from 'react-redux';
import { getUser } from 'src/redux/selectors/user';
import TestimonialCard from '../../TestimonialCard';
import GoldieImg from 'src/assets/goldie.jpeg';
import RcImg from 'src/assets/rc.jpeg';
import { isSubscriptionExpired } from 'src/utils/date';
import hrefs from 'src/modules/href';
import { useHistory } from 'react-router';

type Props = {
  setCloseUpgradeModal: (a: boolean) => void;
};

export default function UpgradeModal({
  setCloseUpgradeModal,
}: Props): JSX.Element {
  const user = useSelector(getUser);
  const history = useHistory();
  return (
    <div className={styles.UpgradeModal}>
      <span className={styles.Title}>
        {isSubscriptionExpired(user?.subscription.currentPeriodEnd as string)
          ? 'Your free trial has expired!'
          : 'You have used up all of your runs!'}
      </span>
      <span className={styles.Description}>
        Upgrade to continue using CopyMuse.
      </span>

      <span className={styles.Upsell}>
        Get a 40% discount on our annual Pro subscription.There
        are 37 left. Get it today!
      </span>
      <button
        className={styles.SaveButton}
        onClick={() => history.push(hrefs.settings())}
      >
        Upgrade Now
      </button>
      <span className={styles.NotSure}>Not sure?</span>
      <div>
        <span className={styles.TestimonialHeader}>
          CopyMuse saves our customers time and money
        </span>
      </div>
      <div className={styles.Testimonials}>
        <TestimonialCard
          className={styles.Testimonial}
          name='Goldie Maanu'
          testimonial='This is a great tool with a well-thought UI, and it has become an essential part of my blog writing process.'
          job=''
          avatarImg={GoldieImg}
        />
        <TestimonialCard
          className={styles.Testimonial}
          name='RC Canlas'
          testimonial='CopyMuse allowed me to reduce my content cost by almost 80% to run my blogs.'
          job='Blogger'
          avatarImg={RcImg}
        />
      </div>

      <button
        className={styles.SignOutButton}
        onClick={() => setCloseUpgradeModal(true)}
      >
        No thanks!
      </button>
    </div>
  );
}
