import React from 'react';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import styles from './ResultCard.module.scss';
import { ReactComponent as Copy } from 'src/assets/copy.svg';
import { ReactComponent as Like } from 'src/assets/like.svg';
import { ReactComponent as Liked } from 'src/assets/liked.svg';
import { ReactComponent as MoreOfThis } from 'src/assets/moreOfThis.svg';
import { ReactComponent as Arrow } from 'src/assets/general-arrow.svg';
import { ReactComponent as Insert } from 'src/assets/insert.svg';
import { useApi } from 'src/api';
import { Result } from 'src/modules/task';
import { TaskTypes } from 'src/modules/task/tasktypes';
import ContentContainer from './ContentContainer';
import { useSelector } from 'react-redux';
import { getTask } from 'src/redux/selectors/task';
import { createNewProps } from './utils';
import { isEmptyIdea } from 'src/utils/idea';
import { getUser } from 'src/redux/selectors/user';
import { getUserId } from 'src/redux/selectors/auth';
import { UndoObject } from 'src/modules/playground';
import { selectN } from 'src/utils/selectN';

type Props = {
  isLiked: boolean;
  projectId: string;
  taskId: string;
  result: Result;
  taskType: TaskTypes;
  setIsGettingApiResponse: (a: boolean) => void;
  setContentEditable?: React.Dispatch<React.SetStateAction<string>>;
  cursorPosition?: number;
  possibleToGenerateSimilarIdeas: boolean;
  undoStackRef?: React.MutableRefObject<UndoObject[]>;
};

export default function ResultCard({
  isLiked,
  projectId,
  taskId,
  result,
  taskType,
  cursorPosition,
  possibleToGenerateSimilarIdeas,
  setIsGettingApiResponse,
  setContentEditable,
  undoStackRef,
}: Props): JSX.Element | null {
  const api = useApi();
  const userId = useSelector(getUserId);
  const user = useSelector(getUser);
  const task = useSelector(getTask(projectId)(taskId));
  const { idea, id } = result;

  // if the generated text is empty, don't feed it into component.
  if (isEmptyIdea(idea)) {
    return null;
  }
  return (
    <div className={classNames(styles.Playground, styles.ResultCard)}>
      <ContentContainer taskType={taskType} text={idea} />
      <div className={styles.Buttons}>
        {setContentEditable && (
          <>
            <Tippy content='Paste text at the bottom of the content in the editor'>
              <div
                className={styles.Button}
                onClick={() => {
                  const { idea } = result;
                  const cleanIdea = idea.trimLeft().replaceAll('Section: ', '');
                  setContentEditable(content => {
                    const newContent = content + `\n${cleanIdea}`;
                    if (undoStackRef) {
                      undoStackRef.current = [
                        ...undoStackRef.current,
                        {
                          before: content,
                          after: newContent,
                        },
                      ];
                    }
                    return newContent;
                  });
                }}
              >
                <Arrow className={classNames(styles.Icon, styles.ArrowIcon)} />
              </div>
            </Tippy>
            <Tippy content='Paste text at the cursor position'>
              <div
                className={styles.Button}
                onClick={() => {
                  if (cursorPosition) {
                    const { idea } = result;
                    const cleanIdea = idea.trim().replaceAll('Section: ', '');

                    setContentEditable(content => {
                      const newContent =
                        content.substring(0, cursorPosition) +
                        cleanIdea +
                        content.substring(cursorPosition);

                      if (undoStackRef) {
                        undoStackRef.current = [
                          ...undoStackRef.current,
                          {
                            before: content,
                            after: newContent,
                          },
                        ];
                      }
                      return newContent;
                    });
                  }
                }}
              >
                <Insert className={classNames(styles.Icon)} />
              </div>
            </Tippy>
          </>
        )}
        {possibleToGenerateSimilarIdeas && (
          <Tippy content='Generate ideas similar to this one'>
            <div
              className={styles.Button}
              onClick={() => {
                if (task) {
                  const moreOfThisPromptProps = createNewProps(task, idea);
                  setIsGettingApiResponse(true);
                  api
                    .generateIdeas({
                      projectId,
                      taskId,
                      promptProps: moreOfThisPromptProps,
                      userId: userId as string,
                      language: task.language,
                      n: selectN(moreOfThisPromptProps.type),
                    })
                    .then(() => setIsGettingApiResponse(false))
                    .catch(() => setIsGettingApiResponse(false));
                  api.updateTaskInputs({
                    taskId,
                    projectId,
                    currentInputs: { ...moreOfThisPromptProps },
                    taskType: moreOfThisPromptProps.type,
                  });
                  api.updateStatistics(
                    user?.userId as string,
                    moreOfThisPromptProps.type,
                  );
                }
              }}
            >
              <MoreOfThis className={styles.Icon} />
            </div>
          </Tippy>
        )}
        <Tippy content={isLiked ? 'Unlike' : 'Like'}>
          <div
            className={classNames(styles.Button, isLiked && styles.Liked)}
            onClick={() => {
              if (isLiked) {
                api.unlikeResult({
                  id,
                  taskId,
                  projectId,
                });
              } else {
                api.likeResult({ result, taskId, projectId });
              }
            }}
          >
            {isLiked ? (
              <Liked className={styles.Icon} />
            ) : (
              <Like className={styles.Icon} />
            )}
          </div>
        </Tippy>
        <Tippy
          trigger='click'
          content='Copied!'
          animation='fade'
          onShow={i => {
            setTimeout(() => {
              i.hide();
            }, 1500);
          }}
        >
          <Tippy content='Copy to clipboard'>
            <div
              className={styles.Button}
              onClick={() => {
                navigator.clipboard.writeText(
                  idea.charAt(0) === ' ' ? idea.slice(1) : idea,
                );
              }}
            >
              <Copy className={styles.Icon} />
            </div>
          </Tippy>
        </Tippy>
      </div>
    </div>
  );
}
