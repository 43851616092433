import {
  ConvertResponse,
  CreateCheckoutSession,
  CustomerPortalUrl,
  PlagiarismResponse,
} from './../modules/api/responseTypes';
import { GetUserByResetIdResponse } from './../redux/actions/user';
import { isTokenExpired } from 'src/utils/jwt';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';
import * as auth from 'src/redux/actions/auth';
import * as user from 'src/redux/actions/user';
import * as project from 'src/redux/actions/project';
import * as task from 'src/redux/actions/task';
import * as feedback from 'src/redux/actions/feedback';
import * as payment from 'src/redux/actions/payment';
import * as statistics from 'src/redux/actions/statistics';
import * as plagiarism from 'src/redux/actions/plagiarism';
import * as ltd from 'src/redux/actions/ltd';
import * as editorSettings from 'src/redux/actions/editorSettings';
import * as favourites from 'src/redux/actions/favourites';
import * as shared from 'src/redux/actions/shared';
import { RequestActions } from 'src/redux/actions/utils/request';
import { store } from 'src/redux';
import { api } from 'src/modules';
import { Api } from 'src/modules/api';
import { RootState, AllActions } from 'src/redux/reducers';
import { Authentication } from 'src/modules/authentication';
import { User } from 'src/modules/user';
import { getToken, getUserId } from 'src/redux/selectors/auth';
import { Project } from 'src/modules/Project';
import {
  BlogSection,
  Result,
  Task,
  UpdateWriteForMeResult,
} from 'src/modules/task';
import { Generation } from 'src/modules/statistics';
import { Ltd } from 'src/modules/ltd';
import { Languages } from 'src/modules/languages/languages';
import { ActivationCode } from 'src/modules/activationCode';
import { EditorSettings } from 'src/modules/editorSetttings';
import { Favourite } from 'src/modules/favourites';
import { SharedFolder } from 'src/modules/shared';
/**
 * Loosely typed request actions for when the exact
 * action types do not matter.
 */
type AnyRequestActions<Meta extends object, Response> = RequestActions<
  string,
  string,
  string,
  Meta,
  Response
>;

/**
 * Specialised thunk action that uses our store state
 * and a promise return type constrained to an API response.
 */
type ResponseThunk<Response> = ThunkAction<
  Promise<Response>,
  RootState,
  unknown,
  AnyAction
>;

/**
 * From the react docs about `useDispatch`:
 *   - For redux-thunk users: the return type of the returned
 *     dispatch functions for thunks is incorrect.
 *   - However, it is possible to get a correctly typed dispatch
 *     function by creating your own custom hook typed from the
 *     store's dispatch function like this:
 *     `const useThunkDispatch = () => useDispatch<typeof store.dispatch>();`
 */
const useThunkDispatch = (): ThunkDispatch<RootState, unknown, AllActions> =>
  useDispatch<typeof store.dispatch>();

/**
 * Implements the "request pattern" using the given `pending`,
 * `success` and `failure` actions.
 * - Dispatches `pending` action first
 * - Invokes `apiCall`
 * - On success, dispatches `success` with the api response
 * - On failure, dispatches `failure` with the error
 * @param actions Actions to dispatch.
 * @param apiCall Api call to invoke between pending and success/failure.
 * @returns Thunk action.
 */
export const request =
  <Meta extends object, Response>(
    actions: AnyRequestActions<Meta, Response>,
    apiCall: (meta: Meta) => Promise<Response>,
  ): ResponseThunk<Response> =>
  async (dispatch): Promise<Response> => {
    const token = getToken(store.getState());
    const userId = getUserId(store.getState());
    if (token && userId) {
      if (isTokenExpired(token)) {
        const actions = auth.refreshToken.actions({ userId });
        dispatch(actions.pending);
        try {
          const response = await api.refreshToken(userId);
          dispatch(actions.success(response));
        } catch (error) {
          dispatch(actions.failure(error as Error));
          throw error;
        }
      }
    }
    dispatch(actions.pending);
    try {
      const response = await apiCall(actions.pending.meta);
      dispatch(actions.success(response));
      return response;
    } catch (error) {
      dispatch(actions.failure(error as Error));
      throw error;
    }
  };

/**
 * Custom hook for consuming the unfinishedBiz API client while
 * automatically dispatching the appropriate redux requests
 * as part of the "request pattern".
 * @returns API client.
 */
export const useApi = (): Api => {
  const dispatch = useThunkDispatch();
  // Memoize so that components using this hook are not forced to re-render unnecessarily.
  // The returned reference need not change unless its own reference to dispatch does.
  // See https://reactjs.org/docs/hooks-reference.html#usememo
  return useMemo(
    () => ({
      signUp(signUpForm): Promise<Authentication> {
        const { email } = signUpForm;
        const actions = auth.signUp.actions({ email });
        return dispatch(request(actions, () => api.signUp(signUpForm)));
      },
      signUpAppsumo(signUpForm): Promise<Authentication> {
        const { email } = signUpForm;
        const actions = auth.signUpAppsumo.actions({ email });
        return dispatch(request(actions, () => api.signUpAppsumo(signUpForm)));
      },
      signIn(signInForm): Promise<Authentication> {
        const { email } = signInForm;
        const actions = auth.signIn.actions({ email });
        return dispatch(request(actions, () => api.signIn(signInForm)));
      },
      thirdPartySignIn(email): Promise<Authentication> {
        const actions = auth.thirdPartySignIn.actions({ email });
        return dispatch(request(actions, () => api.thirdPartySignIn(email)));
      },
      getUser(userId): Promise<User> {
        const actions = user.getUser.actions({ userId });
        return dispatch(request(actions, () => api.getUser(userId)));
      },
      getUsers(): Promise<User[]> {
        const actions = user.getUsers.actions({});
        return dispatch(request(actions, () => api.getUsers()));
      },
      getUserByEmail(email): Promise<User> {
        const actions = user.getUserByEmail.actions({ email });
        return dispatch(request(actions, () => api.getUserByEmail(email)));
      },
      getUserByResetId(resetId): Promise<GetUserByResetIdResponse> {
        const actions = user.getUserByResetId.actions({ resetId });
        return dispatch(request(actions, () => api.getUserByResetId(resetId)));
      },
      updateUser(updatedUser): Promise<User> {
        const actions = user.updateUser.actions(updatedUser);
        return dispatch(request(actions, () => api.updateUser(updatedUser)));
      },
      refreshToken(userId): Promise<string> {
        const actions = auth.refreshToken.actions({ userId });
        return dispatch(request(actions, () => api.refreshToken(userId)));
      },
      resetPassword(email): Promise<Authentication> {
        const actions = auth.resetPassword.actions({ email });
        return dispatch(request(actions, () => api.resetPassword(email)));
      },
      updateResetPassword(resetId): Promise<string> {
        const actions = auth.updateResetPassword.actions({ resetId });
        return dispatch(
          request(actions, () => api.updateResetPassword(resetId)),
        );
      },
      updatePassword(form): Promise<boolean> {
        const actions = user.updatePassword.actions({ userId: form.userId });
        return dispatch(request(actions, () => api.updatePassword(form)));
      },
      logout(): Promise<Authentication> {
        const actions = auth.logout.actions({});
        return dispatch(request(actions, () => api.logout()));
      },
      deleteUser(userId): Promise<Authentication> {
        const actions = user.deleteUser.actions({ userId });
        return dispatch(request(actions, () => api.deleteUser(userId)));
      },
      emailConfirmation(confirmationForm): Promise<boolean> {
        const actions = auth.emailConfirmation.actions(confirmationForm);
        return dispatch(
          request(actions, () => api.emailConfirmation(confirmationForm)),
        );
      },
      getProject(projectId): Promise<Project> {
        const actions = project.getProject.actions({ projectId });
        return dispatch(request(actions, () => api.getProject(projectId)));
      },
      getProjects(userId): Promise<Project[]> {
        const actions = project.getProjects.actions({ userId });
        return dispatch(request(actions, () => api.getProjects(userId)));
      },
      createProject(form): Promise<Project> {
        const actions = project.createProject.actions({
          projectName: form.projectName,
        });
        return dispatch(request(actions, () => api.createProject(form)));
      },
      createTask(form): Promise<Task> {
        const actions = task.createTask.actions({
          projectId: form.projectId,
          taskName: form.taskName,
        });
        return dispatch(request(actions, () => api.createTask(form)));
      },
      deleteTask(form): Promise<object> {
        const actions = task.deleteTask.actions({
          taskId: form.taskId,
          projectId: form.projectId,
        });
        return dispatch(request(actions, () => api.deleteTask(form)));
      },
      getTask(form): Promise<Task> {
        const actions = task.getTask.actions({
          projectId: form.projectId,
          taskId: form.taskId,
        });
        return dispatch(request(actions, () => api.getTask(form)));
      },
      getTasks(projectId): Promise<Task[]> {
        const actions = task.getTasks.actions({
          projectId,
        });
        return dispatch(request(actions, () => api.getTasks(projectId)));
      },
      getTasksByUserId(userId): Promise<Task[]> {
        const actions = task.getTasksByUserId.actions({
          userId,
        });
        return dispatch(request(actions, () => api.getTasksByUserId(userId)));
      },
      updateTaskName(form): Promise<Task> {
        const { projectId, taskId } = form;
        const actions = task.updateName.actions({ projectId, taskId });
        return dispatch(request(actions, () => api.updateTaskName(form)));
      },
      likeResult(form): Promise<Task> {
        const { projectId, taskId } = form;
        const actions = task.likeResult.actions({ projectId, taskId });
        return dispatch(request(actions, () => api.likeResult(form)));
      },
      unlikeResult(form): Promise<Task> {
        const { projectId, taskId } = form;
        const actions = task.unlikeResult.actions({ projectId, taskId });
        return dispatch(request(actions, () => api.unlikeResult(form)));
      },
      generateIdeas(form): Promise<Result[]> {
        const { promptProps, taskId, projectId } = form;
        const { type } = promptProps;
        const actions = task.generateText.actions({
          type,
          projectId,
          taskId,
        });
        return dispatch(request(actions, () => api.generateIdeas(form)));
      },
      generateInlineIdeas(form): Promise<Task> {
        const { promptProps, projectId, taskId } = form;
        const { type } = promptProps;
        const actions = task.generateInlineText.actions({
          type,
          taskId,
          projectId,
        });
        return dispatch(request(actions, () => api.generateInlineIdeas(form)));
      },
      generateWriteForMe(form): Promise<Task> {
        const { promptProps, projectId, taskId } = form;
        const { type } = promptProps;
        const actions = task.generateWriteForMeTextMeta.actions({
          type,
          taskId,
          projectId,
        });
        return dispatch(request(actions, () => api.generateWriteForMe(form)));
      },
      generateBlogPost(form): Promise<BlogSection[]> {
        const { promptProps } = form;
        const { type } = promptProps;
        const actions = task.generateBlogPost.actions({
          type,
        });
        return dispatch(request(actions, () => api.generateBlogPost(form)));
      },
      updateTaskInputs(form): Promise<Task> {
        const { taskId, projectId } = form;
        const actions = task.updateTaskInputs.actions({ taskId, projectId });
        return dispatch(request(actions, () => api.updateTaskInputs(form)));
      },
      createFeedback(form): Promise<string> {
        const actions = feedback.createFeedback.actions({});
        return dispatch(request(actions, () => api.createFeedback(form)));
      },
      updateProjectName(form): Promise<Project> {
        const actions = project.updateProjectName.actions(form);
        return dispatch(request(actions, () => api.updateProjectName(form)));
      },
      createCheckoutSession(form): Promise<CreateCheckoutSession> {
        const actions = payment.createCheckoutSession.actions({
          priceId: form.priceId,
        });
        return dispatch(
          request(actions, () => api.createCheckoutSession(form)),
        );
      },
      getCheckoutSession(sessionId): Promise<object> {
        const actions = payment.getCheckoutSession.actions({ sessionId });
        return dispatch(
          request(actions, () => api.getCheckoutSession(sessionId)),
        );
      },
      createCustomerPortal(customerId): Promise<CustomerPortalUrl> {
        const actions = payment.createCustomerPortal.actions({ customerId });
        return dispatch(
          request(actions, () => api.createCustomerPortal(customerId)),
        );
      },
      getStatistics(userId): Promise<Generation> {
        const actions = statistics.getStatistics.actions({ userId });
        return dispatch(request(actions, () => api.getStatistics(userId)));
      },
      updateStatistics(userId, type, generatedWordsCount): Promise<Generation> {
        const actions = statistics.updateStatistics.actions({
          userId,
          type,
          generatedWordsCount,
        });
        return dispatch(
          request(actions, () =>
            api.updateStatistics(userId, type, generatedWordsCount),
          ),
        );
      },
      savePlaygroundText(form): Promise<Task> {
        const { projectId, taskId } = form;
        const actions = task.savePlaygroundText.actions({ projectId, taskId });
        return dispatch(request(actions, () => api.savePlaygroundText(form)));
      },
      saveEditorContent(form): Promise<string> {
        const { projectId, taskId } = form;
        const actions = task.saveEditorContent.actions({ projectId, taskId });
        return dispatch(request(actions, () => api.saveEditorContent(form)));
      },
      convertToDocx(html): Promise<ConvertResponse> {
        const actions = task.convertToDocx.actions({ html });
        return dispatch(request(actions, () => api.convertToDocx(html)));
      },
      makePlagiarismCheck(data): Promise<PlagiarismResponse> {
        const actions = plagiarism.generatePlagiarismReport.actions({ data });
        return dispatch(request(actions, () => api.makePlagiarismCheck(data)));
      },
      updatePlagiarismStatistics(userId): Promise<string> {
        const actions = statistics.plagiarismStatistics.actions({ userId });
        return dispatch(
          request(actions, () => api.updatePlagiarismStatistics(userId)),
        );
      },
      deleteProject(form): Promise<string> {
        const { userId, projectId } = form;
        const actions = project.deleteProject.actions({ userId, projectId });
        return dispatch(request(actions, () => api.deleteProject(form)));
      },
      getLtdStats(): Promise<Ltd> {
        const actions = ltd.getltdStats.actions({});
        return dispatch(request(actions, () => api.getLtdStats()));
      },
      updateTranslationLanguage(form): Promise<Languages> {
        const { projectId, taskId } = form;
        const actions = task.updateTranslationLanguage.actions({
          projectId,
          taskId,
        });
        return dispatch(
          request(actions, () => api.updateTranslationLanguage(form)),
        );
      },
      getActivationCode(email): Promise<ActivationCode> {
        const actions = auth.getActivationCode.actions({ email });
        return dispatch(request(actions, () => api.getActivationCode(email)));
      },
      expireActivationCode(id): Promise<string> {
        const actions = auth.expireActivationCode.actions({ id });
        return dispatch(request(actions, () => api.expireActivationCode(id)));
      },
      updateWriteForMe(form): Promise<UpdateWriteForMeResult> {
        const { taskId, projectId } = form;
        const actions = task.updateWriteForMe.actions({ taskId, projectId });
        return dispatch(request(actions, () => api.updateWriteForMe(form)));
      },
      updateEditorSettings(form): Promise<EditorSettings> {
        const actions = editorSettings.updateEditorSettings.actions(form);
        return dispatch(request(actions, () => api.updateEditorSettings(form)));
      },
      getEditorSettings(userId): Promise<EditorSettings> {
        const actions = editorSettings.getEditorSettings.actions({ userId });
        return dispatch(request(actions, () => api.getEditorSettings(userId)));
      },
      getFavourites(userId): Promise<Favourite[]> {
        const actions = favourites.getFavourites.actions({ userId });
        return dispatch(request(actions, () => api.getFavourites(userId)));
      },
      createFavourite(favourite): Promise<string> {
        const actions = favourites.createFavourite.actions(favourite);
        return dispatch(request(actions, () => api.createFavourite(favourite)));
      },
      removeFavourite(favourite): Promise<string> {
        const actions = favourites.removeFavourite.actions(favourite);
        return dispatch(request(actions, () => api.removeFavourite(favourite)));
      },
      createSharedFolder(form): Promise<SharedFolder> {
        const actions = shared.createSharedFolders.actions(form);
        return dispatch(request(actions, () => api.createSharedFolder(form)));
      },
      getSharedFolders(userId): Promise<SharedFolder[]> {
        const actions = shared.getSharedFolders.actions({ userId });
        return dispatch(request(actions, () => api.getSharedFolders(userId)));
      },
      getSharedFoldersAdmin(userId): Promise<SharedFolder[]> {
        const actions = shared.getSharedFoldersAdmin.actions({ userId });
        return dispatch(
          request(actions, () => api.getSharedFoldersAdmin(userId)),
        );
      },
      addMemberToSharedFolder(form): Promise<string> {
        const actions = shared.addMember.actions(form);
        return dispatch(
          request(actions, () => api.addMemberToSharedFolder(form)),
        );
      },
      removeMemberFromSharedFolder(form): Promise<string> {
        const actions = shared.removeMember.actions(form);
        return dispatch(
          request(actions, () => api.removeMemberFromSharedFolder(form)),
        );
      },
      getSharedProject(projectId): Promise<Project> {
        const actions = shared.getSharedProject.actions({ projectId });
        return dispatch(
          request(actions, () => api.getSharedProject(projectId)),
        );
      },
      stackCode(form): Promise<User> {
        const actions = user.stackCode.actions({
          userId: form.userId,
          code: form.code,
        });
        return dispatch(request(actions, () => api.stackCode(form)));
      },
    }),
    [dispatch],
  );
};
