import React from 'react';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import { useApi } from 'src/api';
import { ReactComponent as Trash } from 'src/pages/DashboardPage/assets/trash.svg';
import { ReactComponent as Draw } from 'src/pages/DashboardPage/assets/draw.svg';
import { ReactComponent as Folder } from 'src/pages/DashboardPage/assets/folder.svg';
import { ReactComponent as Ellipsis } from 'src/pages/DashboardPage/assets/ellipsis.svg';
import { Project } from 'src/modules/Project';
import styles from './ProjectItem.module.scss';
import { maybePluralize } from 'src/utils/pluralize';
import { useSelector } from 'react-redux';
import { getUsers } from 'src/redux/selectors/user';
import Tippy from '@tippyjs/react';

type Props = {
  project: Project;
  fileCount: number;
  className?: string;
  setCurrentFolder: (a: string) => void;
  setsShowUpdateProjectNameModal: (a: boolean) => void;
  setProjectName: (a: string) => void;
  setProjectId: (a: string) => void;
  members?: string[]; // userIds
  isAdmin: boolean;
};

export default function ProjectItem({
  project,
  fileCount,
  className,
  setCurrentFolder,
  setsShowUpdateProjectNameModal,
  setProjectName,
  setProjectId,
  members,
  isAdmin,
}: Props): JSX.Element {
  const { name, color, projectId, userId } = project;
  const api = useApi();
  const [openDropdown, setOpendropdown] = useState(false);
  const users = useSelector(getUsers);
  const emails = users
    ?.filter(u => members?.includes(u.userId))
    .map(m => m.email);
  return (
    <div
      className={classNames(styles.ProjectItem, className)}
      onClick={() => setCurrentFolder(projectId)}
    >
      <div className={styles.Top}>
        <Folder style={{ fill: color }} className={styles.FolderIcon} />
        {emails && (
          <div className={styles.Emails}>
            {emails.map((email, i) => (
              <Tippy content={email} key={i}>
                <div className={styles.Email}>
                  {email.slice(0, 1).toUpperCase()}
                </div>
              </Tippy>
            ))}
          </div>
        )}
      </div>
      <div className={styles.Bottom}>
        <div className={styles.Info}>
          <div>
            <span className={styles.Name}>{name}</span>
          </div>
          <div>
            <span className={styles.FileCount}>
              {maybePluralize(fileCount, 'file')}
            </span>
          </div>
        </div>
        {isAdmin && (
          <OutsideClickHandler onOutsideClick={() => setOpendropdown(false)}>
            <div
              className={styles.IconContainer}
              onClick={e => {
                e.stopPropagation();
                setOpendropdown(!openDropdown);
              }}
            >
              <Ellipsis className={styles.Ellipsis} />
            </div>
            {openDropdown && (
              <div className={styles.Dropdown}>
                <div
                  className={styles.DropdownItem}
                  onClick={e => {
                    e.stopPropagation();
                    setsShowUpdateProjectNameModal(true);
                    setProjectName(name);
                    setProjectId(projectId);
                    setOpendropdown(false);
                  }}
                >
                  <Draw className={styles.DrawIcon} />
                  <span className={styles.ItemText}>Rename</span>
                </div>
                <div
                  className={styles.DropdownItem}
                  onClick={e => {
                    e.stopPropagation();
                    api.deleteProject({ projectId, userId });
                    setOpendropdown(false);
                  }}
                >
                  <Trash className={styles.TrashIcon} />
                  <span className={styles.ItemText}>Delete Folder</span>
                </div>
              </div>
            )}
          </OutsideClickHandler>
        )}
      </div>
    </div>
  );
}
