import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FormInput from 'src/components/FormInput';
import Input from 'src/components/Input';
import TextArea from 'src/components/TextArea';
import { Task } from 'src/modules/task';
import { ProductDescriptionPromtProps } from 'src/modules/task/promptProps';
import { taskTypes } from 'src/modules/task/tasktypes';
import { getTask } from 'src/redux/selectors/task';
import InputContainer from '../InputContainer';
import TaskWrapper from '../TaskWrapper';
import styles from './ProductDescription.module.scss';

type Props = {
  isLoading: boolean;
  setIsLoading: (a: boolean) => void;
  projectId: string;
  taskId: string;
  width: number;
  setShowTasksModal?: (a: boolean) => void;
};

export default function ProductDescriptionForm({
  isLoading,
  setIsLoading,
  setShowTasksModal,
  projectId,
  taskId,
  width,
}: Props): JSX.Element {
  const task = useSelector(getTask(projectId)(taskId));
  const [productName, setProductName] = useState('');
  const [description, setDescription] = useState('');
  const [brandKeywords, setBrandKeywords] = useState<string[]>([]);
  useEffect(() => {
    if (task) {
      if (task.mostRecentTask === taskTypes.productDescription) {
        const {
          name,
          productDescription,
          productCharacteristics,
        } = task.currentInput as ProductDescriptionPromtProps;
        setProductName(name);
        setDescription(productDescription);
        setBrandKeywords(productCharacteristics);
      }
    }
  }, [task]);
  const currentInputs = {
    name: productName,
    productDescription: description,
    productCharacteristics: brandKeywords,
  };
  const taskPrompt = { type: taskTypes.productDescription, ...currentInputs };
  return (
    <div className={styles.ProductDescription}>
      <TaskWrapper
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        checkString={
          productName + ' ' + description + ' ' + brandKeywords.join()
        }
        setShowTasksModal={setShowTasksModal}
        width={width}
        task={task as Task}
        taskPrompt={taskPrompt}
        currentInputs={currentInputs}
      >
        <>
          <InputContainer width={width}>
            <FormInput
              title='Product Name'
              placeholderText='Add a name for your product'
              input={productName}
              onChange={e => setProductName(e.target.value)}
              password={false}
              className={styles.FormInput}
            />
          </InputContainer>
          <InputContainer width={width}>
            <TextArea
              title='Describe your product'
              placeholderText='e.g. Slim fit chinos'
              text={description}
              setText={setDescription}
              className={styles.TextArea}
            />
          </InputContainer>
          <InputContainer width={width}>
            <Input
              title='Add product keywords'
              currentItems={brandKeywords}
              setCurrentItems={setBrandKeywords}
              width={width}
            />
          </InputContainer>
        </>
      </TaskWrapper>
    </div>
  );
}
