import { Favourite } from 'src/modules/favourites';
import { RootState } from 'src/redux/reducers';

/**
 * Only consume the parts of the store that we need.
 */
type State = Pick<RootState, 'favourites'>;

/**
 * This selector gets the user generation statistics.
 */
export const getFavourites = (state: State): Favourite[] | undefined =>
  state.favourites && state.favourites.favourites;
