import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useApi } from 'src/api';
import HelpModal from 'src/components/Modals/HelpModal';
import DashboardWrapper from 'src/components/NewDashboardWrapper';
import hrefs from 'src/modules/href';
import { getUser } from 'src/redux/selectors/user';
import { loadPublishableKey, ltdPriceIds } from 'src/utils/priceIds';
import styles from './LTD.module.scss';
import { getLtdStats } from 'src/redux/selectors/ltd';

export default function LTDPage(): JSX.Element {
  const history = useHistory();
  const [showHelpModal, setsShowHelpModal] = useState(false);
  const api = useApi();
  const user = useSelector(getUser);
  const ltdStats = useSelector(getLtdStats);
  useEffect(() => {
    api.getLtdStats();
  }, [api]);
  const currentPrice = 118;
  const priceId = ltdPriceIds[currentPrice];
  return (
    <DashboardWrapper
      title='Limited LTD Offer'
      description='Get it while you can!'
      helmetUrl={hrefs.billing()}
      hotKeys={[
        {
          key: 'H',
          title: 'home',
          function: () => history.push(hrefs.dashboard()),
        },
        {
          key: 'A',
          title: 'account settings',
          function: () => history.push(hrefs.settings()),
        },
        {
          key: 'F',
          title: 'feedback',
          function: () => setsShowHelpModal(true),
        },
      ]}
      isOverlayActive={showHelpModal}
      modalInJsx={<HelpModal setShowModal={setsShowHelpModal} />}
    >
      <div className={styles.Ltd}>
        {/* <div className={styles.Deadline}>
          This deal ends on 15/04/21 12:00 PM GMT + 1
        </div> */}
        <div className={styles.DealDescription}>
          <h3>Deal Description</h3>
          <ul>
            <li className={styles.ListItem}>
              State-of-the-art AI Writing Assistant
            </li>
            <li className={styles.ListItem}>
              Unlimited access to our AI copywriting tools
            </li>

            <li className={styles.ListItem}>
              65 monthly credits for our Long-form Writer
            </li>
            <li className={styles.ListItem}>
              Limited use of any new feature that increases the cost of our
              product
            </li>
            <li className={styles.ListItem}>
              Community of Writers, Marketers, and Entrepreneurs
            </li>
            <li className={styles.ListItem}>Priority Customer Support</li>
            <li className={styles.ListItem}>
              Refund only possible if less than 30 runs are made and within 14
              days of purchasing
            </li>
          </ul>
        </div>
        <div className={styles.Halfs}>
          <div className={styles.Left}>
            <h3 className={styles.PriceLevels}>Price Levels</h3>
            <div>
              <div
                className={classNames(
                  styles.Description,
                  currentPrice > 118 && styles.PriceCompleted,
                )}
              >
                unlimited @ $118
              </div>
            </div>
          </div>
          <div className={styles.Right}>
            <h3>Current Price Level</h3>
            {ltdStats && (
              <>
                <div className={styles.PriceLevel}>{`$${currentPrice}`}</div>
                <button
                  className={styles.PurchaseButton}
                  disabled={!ltdStats}
                  onClick={() => {
                    if (user) {
                      api
                        .createCheckoutSession({
                          priceId,
                          customerId: user.subscription.customerId,
                          mode: 'payment',
                        })
                        .then(async data => {
                          const stripe = await loadStripe(loadPublishableKey);
                          stripe?.redirectToCheckout({
                            sessionId: data.sessionId,
                          });
                        });
                    }
                  }}
                >
                  Buy Now
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
