import React from 'react';
import classNames from 'classnames';
import styles from './FormInput.module.scss';

export type FormInputProps = {
  className?: string;
  placeholderText?: string;
  password: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  input: string;
  error?: string;
  title?: string;
  passwordMessage?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  onBlur?: () => void;
};

export default function FormInput({
  className,
  placeholderText,
  password,
  onChange,
  input,
  error,
  title,
  passwordMessage,
  autoFocus,
  onKeyDown,
  onBlur,
}: FormInputProps): JSX.Element {
  return (
    <div>
      {title && <div className={styles.Title}>{title}</div>}
      {error && <div className={styles.Error}>{error}</div>}
      <input
        className={classNames(styles.FormInput, className, {
          [styles.InputError]: !!error,
        })}
        placeholder={placeholderText}
        type={password ? 'password' : 'text'}
        onChange={onChange}
        value={input}
        onKeyDown={onKeyDown}
        autoFocus={autoFocus}
        onBlur={onBlur}
      />
      {passwordMessage && (
        <div className={styles.PasswordMessage}>{passwordMessage}</div>
      )}
    </div>
  );
}
