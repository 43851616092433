import { Ltd } from 'src/modules/ltd';
import { RootState } from 'src/redux/reducers';

/**
 * Only consume the parts of the store that we need.
 */
type State = Pick<RootState, 'ltd'>;

/**
 * This selector gets the ltd stats.
 */
export const getLtdStats = (state: State): Ltd | undefined =>
  state.ltd && state.ltd.stats;
