import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FormInput from 'src/components/FormInput';
import { Task } from 'src/modules/task';
import { BlogIdeaProps } from 'src/modules/task/promptProps';
import { taskTypes } from 'src/modules/task/tasktypes';
import { getTask } from 'src/redux/selectors/task';
import InputContainer from '../InputContainer';
import TaskWrapper from '../TaskWrapper';
import styles from './BlogIdea.module.scss';

type Props = {
  isLoading: boolean;
  setIsLoading: (a: boolean) => void;
  setShowTasksModal?: (a: boolean) => void;
  projectId: string;
  taskId: string;
  width: number;
};

export default function BlogIdeaForm({
  isLoading,
  setIsLoading,
  setShowTasksModal,
  projectId,
  taskId,
  width,
}: Props): JSX.Element {
  const task = useSelector(getTask(projectId)(taskId));
  const [topic, setTopic] = useState('');
  useEffect(() => {
    if (task) {
      if (task.mostRecentTask === taskTypes.blogIdea) {
        const { topic } = task.currentInput as BlogIdeaProps;
        setTopic(topic);
      }
    }
  }, [task]);
  const currentInputs = {
    topic,
  };
  const taskPrompt = { type: taskTypes.blogIdea, ...currentInputs };

  return (
    <div className={styles.BlogIdea}>
      <TaskWrapper
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        checkString={topic}
        setShowTasksModal={setShowTasksModal}
        width={width}
        task={task as Task}
        taskPrompt={taskPrompt}
        currentInputs={currentInputs}
      >
        <InputContainer width={width}>
          <FormInput
            input={topic}
            onChange={e => setTopic(e.target.value)}
            placeholderText='Choose a topic'
            title='Topic'
            password={false}
            className={styles.FormInput}
          />
        </InputContainer>
      </TaskWrapper>
    </div>
  );
}
