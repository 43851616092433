import { Values } from '../types';

export const levelOfDisappointment = {
  very: 'Very',
  somewhat: 'Somewhat',
  notAtAll: 'Not At All',
} as const;
export type DisappointmentTypes = Values<typeof levelOfDisappointment>;

export interface Feedback {
  description: string;
  disappointmentLevel: DisappointmentTypes;
  userId: string;
}
