import { Reducer } from 'redux';
import { User } from 'src/modules/user';
import * as user from 'src/redux/actions/user';
import * as auth from 'src/redux/actions/auth';

/** Redux user state. */
export interface State {
  user?: User;
  all?: User[];
}

const initialState: State = {};

/**
 * Reducer for the user state.
 * @param state Current user state.
 * @param action Redux action.
 * @returns New user state.
 */

type Actions = user.Actions | auth.Actions;

const userReducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case user.getUser.types.success:
    case user.getUserByEmail.types.success:
      return {
        ...state,
        user: action.response,
      };
    case user.getUsers.types.success:
      return { ...state, all: action.response };
    case user.updateUser.types.success:
      return {
        ...state,
        user: action.response,
      };
    case user.getUserByResetId.types.success:
      return {
        ...state,
        user: action.response.user,
      };
    case auth.logout.types.success:
      return {};
    case user.stackCode.types.success:
      return { ...state, user: action.response };
    default:
      return state;
  }
};

export default userReducer;
