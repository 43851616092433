import { EditorSettings } from '../../modules/editorSetttings/index';
import * as editorSettings from 'src/redux/actions/editorSettings';
import { Reducer } from 'redux';

export interface State {
  editorSettings?: EditorSettings;
}

const initialState: State = {};

/**
 * Reducer for the activation code state.
 * @param state Current activation code state.
 * @param action Redux action.
 * @returns New activation code state.
 */

const editorSettingsReducer: Reducer<State, editorSettings.Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case editorSettings.updateEditorSettings.types.success:
      return {
        ...state,
        editorSettings: action.response,
      };
    case editorSettings.getEditorSettings.types.success:
      return {
        ...state,
        editorSettings: action.response,
      };
    default:
      return state;
  }
};

export default editorSettingsReducer;
