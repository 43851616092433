import React from 'react';
import { useHistory } from 'react-router-dom';
import href from 'src/modules/href';
import styles from './Page.module.scss';

export default function Page404(): JSX.Element {
  const history = useHistory();
  return (
    <div className={styles.Page}>
      <div className={styles.Title}>
        Oh no! Looks like our AI overloards have stolen this page.
      </div>
      <div
        className={styles.GoBack}
        onClick={() => history.push(href.landing())}
      >
        Go back to safety
      </div>
    </div>
  );
}
