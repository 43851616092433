import React from 'react';
import styles from './Pulse.module.scss';

type Props = {
  onClick: () => void;
  right?: number;
  left?: number;
  y: number;
};

export default function Pulse({ onClick, right, left, y }: Props) {
  return (
    <div
      className={styles.Pulse}
      onClick={onClick}
      style={{ top: y, left, right }}
    ></div>
  );
}
