import { User } from 'src/modules/user';
import { RootState } from 'src/redux/reducers';

/**
 * Only consume the parts of the store that we need.
 */
type State = Pick<RootState, 'user'>;

/**
 * This selector retrieves the whole user object0 from redux store
 */
export const getUser = (state: State): User | undefined =>
  state.user && state.user.user;

export const getUsers = (state: State): User[] | undefined =>
  state.user && state.user.all;
