export function getClientReferenceId() {
  return (
    (window.Rewardful && window.Rewardful.referral) ||
    'checkout_' + new Date().getTime()
  );
}

export function getRewardfulCoupon() {
  return window.Rewardful && window.Rewardful.coupon.id;
}
