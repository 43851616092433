import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useSelector } from 'react-redux';
import { ReactComponent as ChevronDown } from 'src/assets/chevronDownSilver.svg';
import TextArea from 'src/components/TextArea';
import { Task } from 'src/modules/task';
import { ChangeToneProps } from 'src/modules/task/promptProps';
import { taskTypes } from 'src/modules/task/tasktypes';
import { tones, ToneTypes } from 'src/modules/task/tones';
import { getTask } from 'src/redux/selectors/task';
import InputContainer from '../InputContainer';
import TaskWrapper from '../TaskWrapper';
import styles from './ChangeTone.module.scss';

type Props = {
  isLoading: boolean;
  setIsLoading: (a: boolean) => void;
  setShowTasksModal?: (a: boolean) => void;
  projectId: string;
  taskId: string;
  width: number;
};

export default function ChangeToneForm({
  isLoading,
  setIsLoading,
  setShowTasksModal,
  projectId,
  taskId,
  width,
}: Props) {
  const task = useSelector(getTask(projectId)(taskId));
  const [oldContent, setOldContent] = useState('');
  const [openDropdown, setOpenDropdown] = useState(false);
  const [currentTone, setCurrentTone] = useState<ToneTypes>(
    tones.conversational,
  );
  useEffect(() => {
    if (task) {
      if (task.mostRecentTask === taskTypes.changeTone) {
        const { paragraph, toneType } = task.currentInput as ChangeToneProps;
        setOldContent(paragraph);
        setCurrentTone(toneType);
      }
    }
  }, [task]);
  const currentInputs = {
    paragraph: oldContent,
    toneType: currentTone,
  };
  const taskPrompt = { type: taskTypes.changeTone, ...currentInputs };
  return (
    <div className={styles.Caption}>
      <TaskWrapper
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        checkString={oldContent}
        setShowTasksModal={setShowTasksModal}
        width={width}
        task={task as Task}
        taskPrompt={taskPrompt as ChangeToneProps}
        currentInputs={currentInputs}
      >
        <>
          <div className={styles.ToneContainer}>
            <div className={styles.ToneTitle}>Select Tone</div>
            <OutsideClickHandler onOutsideClick={() => setOpenDropdown(false)}>
              <div
                className={classNames(
                  styles.Dropdown,
                  openDropdown && styles.OpenDropDown,
                )}
                onClick={() => setOpenDropdown(!openDropdown)}
              >
                <div className={styles.SelectedName}>{currentTone}</div>
                <ChevronDown
                  className={openDropdown ? styles.OpenChevron : styles.Chevron}
                />
              </div>
              {openDropdown && (
                <div className={styles.DropDownOptions}>
                  {Object.values(tones).map(et => (
                    <div
                      className={classNames(styles.Option)}
                      onClick={() => {
                        setCurrentTone(et);
                        setOpenDropdown(false);
                      }}
                      key={et}
                    >
                      <div className={styles.ProjectName}>
                        <span>{et}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </OutsideClickHandler>
          </div>
          <InputContainer width={width}>
            <TextArea
              text={oldContent}
              setText={setOldContent}
              placeholderText='Change the tone'
              title='Content'
              className={styles.TextArea}
              textClassName={styles.Area}
              maxLength={400}
            />
          </InputContainer>
        </>
      </TaskWrapper>
    </div>
  );
}
