/**
 * Defines all hrefs used for navigation.
 *
 * The hrefs are functions to discourage consumers from
 * performing string manipulation and to allow changes to
 * the parameters to either be backwards compatible
 * or fail fast and obviously due to type-errors.
 */

/**
 * Key-values assumed to be valid URI components.
 * this lets you have { required: string; optional?: string; }
 */
type Params = Record<string, string | undefined>;

/**
 * A function of `Params` (or void) that returns a string
 * suitable for use as an href.
 * @note See [conditional types](https://www.typescriptlang.org/docs/handbook/advanced-types.html#conditional-types)
 */
export type Href<P extends Params | void> = P extends void
  ? {
      (): string;
    }
  : {
      (params: P): string;
    };

const hrefs = {
  landing(): string {
    return '/';
  },
  signUp(): string {
    return '/sign-up';
  },
  signUpSplit(): string {
    return '/sign-up-split';
  },
  appsumo(): string {
    return '/appsumo';
  },
  signIn(): string {
    return '/sign-in';
  },
  resetPassword(): string {
    return '/reset-password';
  },
  resetPasswordEmailSent(params: { encryptedEmail: string }): string {
    const { encryptedEmail } = params;
    return `/reset-password/email-sent/${encryptedEmail}`;
  },
  newPassword(params: { resetPasswordId: string }): string {
    const { resetPasswordId } = params;
    return `/new-password/${resetPasswordId}`;
  },
  dashboard(): string {
    return '/home';
  },
  project(params: { projectId: string }): string {
    return `/project/${params.projectId}`;
  },
  settings(): string {
    return '/settings';
  },
  task(params: { projectId: string; taskId: string }): string {
    const { projectId, taskId } = params;
    return `/task/${projectId}/${taskId}`;
  },
  termsOfService(): string {
    return '/terms-of-service';
  },
  privacyPolicy(): string {
    return '/privacy-policy';
  },
  success(): string {
    return `/success`;
  },
  billing(): string {
    return '/billing';
  },
  ltd(): string {
    return '/ltd';
  },
  playground(params: { projectId: string; taskId: string }): string {
    const { projectId, taskId } = params;
    return `/playground/${projectId}/${taskId}`;
  },
  facebookDeletion(): string {
    return '/deletion';
  },
  emailConfirmation(params: { encodedUser: string }): string {
    const { encodedUser } = params;
    return `/email-confirmation/${encodedUser}`;
  },
  emailConfirmationSplit(params: { encodedUser: string }): string {
    const { encodedUser } = params;
    return `/email-confirmation-split/${encodedUser}`;
  },
  unlimitedShakespeare(): string {
    return '/unlimited-shakespeare';
  },
  externalQuiz(): string {
    return 'https://ecomtrends.typeform.com/to/FGLpBwd7';
  },
  stripeBilling(): string {
    return 'https://billing.stripe.com/p/login/6oE8zoavT7vxd2g144';
  },
};

export default hrefs as {
  // This type conversion prevents someone from adding an href
  // function that is neither () => string nor (params: Params) => string
  [K in keyof typeof hrefs]: Href<Parameters<typeof hrefs[K]>[0]>;
};
