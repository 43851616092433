import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import { ReactComponent as ChevronDown } from 'src/assets/chevronDownSilver.svg';
import { ReactComponent as CloseIcon } from 'src/assets/generalClose.svg';
import styles from './Modal.module.scss';
import { useSelector } from 'react-redux';
import { getProjects } from 'src/redux/selectors/project';
import { Project } from 'src/modules/Project';
import Spinner from '../../Spinner';
import { colours } from 'src/modules/colours';
import href from 'src/modules/href';
import { getUserId } from 'src/redux/selectors/auth';
import { taskTypes } from 'src/modules/task/tasktypes';
import { useHistory } from 'react-router-dom';
import { useApi } from 'src/api';
import ModalWrapper from '../../ModalWrapper';
import FormInput from '../../FormInput';

type Props = {
  // used to set whether to keep modal open or closed.
  setNewTask: (a: boolean) => void;
  currentProject?: Project;
};

export default function TaskModal({
  setNewTask,
  currentProject,
}: Props): JSX.Element {
  const history = useHistory();
  const api = useApi();
  const userId = useSelector(getUserId);
  const projects = useSelector(getProjects);
  const [taskName, setTaskName] = useState('');
  const [selectedProject, setSelectedProject] =
    useState<Project | undefined>(currentProject);
  const [openDropdown, setOpenDropdown] = useState(false);
  const isActive = !!selectedProject && taskName.length > 0;
  function createTask() {
    if (userId && selectedProject) {
      api
        .createTask({
          userId,
          projectId: selectedProject.projectId,
          taskType: taskTypes.playground,
          taskName,
        })
        .then(task => {
          history.push(
            href.playground({
              projectId: selectedProject.projectId,
              taskId: task.taskId,
            }),
          );
          setNewTask(false);
        });
    }
  }
  return (
    <ModalWrapper setShowChildrenModal={setNewTask}>
      <div className={styles.Modal}>
        <div className={styles.Top}>
          <div className={styles.Header}>
            <div className={styles.Title}>Add a new document</div>
            <CloseIcon
              className={styles.Close}
              onClick={() => setNewTask(false)}
            />
          </div>
        </div>
        <hr className={styles.Hr} />
        <div className={styles.Subtitle}>
          <div className={styles.Projectsubtitle}>Give it a name</div>
          <FormInput
            input={taskName}
            onChange={e => setTaskName(e.target.value)}
            password={false}
            className={styles.FormInput}
            onKeyDown={e => {
              if (e.key === 'Enter' && isActive) {
                createTask();
              }
            }}
            placeholderText='Add a document name'
            autoFocus
          />
        </div>
        <div className={styles.Bottom}>
          <hr className={styles.Hr} />
          <div className={styles.ProjectContainer}>
            <div className={styles.Projectsubtitle}>Select a Folder</div>
            <OutsideClickHandler onOutsideClick={() => setOpenDropdown(false)}>
              <div
                className={classNames(
                  styles.Dropdown,
                  openDropdown && styles.OpenDropDown,
                )}
                onClick={() => setOpenDropdown(!openDropdown)}
              >
                <div className={styles.SelectedName}>
                  {selectedProject?.name}
                </div>
                <ChevronDown
                  className={openDropdown ? styles.OpenChevron : styles.Chevron}
                />
              </div>
              {openDropdown && (
                <div className={styles.DropDownOptions}>
                  {projects ? (
                    projects.map(project => (
                      <div
                        className={classNames(
                          styles.Option,
                          project.name === selectedProject?.name &&
                            styles.Selected,
                        )}
                        onClick={() => {
                          setSelectedProject(project);
                          setOpenDropdown(false);
                        }}
                        key={project.projectId}
                      >
                        <div className={styles.ProjectName}>
                          <span>{project.name}</span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>
                      <Spinner size={12} color={colours.black} />
                    </div>
                  )}
                </div>
              )}
            </OutsideClickHandler>
          </div>
          <hr className={styles.Hr} />
          <div className={styles.ButtonContainer}>
            <button
              className={styles.SelectButton}
              disabled={!isActive}
              onClick={() => {
                if (userId && selectedProject) {
                  api
                    .createTask({
                      userId,
                      projectId: selectedProject.projectId,
                      taskType: taskTypes.playground,
                      taskName,
                    })
                    .then(task => {
                      history.push(
                        href.playground({
                          projectId: selectedProject.projectId,
                          taskId: task.taskId,
                        }),
                      );
                      setNewTask(false);
                    });
                }
              }}
            >
              Add Document
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}
