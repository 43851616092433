import { PlagiarismResponse } from './../../modules/api/responseTypes';
import { createActionsBuilder, ExtractActions } from './utils/request';

type GeneratePlagiarismReportMeta = { data: string };
type GeneratePlagiarismReportResponse = PlagiarismResponse;

export const generatePlagiarismReport = createActionsBuilder<
  GeneratePlagiarismReportMeta,
  GeneratePlagiarismReportResponse
>()(
  'GENERATE_PLAGIARISM_REPORT_PENDING',
  'GENERATE_PLAGIARISM_REPORT_SUCCESS',
  'GENERATE_PLAGIARISM_REPORT_FAILURE',
);
export type GeneratePlagiarismReportActions = ExtractActions<
  typeof generatePlagiarismReport
>;

export type Actions = GeneratePlagiarismReportActions;
