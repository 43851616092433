import React from 'react';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { ReactComponent as Chevron } from 'src/assets/right-arrow.svg';
import styles from './Createdropdown.module.scss';
import { useState } from 'react';

type DropdownProps = {
  setNewTaskModal: (a: boolean) => void;
  setNewProjectModal: (a: boolean) => void;
};

export default function CreateDropdown({
  setNewTaskModal,
  setNewProjectModal,
}: DropdownProps): JSX.Element {
  const [openDropdown, setOpendropdown] = useState(false);
  return (
    <OutsideClickHandler onOutsideClick={() => setOpendropdown(false)}>
      <div
        className={styles.Count}
        onClick={() => setOpendropdown(!openDropdown)}
      >
        <div className={styles.CountContainer}>
          Create
          <Chevron className={classNames(styles.Chevron)} />
        </div>
      </div>
      {openDropdown && (
        <div className={styles.Countdropdown}>
          <div className={styles.Item} onClick={() => setNewProjectModal(true)}>
            <span className={styles.Text}>New folder</span>
          </div>
          <div className={styles.Item} onClick={() => setNewTaskModal(true)}>
            <span className={styles.Text}>New document</span>
          </div>
        </div>
      )}
    </OutsideClickHandler>
  );
}
