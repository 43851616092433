import { combineReducers, Reducer } from 'redux';
import auth from 'src/redux/reducers/auth';
import user from 'src/redux/reducers/user';
import project from 'src/redux/reducers/project';
import task from 'src/redux/reducers/task';
import statistics from 'src/redux/reducers/statistics';
import plagiarism from 'src/redux/reducers/plagiarism';
import ltd from 'src/redux/reducers/ltd';
import activationCode from 'src/redux/reducers/activationCode';
import editorSettings from 'src/redux/reducers/editorSettings';
import favourites from 'src/redux/reducers/favourites';
import sharedFolders from 'src/redux/reducers/shared';

const reducers = {
  auth,
  user,
  project,
  task,
  statistics,
  plagiarism,
  ltd,
  activationCode,
  editorSettings,
  favourites,
  sharedFolders,
};

/** Reducer of all actions for the root state. */
const rootReducer = combineReducers<typeof reducers>(reducers);

/** Helper to extract the actions that a reducer be applied to. */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ExtractReducerActions<R> = R extends Reducer<infer _S, infer X>
  ? X
  : never;

/** Union of all actions that can be reduced by the root reducer. */
export type AllActions = ExtractReducerActions<typeof rootReducer>;

/** Types the combined state of the redux store. */
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
