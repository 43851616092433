import React, { useState } from 'react';
import { DashboardTabs } from 'src/modules/dashboard';
import HelpModal from '../Modals/HelpModal';
import PageHelmet from '../PageHelmet';
import styles from './DashboardWrapper.module.scss';
import Sidebar from './Sidebar';

type Props = {
  children: JSX.Element;
  tab: DashboardTabs;
  isOverlayActive: boolean;
  modalInJsx: JSX.Element;
  helmetUrl: string;
};

export default function DashboardWrapper({
  children,
  tab,
  isOverlayActive,
  modalInJsx,
  helmetUrl,
}: Props) {
  const [showHelpModal, setShowHelpModal] = useState(false);
  return (
    <>
      <PageHelmet url={helmetUrl} />
      <div className={styles.DesktopDashboard}>
        <Sidebar selectedTab={tab} setShowHelpModal={setShowHelpModal} />
        <div className={styles.Main}>
          <div className={styles.Wrapper}>{children}</div>
        </div>
      </div>
      {isOverlayActive && !showHelpModal && (
        <div className={styles.Overlay}>{modalInJsx}</div>
      )}
      {showHelpModal && !isOverlayActive && (
        <div className={styles.Overlay}>
          <HelpModal setShowModal={setShowHelpModal} />
        </div>
      )}
    </>
  );
}
