import { RootState } from 'src/redux/reducers';

/**
 * Only consume the parts of the store that we need.
 */
type State = Pick<RootState, 'auth'>;

/**
 * This selector checks state whether the user is authenticated or not.
 */
export const getAuth = (state: State): boolean => state.auth.isAuthenticated;

export const getToken = (state: State): string | null => state.auth.token;

export const getUserId = (state: State): string | null => state.auth.userId;
