import React from 'react';
import styles from './InputContainer.module.scss';

type Props = {
  children: JSX.Element;
  width: number;
};

export default function InputContainer({
  children,
  width,
}: Props): JSX.Element {
  return (
    <div className={styles.InputContainer} style={{ width }}>
      {children}
    </div>
  );
}
