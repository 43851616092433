import { AxiosError } from 'axios';
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import GoogleLogin, { GoogleLoginResponse } from 'react-google-login';
import { useHistory } from 'react-router-dom';
import { useApi } from 'src/api';
import AuthenticationWrapper from 'src/components/AuthenticationWrapper';
import FormInput from 'src/components/FormInput';
import Spinner from 'src/components/Spinner';
import href from 'src/modules/href';
import { validateEmail } from 'src/utils/validation';
import styles from './SignInPage.module.scss';
import { ReactComponent as GoogleIcon } from 'src/assets/google.svg';
import { ReactComponent as FacebookIcon } from 'src/assets/facebook.svg';
import ReactFacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { getUserId } from 'src/redux/selectors/auth';
import { useSelector } from 'react-redux';

export default function SignInPage(): JSX.Element {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [apiError, setApiError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const userId = useSelector(getUserId);
  const api = useApi();
  const history = useHistory();
  const emailError = validateEmail(email);
  if (userId) {
    history.push(href.dashboard());
    return <div className={styles.LandingPage} />;
  }
  return (
    <AuthenticationWrapper>
      <>
        <Helmet>
          <meta charSet='utf-8' />
          <title>CopyMuse - Great to see you again! We missed you. </title>
          <link rel='canonical' href={`https://copymuse.ai${href.signIn()}`} />
        </Helmet>
        <div className={styles.SignInPage}>
          <div className={styles.Title}>Welcome back!</div>
          {apiError && <div className={styles.ApiError}>{apiError}</div>}
          <div className={styles.FormInputContainer}>
            <FormInput
              title='E-mail'
              placeholderText='Enter your e-mail address'
              password={false}
              input={email}
              onChange={e => setEmail(e.target.value)}
              className={styles.FormInput}
              error={showErrors ? emailError : ''}
            />
          </div>
          <FormInput
            title='Password'
            placeholderText='Enter your password'
            password
            input={password}
            onChange={e => setPassword(e.target.value)}
            className={styles.FormInput}
          />
          <div
            className={styles.ResetPassword}
            onClick={() => history.push(href.resetPassword())}
          >
            Forgot password?
          </div>
          <button
            className={styles.CreateAccountButton}
            onClick={() => {
              setShowErrors(true);
              if (!emailError) {
                setIsLoading(true);
                api
                  .signIn({ email, password })
                  .then(() => {
                    setIsLoading(false);
                    history.push(href.dashboard());
                  })
                  .catch((e: AxiosError) => {
                    setIsLoading(false);
                    setApiError(e.response?.data.errorMessage);
                  });
              }
            }}
          >
            {isLoading ? <Spinner size={12} color='#ffffff' /> : 'Sign In'}
          </button>

          {/* <div className={styles.Split}>
            <hr className={styles.HR} />
            <div className={styles.Or}>or</div>
            <hr className={styles.HR} />
          </div> */}
          {/* <div className={styles.SocialAuth}>
            <GoogleLogin
              clientId={
                ' '
              }
              render={renderProps => (
                <div
                  className={styles.AuthContainer}
                  onClick={renderProps.onClick}
                >
                  <GoogleIcon className={styles.SocialIcon} />
                </div>
              )}
              onSuccess={res => {
                const { profileObj } = res as GoogleLoginResponse;
                const { email } = profileObj;
                api
                  .thirdPartySignIn(email)
                  .then(() => history.push(href.dashboard()))
                  .catch((e: AxiosError) =>
                    setApiError(e.response?.data.errorMessage),
                  );
              }}
            />
            <ReactFacebookLogin
              appId='454446472354832'
              autoLoad={false}
              fields='email'
              onClick={() => {}}
              callback={res => {
                const { email } = res;
                if (email) {
                  api
                    .thirdPartySignIn(email)
                    .then(() => history.push(href.dashboard()))
                    .catch((e: AxiosError) =>
                      setApiError(e.response?.data.errorMessage),
                    );
                }
              }}
              render={renderProps => (
                <div
                  className={styles.AuthContainer}
                  onClick={renderProps.onClick}
                >
                  <FacebookIcon className={styles.SocialIcon} />
                </div>
              )}
            />
          </div> */}
        </div>
        <div className={styles.LogIn}>
          <span>Don't have an account?</span>
          <span
            className={styles.LogInText}
            onClick={() => history.push(href.signUp())}
          >
            Sign up
          </span>
        </div>
      </>
    </AuthenticationWrapper>
  );
}
