import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FormInput from 'src/components/FormInput';
import Input from 'src/components/Input';
import TextArea from 'src/components/TextArea';
import { Task } from 'src/modules/task';
import { GoogleAdProps } from 'src/modules/task/promptProps';
import { taskTypes } from 'src/modules/task/tasktypes';
import { getTask } from 'src/redux/selectors/task';
import InputContainer from '../InputContainer';
import TaskWrapper from '../TaskWrapper';
import styles from './Google.module.scss';

type Props = {
  isLoading: boolean;
  setIsLoading: (a: boolean) => void;
  setShowTasksModal?: (a: boolean) => void;
  projectId: string;
  taskId: string;
  width: number;
};

export default function GoogleAdForm({
  isLoading,
  setIsLoading,
  setShowTasksModal,
  projectId,
  taskId,
  width,
}: Props) {
  const task = useSelector(getTask(projectId)(taskId));
  const [companyName, setName] = useState('');
  const [productDescription, setDescription] = useState('');
  const [keywords, setKeywords] = useState<string[]>([]);
  useEffect(() => {
    if (task) {
      if (task.mostRecentTask === taskTypes.googleAd) {
        const {
          companyName,
          productDescription,
          keywords,
        } = task.currentInput as GoogleAdProps;
        setName(companyName);
        setDescription(productDescription);
        setKeywords(keywords);
      }
    }
  }, [task]);
  const currentInputs = {
    companyName,
    productDescription,
    keywords,
  };
  const taskPrompt = { type: taskTypes.googleAd, ...currentInputs };
  return (
    <div className={styles.Facebook}>
      <TaskWrapper
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        checkString={
          companyName + ' ' + productDescription + ' ' + keywords.join()
        }
        setShowTasksModal={setShowTasksModal}
        width={width}
        task={task as Task}
        taskPrompt={taskPrompt}
        currentInputs={currentInputs}
      >
        <>
          <InputContainer width={width}>
            <FormInput
              className={styles.FormInput}
              input={companyName}
              onChange={e => setName(e.target.value)}
              password={false}
              title='Company/Product Name'
              placeholderText='Add a name'
            />
          </InputContainer>
          <InputContainer width={width}>
            <TextArea
              title='Product Description'
              placeholderText='Describe your product'
              text={productDescription}
              setText={setDescription}
              className={styles.TextArea}
            />
          </InputContainer>
          <InputContainer width={width}>
            <Input
              title='Add product keywords'
              currentItems={keywords}
              setCurrentItems={setKeywords}
              width={width}
            />
          </InputContainer>
        </>
      </TaskWrapper>
    </div>
  );
}
