import React from 'react';
import Hotkeys from 'react-hot-keys';
import OutsideClickHandler from 'react-outside-click-handler';

type Props = {
  children: JSX.Element;
  setShowChildrenModal: (a: boolean) => void;
};

export default function ModalWrapper({
  children,
  setShowChildrenModal,
}: Props): JSX.Element {
  return (
    <Hotkeys
      keyName='escape'
      onKeyDown={(keyName, e) => {
        e.preventDefault();
      }}
      onKeyUp={() => setShowChildrenModal(false)}
    >
      <OutsideClickHandler onOutsideClick={() => setShowChildrenModal(false)}>
        {children}
      </OutsideClickHandler>
    </Hotkeys>
  );
}
