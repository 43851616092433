import { CreateCheckoutSession } from './../../modules/api/responseTypes';
import { createActionsBuilder, ExtractActions } from './utils/request';

type CreateCheckoutSessionMeta = { priceId: string };
type CreateCheckoutSessionResponse = CreateCheckoutSession;

export const createCheckoutSession = createActionsBuilder<
  CreateCheckoutSessionMeta,
  CreateCheckoutSessionResponse
>()(
  'CREATE_CHECKOUT_SESSION_PENDING',
  'CREATE_CHECKOUT_SESSION_SUCCESS',
  'CREATE_CHECKOUT_SESSION_FAILURE',
);
export type CreateCheckoutSessionActions = ExtractActions<
  typeof createCheckoutSession
>;

type GetCheckoutSessionMeta = { sessionId: string };
type GetCheckoutSessionResponse = object;

export const getCheckoutSession = createActionsBuilder<
  GetCheckoutSessionMeta,
  GetCheckoutSessionResponse
>()(
  'GET_CHECKOUT_SESSION_PENDING',
  'GET_CHECKOUT_SESSION_SUCCESS',
  'GET_CHECKOUT_SESSION_FAILURE',
);
export type GetCheckoutSessionActions = ExtractActions<
  typeof getCheckoutSession
>;

type CreateCustomerPortalMeta = { customerId: string };
type CreateCustomerPortalResponse = { url: string };

export const createCustomerPortal = createActionsBuilder<
  CreateCustomerPortalMeta,
  CreateCustomerPortalResponse
>()(
  'CREATE_CUSTOMER_PORTAL_PENDING',
  'CREATE_CUSTOMER_PORTAL_SUCCESS',
  'CREATE_CUSTOMER_PORTAL_FAILURE',
);
export type CreateCustomerPortal = ExtractActions<typeof createCustomerPortal>;

export type Actions =
  | CreateCheckoutSessionActions
  | GetCheckoutSessionActions
  | CreateCustomerPortal;
