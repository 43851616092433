import { ByMinuteVersionHistory } from './../reducers/task';
import { Task } from 'src/modules/task';
import { RootState } from 'src/redux/reducers';

/**
 * Only consume the parts of the store that we need.
 */
type State = Pick<RootState, 'task'>;

/**
 * This selector retrieves the whole task object from redux store
 */
export const getTask = (projectId: string) => (taskId: string) => (
  state: State,
): Task | undefined =>
  state.task.byId[projectId] && state.task.byId[projectId][taskId];
/**
 * This selector retrieves all tasks from redux store
 */
export const getTasks = (state: State): Task[] | undefined =>
  state.task && state.task.tasks;

export const getByMinuteVersionHistory = (projectId: string) => (
  taskId: string,
) => (state: State): ByMinuteVersionHistory[] | undefined =>
  state.task.byMinuteVersionHistory[projectId] &&
  state.task.byMinuteVersionHistory[projectId][taskId];
