import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as CheckIcon } from 'src/assets/tick.svg';
import styles from './Pricing.module.scss';
import { subscriptionTypes, SubscriptionTypes } from 'src/modules/user';
import { useWindowDimensions } from 'src/utils/windowPosition';

type Props = {
  isSubscriptionMonthly?: boolean;
  setIsBillingMonthly?: (a: boolean) => void;
  activeSubscription: SubscriptionTypes | undefined;
  freeOnClick: () => void;
  starterOnClick: () => void;
  proOnClick: () => void;
  unlimitedOnClick: () => void;
  freeText: string;
  starterText: string;
  proText: string;
  unlimitedText: string;
};

export default function Pricing({
  isSubscriptionMonthly,
  activeSubscription,
  freeOnClick,
  starterOnClick,
  proOnClick,
  unlimitedOnClick,
  setIsBillingMonthly,
  freeText,
  starterText,
  proText,
  unlimitedText,
}: Props): JSX.Element {
  const [isMonthly, setIsMonthly] = useState(
    isSubscriptionMonthly ? isSubscriptionMonthly : false,
  );
  const { width } = useWindowDimensions();
  const isMobile = width < 450;
  return (
    <div className={styles.Pricing}>
      <div className={classNames(styles.Card, isMobile && styles.MobileHeader)}>
        <div
          className={classNames(styles.CardHead, isMobile && styles.MobileHead)}
        >
          <div className={styles.HeaderTitleContainer}>
            <span className={styles.BillingCycle}>Billing cycle</span>
          </div>
          <div className={styles.Switch}>
            <div
              className={classNames(
                styles.SwitchItem,
                isMonthly && styles.Active,
              )}
              onClick={() => {
                setIsMonthly(true);
                if (setIsBillingMonthly) {
                  setIsBillingMonthly(true);
                }
              }}
            >
              <span className={styles.Cycle}>Monthly</span>
            </div>
            <div
              className={classNames(
                styles.SwitchItem,
                !isMonthly && styles.Active,
              )}
              onClick={() => {
                setIsMonthly(false);
                if (setIsBillingMonthly) {
                  setIsBillingMonthly(false);
                }
              }}
            >
              <span className={styles.Cycle}>Annual</span>
            </div>
          </div>
          <div
            className={classNames(
              styles.SectionTitle,
              isMobile && styles.MobileTitle,
            )}
          >
            Usage
          </div>
        </div>
        {!isMobile && (
          <div className={styles.CardBody}>
            <div className={styles.BodyItem}>Projects</div>
            <div className={styles.BodyItem}>Files</div>
            <div className={styles.BodyItem}>All AI Copywriting Tools</div>
            <div className={styles.BodyItem}>Shakespeare AI (Long-form AI)</div>

            <div className={styles.BodyItem}>Early Access to New Features</div>
            <div className={styles.BodySectionTitle}>Support</div>
            <div className={styles.BodyItem}>Priority Support</div>
          </div>
        )}
      </div>
      <div className={styles.Card}>
        <div className={styles.CardHead}>
          <div className={styles.TierHeader}>
            <div className={styles.PricingTier}>Starter</div>
            {!isMonthly && <div className={styles.Discount}>-72%</div>}
          </div>
          <div className={styles.PriceContainer}>
            <span className={styles.Price}>${isMonthly ? 49 : 22.99}</span>
            <span className={styles.Duration}>per month</span>
          </div>
          <button className={styles.Button} onClick={starterOnClick}>
            {activeSubscription === subscriptionTypes.starterV2
              ? 'Active'
              : starterText}
          </button>
        </div>
        <div className={styles.CardBody}>
          <div className={classNames(styles.BodyItem, styles.ActiveBodyItem)}>
            <CheckIcon
              className={classNames(styles.CheckIcon, styles.ActiveCheck)}
            />
            Unlimited {isMobile && 'Projects'}
          </div>
          <div className={classNames(styles.BodyItem, styles.ActiveBodyItem)}>
            <CheckIcon
              className={classNames(styles.CheckIcon, styles.ActiveCheck)}
            />
            Unlimited {isMobile && 'Documents'}
          </div>
          <div className={classNames(styles.BodyItem, styles.ActiveBodyItem)}>
            <CheckIcon
              className={classNames(styles.CheckIcon, styles.ActiveCheck)}
            />
            100 runs
          </div>
          <div className={classNames(styles.BodyItem, styles.ActiveBodyItem)}>
            <CheckIcon
              className={classNames(styles.CheckIcon, styles.ActiveCheck)}
            />
            12,000 words
          </div>
          {!isMobile && (
            <div
              className={classNames(styles.BodyItem, styles.ActiveBodyItem)}
            ></div>
          )}

          {!isMobile && <div className={styles.BodySectionTitle}>&nbsp;</div>}
          <div className={classNames(styles.BodyItem, styles.ActiveBodyItem)}>
            <CheckIcon
              className={classNames(styles.CheckIcon, styles.ActiveCheck)}
            />
            {isMobile && 'Priority Support'}
          </div>
        </div>
      </div>
      <div className={classNames(styles.Card, styles.ShowCard)}>
        <div className={styles.CardHead}>
          <div className={styles.TierHeader}>
            <div className={styles.PricingTier}>Pro</div>
            {!isMonthly && <div className={styles.Discount}>-22%</div>}
          </div>
          <div className={styles.PriceContainer}>
            <span className={styles.Price}>${isMonthly ? 99 : 79}</span>
            <span className={styles.Duration}>per month</span>
          </div>
          <button className={styles.Button} onClick={proOnClick}>
            {activeSubscription === subscriptionTypes.proV2 ? 'Active' : proText}
          </button>
        </div>
        <div className={styles.CardBody}>
          <div className={classNames(styles.BodyItem, styles.ActiveBodyItem)}>
            <CheckIcon
              className={classNames(styles.CheckIcon, styles.ActiveCheck)}
            />
            Unlimited {isMobile && 'Projects'}
          </div>
          <div className={classNames(styles.BodyItem, styles.ActiveBodyItem)}>
            <CheckIcon
              className={classNames(styles.CheckIcon, styles.ActiveCheck)}
            />
            Unlimited {isMobile && 'Documents'}
          </div>
          <div className={classNames(styles.BodyItem, styles.ActiveBodyItem)}>
            <CheckIcon
              className={classNames(styles.CheckIcon, styles.ActiveCheck)}
            />
            250 runs {isMobile && 'runs'}
          </div>
          <div className={classNames(styles.BodyItem, styles.ActiveBodyItem)}>
            <CheckIcon
              className={classNames(styles.CheckIcon, styles.ActiveCheck)}
            />
            50,000 Words {isMobile && 'runs'}
          </div>

          <div className={classNames(styles.BodyItem, styles.ActiveBodyItem)}>
            <CheckIcon
              className={classNames(styles.CheckIcon, styles.ActiveCheck)}
            />
            {isMobile && 'Early Access to New Features'}
          </div>
          {!isMobile && <div className={styles.BodySectionTitle}>&nbsp;</div>}
          <div className={classNames(styles.BodyItem, styles.ActiveBodyItem)}>
            <CheckIcon
              className={classNames(styles.CheckIcon, styles.ActiveCheck)}
            />
            {isMobile && 'Priority Support'}
          </div>
        </div>
      </div>
      <div className={styles.Card}>
        <div className={styles.CardHead}>
          <div className={styles.TierHeader}>
            <div className={styles.PricingTier}>Unlimited</div>
            {!isMonthly && <div className={styles.Discount}>-40%</div>}
          </div>
          <div className={styles.PriceContainer}>
            <span className={styles.Price}>${isMonthly ? 299 : 199}</span>
            <span className={styles.Duration}>per month</span>
          </div>
          <button className={styles.Button} onClick={unlimitedOnClick}>
            {activeSubscription === subscriptionTypes.unlimitedV2
              ? 'Active'
              : unlimitedText}
          </button>
        </div>
        <div className={styles.CardBody}>
          <div className={classNames(styles.BodyItem, styles.ActiveBodyItem)}>
            <CheckIcon
              className={classNames(styles.CheckIcon, styles.ActiveCheck)}
            />
            Unlimited {isMobile && 'Projects'}
          </div>
          <div className={classNames(styles.BodyItem, styles.ActiveBodyItem)}>
            <CheckIcon
              className={classNames(styles.CheckIcon, styles.ActiveCheck)}
            />
            Unlimited {isMobile && 'Documents'}
          </div>
          <div className={classNames(styles.BodyItem, styles.ActiveBodyItem)}>
            <CheckIcon
              className={classNames(styles.CheckIcon, styles.ActiveCheck)}
            />
            Unlimited
          </div>
          <div className={classNames(styles.BodyItem, styles.ActiveBodyItem)}>
            <CheckIcon
              className={classNames(styles.CheckIcon, styles.ActiveCheck)}
            />
            Unlimited
          </div>
          
          <div className={classNames(styles.BodyItem, styles.ActiveBodyItem)}>
            <CheckIcon
              className={classNames(styles.CheckIcon, styles.ActiveCheck)}
            />
            {isMobile && 'Early Access to New Features'}
          </div>
          {!isMobile && <div className={styles.BodySectionTitle}>&nbsp;</div>}
          <div className={classNames(styles.BodyItem, styles.ActiveBodyItem)}>
            <CheckIcon
              className={classNames(styles.CheckIcon, styles.ActiveCheck)}
            />
            {isMobile && 'Priority Support'}
          </div>
        </div>
      </div>
    </div>
  );
}
