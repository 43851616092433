import {
  BlogSection,
  Result,
  Task,
  UpdateWriteForMeResult,
} from 'src/modules/task';
import { createActionsBuilder, ExtractActions } from './utils/request';
import { TaskTypes } from 'src/modules/task/tasktypes';
import { ConvertResponse } from 'src/modules/api/responseTypes';
import { Languages } from 'src/modules/languages/languages';

type GetTaskMeta = {
  projectId: string;
  taskId: string;
};
type GetTaskResponse = Task;

export const getTask = createActionsBuilder<GetTaskMeta, GetTaskResponse>()(
  'GET_TASK_PENDING',
  'GET_TASK_SUCCESS',
  'GET_TASK_FAILURE',
);
export type GetTaskActions = ExtractActions<typeof getTask>;

type GetTasksMeta = {
  projectId: string;
};
type GetTasksResponse = Task[];

export const getTasks = createActionsBuilder<GetTasksMeta, GetTasksResponse>()(
  'GET_TASKS_PENDING',
  'GET_TASKS_SUCCESS',
  'GET_TASKS_FAILURE',
);
export type GetTasksActions = ExtractActions<typeof getTasks>;

type GetTasksByUserIdMeta = {
  userId: string;
};
type GetTasksByUserIdResponse = Task[];

export const getTasksByUserId = createActionsBuilder<
  GetTasksByUserIdMeta,
  GetTasksByUserIdResponse
>()(
  'GET_TASKS_BY_USERID_PENDING',
  'GET_TASKS_BY_USERID_SUCCESS',
  'GET_TASKS_BY_USERID_FAILURE',
);
export type GetTasksByUserIdActions = ExtractActions<typeof getTasksByUserId>;

type CreateTaskMeta = {
  projectId: string;
  taskName?: string;
};
type CreateTaskResponse = Task;

export const createTask = createActionsBuilder<
  CreateTaskMeta,
  CreateTaskResponse
>()('CREATE_TASK_PENDING', 'CREATE_TASK_SUCCESS', 'CREATE_TASK_FAILURE');
export type CreateTaskActions = ExtractActions<typeof createTask>;

type DeleteTaskMeta = {
  projectId: string;
  taskId: string;
};
type DeleteTaskResponse = object;

export const deleteTask = createActionsBuilder<
  DeleteTaskMeta,
  DeleteTaskResponse
>()('DELETE_TASK_PENDING', 'DELETE_TASK_SUCCESS', 'DELETE_TASK_FAILURE');
export type DeleteTaskActions = ExtractActions<typeof deleteTask>;

type UpdateNameMeta = {
  projectId: string;
  taskId: string;
};
type UpdateNameResponse = Task;

export const updateName = createActionsBuilder<
  UpdateNameMeta,
  UpdateNameResponse
>()('UPDATE_NAME_PENDING', 'UPDATE_NAME_SUCCESS', 'UPDATE_NAME_FAILURE');
export type UpdateNameActions = ExtractActions<typeof updateName>;

type LikeResultMeta = {
  projectId: string;
  taskId: string;
};
type LikeResultResponse = Task;

export const likeResult = createActionsBuilder<
  LikeResultMeta,
  LikeResultResponse
>()('LIKE_RESULT_PENDING', 'LIKE_RESULT_SUCCESS', 'LIKE_RESULT_FAILURE');
export type LikeResultActions = ExtractActions<typeof likeResult>;

type UnlikeResultMeta = {
  projectId: string;
  taskId: string;
};
type UnlikeResultResponse = Task;

export const unlikeResult = createActionsBuilder<
  UnlikeResultMeta,
  UnlikeResultResponse
>()('UNLIKE_RESULT_PENDING', 'UNLIKE_RESULT_SUCCESS', 'UNLIKE_RESULT_FAILURE');
export type UnlikeResultActions = ExtractActions<typeof unlikeResult>;

type UpdateTaskInputsMeta = {
  projectId: string;
  taskId: string;
};
type UpdateTaskInputsResponse = Task;

export const updateTaskInputs = createActionsBuilder<
  UpdateTaskInputsMeta,
  UpdateTaskInputsResponse
>()(
  'UPDATE_TASK_INPUTS_PENDING',
  'UPDATE_TASK_INPUTS_SUCCESS',
  'UPDATE_TASK_INPUTS_FAILURE',
);
export type UpdateTaskInputActions = ExtractActions<typeof updateTaskInputs>;

type GenerateTextMeta = {
  type: TaskTypes;
  projectId: string;
  taskId: string;
};

type GenerateTextResponse = Result[];

export const generateText = createActionsBuilder<
  GenerateTextMeta,
  GenerateTextResponse
>()('GENERATE_TEXT_PENDING', 'GENERATE_TEXT_SUCCESS', 'GENERATE_TEXT_FAILURE');
export type GenerateTextActions = ExtractActions<typeof generateText>;

type GenerateInlineTextMeta = {
  type: TaskTypes;
  projectId: string;
  taskId: string;
};

type GenerateInlineTextResponse = Task;

export const generateInlineText = createActionsBuilder<
  GenerateInlineTextMeta,
  GenerateInlineTextResponse
>()(
  'GENERATE_INLINE_TEXT_PENDING',
  'GENERATE_INLINE_TEXT_SUCCESS',
  'GENERATE_INLINE_TEXT_FAILURE',
);
export type GenerateInlineTextActions = ExtractActions<
  typeof generateInlineText
>;

type GenerateWriteForMeTextMeta = {
  type: TaskTypes;
  projectId: string;
  taskId: string;
};

type GenerateWriteForMeTextResponse = Task;

export const generateWriteForMeTextMeta = createActionsBuilder<
  GenerateWriteForMeTextMeta,
  GenerateWriteForMeTextResponse
>()(
  'GENERATE_WRITE_FOR_ME_PENDING',
  'GENERATE_WRITE_FOR_ME_SUCCESS',
  'GENERATE_WRITE_FOR_ME_FAILURE',
);
export type GenerateWriteForMeTextActions = ExtractActions<
  typeof generateWriteForMeTextMeta
>;

type GenerateBlogPostMeta = {
  type: TaskTypes;
};

type GenerateBlogPostResponse = BlogSection[];

export const generateBlogPost = createActionsBuilder<
  GenerateBlogPostMeta,
  GenerateBlogPostResponse
>()(
  'GENERATE_BLOG_POST_PENDING',
  'GENERATE_BLOG_POST_SUCCESS',
  'GENERATE_BLOG_POST_FAILURE',
);
export type GenerateBlogPostActions = ExtractActions<typeof generateBlogPost>;

type SavePlaygroundTextMeta = { projectId: string; taskId: string };

type SavePlaygroundTextResponse = Task;

export const savePlaygroundText = createActionsBuilder<
  SavePlaygroundTextMeta,
  SavePlaygroundTextResponse
>()(
  'SAVE_PLAYGROUND_TEXT_PENDING',
  'SAVE_PLAYGROUND_TEXT_SUCCESS',
  'SAVE_PLAYGROUND_TEXT_FAILURE',
);
export type SavePlaygroundTextActions = ExtractActions<
  typeof savePlaygroundText
>;

type SaveEditorContentMeta = { projectId: string; taskId: string };

type SaveEditorContentResponse = string;

export const saveEditorContent = createActionsBuilder<
  SaveEditorContentMeta,
  SaveEditorContentResponse
>()(
  'SAVE_EDITOR_CONTENT_PENDING',
  'SAVE_EDITOR_CONTENT_SUCCESS',
  'SAVE_EDITOR_CONTENT_FAILURE',
);
export type SaveEditorContentActions = ExtractActions<typeof saveEditorContent>;

type ConvertToDocx = { html: string };

type ConvertToDocxResponse = ConvertResponse;

export const convertToDocx = createActionsBuilder<
  ConvertToDocx,
  ConvertToDocxResponse
>()('CONVERT_DOCX_PENDING', 'CONVERT_DOCX_SUCCESS', 'CONVERT_DOCX_FAILURE');
export type ConvertToDocxActions = ExtractActions<typeof convertToDocx>;

type UpdateTranslationLanguageMeta = { taskId: string; projectId: string };

type UpdateTranslationLanguageResponse = Languages;

export const updateTranslationLanguage = createActionsBuilder<
  UpdateTranslationLanguageMeta,
  UpdateTranslationLanguageResponse
>()(
  'UPDATE_TRANSLATION_LANGUAGE_PENDING',
  'UPDATE_TRANSLATION_LANGUAGE_SUCCESS',
  'UPDATE_TRANSLATION_LANGUAGE_FAILURE',
);
export type UpdateTranslationLanguageActions = ExtractActions<
  typeof updateTranslationLanguage
>;

type UpdateWriteForMeMeta = { taskId: string; projectId: string };

type UpdateWriteForMeResponse = UpdateWriteForMeResult;

export const updateWriteForMe = createActionsBuilder<
  UpdateWriteForMeMeta,
  UpdateWriteForMeResponse
>()(
  'UPDATE_WRITE_FOR_ME_PENDING',
  'UPDATE_WRITE_FOR_ME_SUCCESS',
  'UPDATE_WRITE_FOR_ME_FAILURE',
);
export type UpdateWriteForMeActions = ExtractActions<typeof updateWriteForMe>;

export type Actions =
  | GetTaskActions
  | GetTasksActions
  | GetTasksByUserIdActions
  | CreateTaskActions
  | DeleteTaskActions
  | UpdateNameActions
  | LikeResultActions
  | UnlikeResultActions
  | UpdateTaskInputActions
  | GenerateTextActions
  | GenerateInlineTextActions
  | SavePlaygroundTextActions
  | SaveEditorContentActions
  | ConvertToDocxActions
  | GenerateBlogPostActions
  | GenerateWriteForMeTextActions
  | UpdateTranslationLanguageActions
  | UpdateWriteForMeActions;
