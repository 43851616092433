export function validateEmail(email: string): string {
  if (
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email,
    )
  ) {
    return '';
  }
  return 'Invalid email address';
}

export function validatePassword(password: string): string {
  if (password.length < 8) {
    return 'Password length is too short';
  }
  return '';
}
