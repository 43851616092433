import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TextArea from 'src/components/TextArea';
import { Task } from 'src/modules/task';
import { HeroTextProps } from 'src/modules/task/promptProps';
import { taskTypes } from 'src/modules/task/tasktypes';
import { getTask } from 'src/redux/selectors/task';
import InputContainer from '../InputContainer';
import TaskWrapper from '../TaskWrapper';
import styles from './LandingHeroText.module.scss';

type Props = {
  isLoading: boolean;
  setIsLoading: (a: boolean) => void;
  setShowTasksModal?: (a: boolean) => void;
  projectId: string;
  taskId: string;
  width: number;
};

export default function LandingHeroTextForm({
  isLoading,
  setIsLoading,
  setShowTasksModal,
  projectId,
  taskId,
  width,
}: Props) {
  const task = useSelector(getTask(projectId)(taskId));
  const [description, setDescription] = useState('');
  useEffect(() => {
    if (task) {
      if (task.mostRecentTask === taskTypes.heroText) {
        const { description } = task.currentInput as HeroTextProps;
        setDescription(description);
      }
    }
  }, [task]);
  const currentInputs = {
    description,
  };
  const taskPrompt = { type: taskTypes.heroText, ...currentInputs };
  return (
    <div className={styles.LandingHeroText}>
      <TaskWrapper
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        checkString={description}
        setShowTasksModal={setShowTasksModal}
        width={width}
        task={task as Task}
        taskPrompt={taskPrompt}
        currentInputs={currentInputs}
      >
        <InputContainer width={width}>
          <TextArea
            text={description}
            setText={setDescription}
            placeholderText='Describe your product'
            title='Product Description'
            className={styles.TextArea}
          />
        </InputContainer>
      </TaskWrapper>
    </div>
  );
}
