import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TextArea from 'src/components/TextArea';
import { Task } from 'src/modules/task';
import { InstructProps } from 'src/modules/task/promptProps';
import { taskTypes } from 'src/modules/task/tasktypes';
import { getTask } from 'src/redux/selectors/task';
import InputContainer from '../InputContainer';
import TaskWrapper from '../TaskWrapper';
import styles from './InstructForm.module.scss';

type Props = {
  isLoading: boolean;
  setIsLoading: (a: boolean) => void;
  setShowTasksModal?: (a: boolean) => void;
  projectId: string;
  taskId: string;
  width: number;
};

export default function InstructForm({
  isLoading,
  setIsLoading,
  setShowTasksModal,
  projectId,
  taskId,
  width,
}: Props): JSX.Element {
  const task = useSelector(getTask(projectId)(taskId));
  const [instruction, setInstruction] = useState('');
  useEffect(() => {
    if (task) {
      if (task.mostRecentTask === taskTypes.instruct) {
        const { instruction } = task.currentInput as InstructProps;
        setInstruction(instruction);
      }
    }
  }, [task]);
  const currentInputs = {
    instruction,
  };
  const taskPrompt = { type: taskTypes.instruct, ...currentInputs };
  return (
    <div className={styles.Caption}>
      <TaskWrapper
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        checkString={instruction}
        setShowTasksModal={setShowTasksModal}
        width={width}
        task={task as Task}
        taskPrompt={taskPrompt}
        currentInputs={currentInputs}
      >
        <InputContainer width={width}>
          <TextArea
            text={instruction}
            setText={setInstruction}
            placeholderText='Tell the AI what to do'
            title='Instruct'
            className={styles.TextArea}
            textClassName={styles.Area}
            maxLength={400}
          />
        </InputContainer>
      </TaskWrapper>
    </div>
  );
}
