import { useEffect, useRef, useState } from 'react';
import Hotkeys from 'react-hot-keys';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import { saveAs } from 'file-saver';
import isEqual from 'react-fast-compare';
import { useApi } from 'src/api';
import Turndown from 'turndown';
import { Autosave } from 'react-autosave';
import OutsideClickHandler from 'react-outside-click-handler';
import { ReactComponent as Add } from 'src/assets/add.svg';
import { ReactComponent as BackArrow } from 'src/assets/back-arrow.svg';
import { ReactComponent as Heart } from 'src/assets/like.svg';
import { ReactComponent as Home } from 'src/assets/home.svg';
import { ReactComponent as History } from 'src/assets/history.svg';
import { ReactComponent as CircleChevron } from 'src/assets/circle-chevron.svg';
import { ReactComponent as Export } from 'src/assets/download.svg';
import { ReactComponent as ChevronDown } from 'src/assets/chevronDownSilver.svg';
import { ReactComponent as Rewrite } from 'src/assets/rewrite.svg';
import { ReactComponent as Minimize } from 'src/assets/minimize.svg';
import { ReactComponent as Expand } from 'src/assets/expand.svg';
import { ReactComponent as VersionHistory } from 'src/assets/versionhistory.svg';
import { ReactComponent as WriteForMe } from 'src/assets/writeforme.svg';
import { ReactComponent as Simplify } from 'src/assets/simple.svg';
import { ReactComponent as Redo } from 'src/assets/redo.svg';
import { ReactComponent as Undo } from 'src/assets/undo.svg';
import { ReactComponent as Instruct } from 'src/assets/instructions.svg';
import { ReactComponent as EditorSettingsIcon } from 'src/assets/setting-lines.svg';
import href from 'src/modules/href';
import { getUserId } from 'src/redux/selectors/auth';
import styles from './Playground.module.scss';
import './light.css';
import { taskTypes, TaskTypes } from 'src/modules/task/tasktypes';
import CopyUseCase from '../../components/CopyUseCase';
import NewTaskPlaygroundModal from 'src/components/Modals/NewTaskPlaygroundModal';
import { getByMinuteVersionHistory, getTask } from 'src/redux/selectors/task';
import ResultCard from 'src/components/ResultCard';
import Spinner from 'src/components/Spinner';
import { colours } from 'src/modules/colours';
import { getUser } from 'src/redux/selectors/user';
import { getStatistics } from 'src/redux/selectors/generation';
import { isSubscriptionExpired } from 'src/utils/date';
import { subscriptionTypes } from 'src/modules/user';
import { isEmptyIdea } from 'src/utils/idea';
import WelcomeModal from 'src/components/Modals/WelcomeModal';
import PlaygroundTour from 'src/components/PlaygroundTour';
import {
  exportTypes,
  ExportTypes,
  maxTokenLength,
  outputLengths,
  OutputLengthTypes,
  PlaygroundTabTypes,
  tabs,
  UndoObject,
} from 'src/modules/playground';
import { fileDownloadHandler } from 'src/utils/export';
import Countdropdown from 'src/components/Countdropdown';
import { Result, Task } from 'src/modules/task';
import { convertToHtml } from 'src/modules/playground/convertOutputDatatoHtml';
import { getSelectionDimensions } from 'src/utils/playground-utils';
import TextArea from 'src/components/TextArea';
import VersionHistoryItem from 'src/components/VersionHistoryItem';
import { Languages, languages } from 'src/modules/languages/languages';
import LanguageDropdown from 'src/components/LanguageDropdown';
import SaveState from 'src/components/SaveState';
import UpgradeModal from 'src/components/Modals/UpgradeModal';
import { getEditorSettings } from 'src/redux/selectors/editorSettings';
import { EditorSettings } from 'src/modules/editorSetttings';
import getCaretCoordinates from 'textarea-caret';
import { writeForMeTypes, WriteForMeTypes } from 'src/modules/generation';
import { removeHtmlTags } from 'src/utils/html';
import FormInput from 'src/components/FormInput';
import StatsCounter from './StatsCounter';

function shortcutMatch(key: string, e: KeyboardEvent): boolean {
  return (e.metaKey || e.ctrlKey) && e.key === key;
}

type Props = {
  projectId: string;
  taskId: string;
};

export default function PlaygroundPage({
  projectId,
  taskId,
}: Props): JSX.Element {
  const api = useApi();
  const userId = useSelector(getUserId);
  const user = useSelector(getUser);
  const statistics = useSelector(getStatistics);
  const task = useSelector(getTask(projectId)(taskId));
  const editorSettings = useSelector(getEditorSettings);
  const byMinuteVersionHistory = useSelector(
    getByMinuteVersionHistory(projectId)(taskId),
  );
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [taskType, setTaskType] = useState<TaskTypes | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isInlineLoading, setIsInlineLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<PlaygroundTabTypes>(tabs.results);
  const [exportType, setExportType] = useState<ExportTypes>(exportTypes.docx);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [showTutorial, setShowTutorial] = useState(false);
  const [giveTour, setGiveTour] = useState(false);
  const [writeForMeApiLoading, setWriteForMeApiLoading] = useState(false);
  const [isOutOfRuns, setIsOutOfRuns] = useState(false);
  const [closeUpgradeModal, setCloseUpgradeModal] = useState(false);
  const [localEditorSettings, setLocalEditorSettings] = useState<
    EditorSettings | undefined
  >();
  const [cursorPosition, setCursorPosition] = useState<{
    x: number;
    y: number;
  }>();
  const [selectionDimensions, setSelectionDimensions] = useState<{
    width: number;
    height: number;
  }>();
  const [highlightedText, setHighlightedText] = useState('');
  const [inlineIdeas, setInlineIdeas] = useState<string[]>([]);
  const [keyStack, setKeyStack] = useState<string[]>([]);
  const [inlineIdeaPosition, setInlineIdeaPosition] = useState(0);
  const [tourPosition, setTourPosition] = useState(1);
  const [selectedTextStartAndEndIndices, setSelectedTextStartAndEndIndices] =
    useState({ start: 0, end: 0 });

  const [writeForMeLength, setWriteForMeLength] = useState<OutputLengthTypes>(
    outputLengths.long,
  );

  const [contentEditable, setContentEditable] = useState('');
  const [versionHistoryContentEditable, setVersionHistoryContentEditable] =
    useState('');
  const [selectedVersionHistoryDate, setSelectedVersionHistoryDate] =
    useState('');
  const [contentBrief, setContentBrief] = useState('');
  const [longFormTitle, setLongFormTitle] = useState('');
  const [writeForMeHistory, setWriteForMeHistory] = useState({
    context: '',
    startIndex: 0,
    endIndex: 0,
  });
  const undoStackRef = useRef<UndoObject[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const hiddenRef = useRef<HTMLDivElement>(null);
  const briefRef = useRef<string>('');
  const writeForMeLengthRef = useRef<string>('');
  const inlineRef = useRef<HTMLDivElement>(null);
  const inlineIdeasRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const isEmptyName = title.length === 0;
  const isRedoDisabled =
    !writeForMeHistory.context &&
    writeForMeHistory.startIndex === 0 &&
    writeForMeHistory.endIndex === 0;

  useEffect(() => {
    if (!editorSettings && task) {
      setLocalEditorSettings({
        userId: task.userId,
        n: 3,
        inline: true,
        language: task.language as Languages,
      });
    } else {
      setLocalEditorSettings(editorSettings);
    }
  }, [editorSettings, task]);

  useEffect(() => {
    writeForMeLengthRef.current = writeForMeLength;
  }, [writeForMeLength]);

  useEffect(() => {
    if (userId) {
      api.getUser(userId);
      api.getTask({ projectId, taskId });
      api.getStatistics(userId);
      api.getEditorSettings(userId);
    }
  }, [api, userId, projectId, taskId]);

  useEffect(() => {
    if (task) {
      if (
        !isEqual(task.playgroundText, textAreaRef.current?.textContent) &&
        textAreaRef.current?.textContent?.length === 0
      ) {
        api
          .saveEditorContent({
            taskId,
            projectId,
            playgroundText: textAreaRef.current?.textContent as string,
          })
          .then(() => setIsUnsaved(false));
      }
    }
  }, [contentEditable, task, api, taskId, projectId]);

  useEffect(() => {
    if (task) {
      setTaskType(task.mostRecentTask);
      setTitle(task.taskName);
      setWriteForMeLength(
        task.writeForMe ? task.writeForMe.outputLength : outputLengths.long,
      );
      setContentBrief(task.writeForMe ? task.writeForMe.brief : '');
      setLongFormTitle(task.writeForMe ? task.writeForMe.title || '' : '');
      if (typeof task.playgroundText === 'string') {
        if (task.playgroundText.includes('<p>')) {
          setContentEditable(removeHtmlTags(task.playgroundText));
        } else {
          setContentEditable(task.playgroundText);
        }
      } else if (task.playgroundText === null) {
        setContentEditable('');
      } else {
        const uncleanedHtml = convertToHtml(task.playgroundText);

        setContentEditable(removeHtmlTags(uncleanedHtml));
      }
    }
  }, [task]);
  useEffect(() => {
    if (user) {
      setShowTutorial(user.showTutorial || false);
    }
  }, [user]);
  useEffect(() => {
    if (task) {
      setIsUnsaved(!isEqual(task.playgroundText, contentEditable));
    }
  }, [contentEditable, task]);
  useEffect(() => {
    if (giveTour) {
      if (tourPosition === 1 && taskType === taskTypes.listile) {
        setTourPosition(2);
      }
      if (tourPosition === 2 && task && task.currResults.length > 0) {
        setTourPosition(3);
      }
      if (
        tourPosition === 3 &&
        task?.currResults.some(res => contentEditable.includes(res.idea))
      ) {
        setTourPosition(4);
      }
      if (tourPosition === 4 && inlineIdeas.length > 0) {
        setTourPosition(5);
      }
      if (tourPosition === 6 && task?.taskName !== title) {
        setTourPosition(7);
      }
    }
  }, [
    giveTour,
    taskType,
    tourPosition,
    task,
    contentEditable,
    isUnsaved,
    inlineIdeas,
    title,
  ]);

  useEffect(() => {
    if (highlightedText.length === 0) {
      setInlineIdeas([]);
    }
  }, [highlightedText]);

  useEffect(() => {
    briefRef.current = contentBrief;
  }, [contentBrief]);

  useEffect(() => {
    if (
      ((statistics && statistics?.runsLeft <= 0) ||
        isSubscriptionExpired(user?.subscription.currentPeriodEnd as string)) &&
      user?.subscription.type === subscriptionTypes.free
    ) {
      setIsOutOfRuns(true);
    }
  }, [statistics, user]);

  const handler = (e: KeyboardEvent) => {
    if (highlightedText && cursorPosition) {
      if (e.key === 'ArrowUp') {
        if (inlineIdeas.length > 0) {
          e.preventDefault();
          setInlineIdeaPosition(
            inlineIdeaPosition > 0
              ? inlineIdeaPosition - 1
              : inlineIdeaPosition,
          );
          inlineRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
        }
      } else if (e.key === 'ArrowDown') {
        if (inlineIdeas.length > 0) {
          e.preventDefault();
          setInlineIdeaPosition(
            inlineIdeaPosition < inlineIdeas.length - 1
              ? inlineIdeaPosition + 1
              : inlineIdeaPosition,
          );
          inlineRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
        }
      } else if (e.key === 'Enter') {
        if (inlineIdeas.length > 0) {
          e.preventDefault();
          const insertContent = inlineIdeas[inlineIdeaPosition].slice(1);

          setContentEditable(content => {
            const newContent =
              content.substring(0, selectedTextStartAndEndIndices.start) +
              insertContent +
              content.substring(selectedTextStartAndEndIndices.end);
            undoStackRef.current = [
              ...undoStackRef.current,
              {
                before: content,
                after: newContent,
              },
            ];
            return newContent;
          });
          setHighlightedText('');
          setInlineIdeas([]);
          setInlineIdeaPosition(0);
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handler);
    return () => {
      document.removeEventListener('keydown', handler);
    };
  });

  const isLikedIds = task?.liked.map(idea => idea.id);
  const currentKeyStack: string[] = [];

  const keyboardShortcut = (macString: string, otherString: string) => {
    const os = navigator.platform;
    const isMac = os.indexOf('Mac') > -1;
    if (isMac) {
      return macString;
    }
    return otherString;
  };

  function isInlineApiDisabled(highlightedTextLength: number): boolean {
    return (
      highlightedTextLength > 400 ||
      (statistics ? statistics.runsLeft <= 0 : false)
    );
  }

  function runSaveTitle() {
    if (isEmptyName) {
      api.updateTaskName({ taskId, projectId, newName: 'Untitled Document' });
    } else if (title !== (task?.taskName as string)) {
      api.updateTaskName({ taskId, projectId, newName: title });
    }
    inputRef.current?.blur();
  }

  function runSaveApi() {
    if (isUnsaved) {
      api.savePlaygroundText({
        taskId,
        projectId,
        playgroundText: contentEditable,
        taskName: title,
      });
    }
  }

  async function runRewriteApi(
    highlightedText: string,
  ): Promise<Task | undefined | Result[]> {
    if (userId && userId !== null) {
      api.updateStatistics(userId, taskTypes.contentRewriter);
      const props = {
        promptProps: {
          type: taskTypes.contentRewriter,
          old: highlightedText,
        },
        n: localEditorSettings?.n,
        userId,
        projectId: task?.projectId as string,
        taskId: task?.taskId as string,
        language: localEditorSettings?.language || languages.english,
      };
      if (localEditorSettings?.inline) {
        return api.generateInlineIdeas(props);
      } else {
        api.updateTaskInputs({
          taskId,
          projectId,
          currentInputs: props.promptProps,
          taskType: props.promptProps.type,
        });
        return api.generateIdeas(props);
      }
    }
  }

  async function runExpandApi(
    highlightedText: string,
  ): Promise<Task | undefined | Result[]> {
    if (userId && userId !== null) {
      api.updateStatistics(userId, taskTypes.bulletPointExpander);
      const props = {
        promptProps: {
          type: taskTypes.bulletPointExpander,
          bulletPoint: highlightedText,
        },
        n: localEditorSettings?.n,
        userId,
        projectId: task?.projectId as string,
        taskId: task?.taskId as string,
        language: localEditorSettings?.language || languages.english,
      };
      if (localEditorSettings?.inline) {
        return api.generateInlineIdeas(props);
      } else {
        api.updateTaskInputs({
          taskId,
          projectId,
          currentInputs: props.promptProps,
          taskType: props.promptProps.type,
        });
        return api.generateIdeas(props);
      }
    }
  }

  async function runShortenApi(
    highlightedText: string,
  ): Promise<Task | undefined | Result[]> {
    if (userId && userId !== null) {
      api.updateStatistics(userId, taskTypes.summarizer);
      const props = {
        promptProps: {
          type: taskTypes.summarizer,
          long: highlightedText,
        },
        n: localEditorSettings?.n,
        userId,
        projectId: task?.projectId as string,
        taskId: task?.taskId as string,
        language: localEditorSettings?.language || languages.english,
      };
      if (localEditorSettings?.inline) {
        return api.generateInlineIdeas(props);
      } else {
        api.updateTaskInputs({
          taskId,
          projectId,
          currentInputs: props.promptProps,
          taskType: props.promptProps.type,
        });
        return api.generateIdeas(props);
      }
    }
  }

  async function runSimplifyApi(
    highlightedText: string,
  ): Promise<Task | undefined | Result[]> {
    if (userId && userId !== null) {
      api.updateStatistics(userId, taskTypes.simplify);
      const props = {
        promptProps: {
          type: taskTypes.simplify,
          old: highlightedText,
        },
        n: localEditorSettings?.n,
        userId,
        projectId: task?.projectId as string,
        taskId: task?.taskId as string,
        language: localEditorSettings?.language || languages.english,
      };
      if (localEditorSettings?.inline) {
        return api.generateInlineIdeas(props);
      } else {
        api.updateTaskInputs({
          taskId,
          projectId,
          currentInputs: props.promptProps,
          taskType: props.promptProps.type,
        });
        return api.generateIdeas(props);
      }
    }
  }

  async function runInstructApi(
    highlightedText: string,
  ): Promise<Task | undefined | Result[]> {
    if (userId && userId !== null) {
      api.updateStatistics(userId, taskTypes.instruct);
      const props = {
        promptProps: {
          type: taskTypes.instruct,
          instruction: highlightedText,
        },
        n: localEditorSettings?.n,
        userId,
        projectId: task?.projectId as string,
        taskId: task?.taskId as string,
        language: localEditorSettings?.language || languages.english,
      };
      if (localEditorSettings?.inline) {
        return api.generateInlineIdeas(props);
      } else {
        api.updateTaskInputs({
          taskId,
          projectId,
          currentInputs: props.promptProps,
          taskType: props.promptProps.type,
        });
        return api.generateIdeas(props);
      }
    }
  }

  function createContext(content: string, cursorPosition: number) {
    const cutOffSubstring = '///';
    const activeText = content.substring(0, cursorPosition);
    if (activeText.includes(cutOffSubstring)) {
      return activeText.substring(
        activeText.lastIndexOf(cutOffSubstring) + cutOffSubstring.length,
        cursorPosition,
      );
    }

    return activeText;
  }

  async function runWriteForMeApi(
    writeForMeType: WriteForMeTypes,
    isWriteForMe: boolean,
  ): Promise<void> {
    if (
      userId &&
      statistics &&
      statistics.writeForMeRunsLeft &&
      statistics.writeForMeRunsLeft > 0 &&
      !isSubscriptionExpired(user?.subscription.currentPeriodEnd as string)
    ) {
      setWriteForMeApiLoading(true);
      textAreaRef.current?.blur();
      let context: string;

      if (writeForMeTypes.inline === writeForMeType) {
        context = createContext(
          textAreaRef.current?.textContent as string,
          selectedTextStartAndEndIndices.start,
        );
      } else if (writeForMeTypes.sidebar === writeForMeType) {
        context = createContext(contentEditable, contentEditable.length);
      } else {
        context = writeForMeHistory.context;

        setContentEditable(content => {
          const newContent =
            content.substring(0, writeForMeHistory.startIndex) +
            content.substring(writeForMeHistory.endIndex);

          return newContent;
        });
      }

      const result = await api.generateWriteForMe({
        userId,
        projectId,
        taskId,
        promptProps: isWriteForMe
          ? {
              type: taskTypes.writeForMe,
              brief: briefRef.current,
              context,
              title: longFormTitle,
            }
          : {
              type: taskTypes.contextualInstruct,
              context,
            },
        language: localEditorSettings?.language || languages.english,
        n: 1,
        maxTokens: maxTokenLength(
          writeForMeLengthRef.current as OutputLengthTypes,
        ),
      });
      const idea = result.history
        ? result.history.slice(-1)[0].results[0].idea
        : '';
      if (idea && idea.length > 0) {
        api.updateStatistics(
          userId,
          taskTypes.writeForMe,
          idea.split(' ').length,
        );
      }

      setWriteForMeApiLoading(false);
      if (idea.length > 0) {
        let localCaretPosition: number;

        if (writeForMeTypes.inline === writeForMeType) {
          localCaretPosition = selectedTextStartAndEndIndices.start;
        } else if (writeForMeTypes.sidebar === writeForMeType) {
          localCaretPosition = contentEditable.length;
        } else {
          localCaretPosition = writeForMeHistory.startIndex;
        }
        if (undoStackRef) {
          undoStackRef.current = [
            ...undoStackRef.current,
            {
              before: contentEditable,
              after:
                contentEditable.substring(0, localCaretPosition) +
                idea +
                contentEditable.substring(localCaretPosition),
            },
          ];
        }
        for (let i = 0; i < idea.length; i++) {
          const writeForMeChar = idea[i];
          const inlineCaretPosition = localCaretPosition + i;

          // eslint-disable-next-line no-loop-func
          setTimeout(() => {
            if (writeForMeChar) {
              setContentEditable(
                // eslint-disable-next-line no-loop-func
                content => {
                  // this conditional fixes the bug where when you want to redo the opening paragraph, deleted content still remains.
                  // solution: this conditional determines whether to use a fresh empty string or an existing string to add the content
                  const baseContent =
                    writeForMeHistory.context.length === 0 &&
                    i === 0 &&
                    writeForMeTypes.redo === writeForMeType
                      ? ''
                      : content;

                  const newContent =
                    baseContent.substring(0, inlineCaretPosition) +
                    writeForMeChar +
                    baseContent.substring(inlineCaretPosition);

                  return newContent;
                },
              );
            }
          }, 100);
        }
        setWriteForMeHistory({
          context,
          startIndex:
            writeForMeType === writeForMeTypes.sidebar
              ? contentEditable.length
              : writeForMeType === writeForMeTypes.redo
              ? writeForMeHistory.startIndex
              : selectedTextStartAndEndIndices.start,
          endIndex:
            (writeForMeType === writeForMeTypes.sidebar
              ? contentEditable.length
              : writeForMeType === writeForMeTypes.redo
              ? writeForMeHistory.startIndex
              : selectedTextStartAndEndIndices.start) + idea.length,
        });
      }
    }
  }

  async function runSidebarApiTool(
    apiCall: () => Promise<Result[] | undefined>,
  ) {
    try {
      if (runsLeft > 0) {
        setIsLoading(true);
        await apiCall();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }

  async function runApiTool(
    apiCall: () => Promise<Task | undefined | Result[]>,
    isDisabledInlineApiTool: boolean,
    isLongForm?: boolean,
  ) {
    if (localEditorSettings?.inline) {
      await runInlineApiTool(
        apiCall as () => Promise<Task | undefined>,
        isDisabledInlineApiTool,
        isLongForm,
      );
    } else {
      await runSidebarApiTool(apiCall as () => Promise<Result[] | undefined>);
    }
  }

  async function runInlineApiTool(
    apiCall: () => Promise<Task | undefined>,
    isDisabledInlineApiTool: boolean,
    isLongForm?: boolean,
  ) {
    try {
      if (
        !isDisabledInlineApiTool &&
        userId &&
        !isSubscriptionExpired(user?.subscription.currentPeriodEnd as string) &&
        statistics &&
        (isLongForm ? statistics.writeForMeRunsLeft > 0 : runsLeft > 0)
      ) {
        setIsInlineLoading(true);
        const task = await apiCall();
        if (task && task.history) {
          const ideas = task.history
            .slice(-1)[0]
            .results.reduce((ideas, currentResult) => {
              if (isEmptyIdea(currentResult.idea)) {
                return ideas;
              } else {
                return [...ideas, currentResult.idea];
              }
            }, [] as string[]);
          setInlineIdeas(ideas);
        }
        setIsInlineLoading(false);
      }
    } catch (error) {
      setIsInlineLoading(false);
    }
  }

  const runUndo = (e?: KeyboardEvent) => {
    const lastUndo = undoStackRef.current.slice(-1)[0];
    if (e) {
      e.preventDefault();
    }
    if (undoStackRef.current.length > 0) {
      setContentEditable(
        contentEditable.replace(lastUndo.after, lastUndo.before),
      );
      undoStackRef.current.pop();
    }
  };

  const handleChange = (evt: any) => {
    // if the user deletes the last paragraph tag, add one when he/she next writes.
    const oldText = contentEditable;

    const refinedText = evt.target.value.replaceAll('<p></p>', '');
    if (undoStackRef) {
      undoStackRef.current = [
        ...undoStackRef.current,
        {
          before: oldText,
          after: refinedText,
        },
      ];
    }
    setContentEditable(refinedText);
    setInlineIdeaPosition(0);
  };

  type InlineProps = {
    top: number;
    left: number;
  };
  function calculateInlineBarHeight(): InlineProps {
    if (cursorPosition && selectionDimensions) {
      const { height } = selectionDimensions;
      const top =
        cursorPosition.y + 550 >= window.screen.height
          ? cursorPosition.y - 43
          : cursorPosition.y + height + 2;
      return {
        left: cursorPosition.x,
        top,
      };
    }
    return {
      left: 0,
      top: 0,
    };
  }

  function onTextAreaChange(): void {
    const textarea = document.getElementById('textarea') as HTMLTextAreaElement;
    setHighlightedText(
      window.getSelection()?.toString() ||
        contentEditable.substring(
          textarea.selectionStart,
          textarea.selectionEnd,
        ),
    );

    if (textarea) {
      const { top, left, height } = textarea.getBoundingClientRect();
      const coordinates = getCaretCoordinates(textarea, textarea.selectionEnd);

      setCursorPosition({
        x: coordinates.left + left,
        y:
          coordinates.top +
          top +
          (height > 830 && coordinates.top > 790 ? 45 : 0) -
          textarea.scrollTop,
      });
      setSelectedTextStartAndEndIndices({
        start: textarea.selectionStart,
        end: textarea.selectionEnd,
      });
    }
    setSelectionDimensions(getSelectionDimensions());
  }

  if (!task || !user || !statistics) {
    return (
      <div className={styles.Playground}>
        <div className={styles.LoadingContainer}>
          <div>
            <Spinner size={20} color={colours.black} />
          </div>
          <div className={styles.LoadingTitle}>Loading...</div>
        </div>
      </div>
    );
  }
  const { runsLeft } = statistics;
  const {
    subscription: { type, currentPeriodEnd },
  } = user;
  const isExpired = isSubscriptionExpired(currentPeriodEnd);
  const inlineBarDimensions = calculateInlineBarHeight();

  return (
    <>
      <Autosave
        data={contentEditable}
        interval={500}
        onSave={() =>
          api.saveEditorContent({
            taskId,
            projectId,
            playgroundText: textAreaRef.current?.textContent as string,
          })
        }
        onSuccess={() => setIsUnsaved(false)}
      />
      {giveTour && (
        <PlaygroundTour
          currentPosition={tourPosition}
          setTourPosition={setTourPosition}
          setGiveTour={setGiveTour}
        />
      )}
      <Hotkeys
        keyName='command+q,ctrl+q,command+h,command+l,ctrl+h,ctrl+l,command+k,ctrl+k,command+g,ctrl+g,command+z,ctrl+z,command+p,ctrl+p,command+f,ctrl+f'
        onKeyDown={(keyName, e) => {
          e.preventDefault();
        }}
        onKeyUp={(keyName, e) => {
          switch (keyName) {
            case 'command+h':
            case 'ctrl+h':
              setActiveTab(tabs.results);
              break;
            case 'command+l':
            case 'ctrl+l':
              setActiveTab(tabs.liked);
              break;
            case 'command+q':
            case 'ctrl+q':
              setShowModal(true);
              break;
            case 'command+k':
            case 'ctrl+k':
              setShowForm(!showForm);
              break;
            case 'command+g':
            case 'ctrl+g':
              setActiveTab(tabs.export);
              break;
            case 'command+z':
            case 'ctrl+z':
              runUndo(e);
              break;
            case 'command+p':
            case 'ctrl+p':
              setActiveTab(tabs.history);
              break;
            case 'command+m':
            case 'ctrl+m':
              setActiveTab(tabs.writeForMe);
              break;
            default:
              break;
          }
        }}
      />
      {highlightedText &&
        cursorPosition &&
        cursorPosition.x !== 0 &&
        cursorPosition.y !== 0 && (
          <>
            <div className={styles.InlineToolbar} style={inlineBarDimensions}>
              <Tippy
                content={`Rewrite ( ${keyboardShortcut('⌘ + /', 'Ctrl + /')} )`}
              >
                <div
                  className={classNames(
                    styles.InlineTool,
                    (isEqual(keyStack.slice(-2), ['Meta', '/']) ||
                      isEqual(keyStack.slice(-2), ['Ctrl', '/'])) &&
                      styles.ActiveInlineTool,
                    highlightedText.length > 400 && styles.DisabledInlineTool,
                  )}
                  onClick={async () =>
                    await runApiTool(
                      () => runRewriteApi(highlightedText),
                      isInlineApiDisabled(highlightedText.length),
                    )
                  }
                >
                  <Rewrite
                    className={
                      isEqual(keyStack.slice(-2), ['Meta', '/']) ||
                      isEqual(keyStack.slice(-2), ['Ctrl', '/'])
                        ? styles.ActiveInlineIcon
                        : styles.InlineIcon
                    }
                  />
                </div>
              </Tippy>
              <Tippy
                content={`Expand ( ${keyboardShortcut('⌘ + [', 'Ctrl + [')} )`}
              >
                <div
                  className={classNames(
                    styles.InlineTool,
                    (isEqual(keyStack.slice(-2), ['Meta', '[']) ||
                      isEqual(keyStack.slice(-2), ['Ctrl', '['])) &&
                      styles.ActiveInlineTool,
                    highlightedText.length > 400 && styles.DisabledInlineTool,
                  )}
                  onClick={async () =>
                    await runApiTool(
                      () => runExpandApi(highlightedText),
                      isInlineApiDisabled(highlightedText.length),
                    )
                  }
                >
                  <Expand
                    className={
                      isEqual(keyStack.slice(-2), ['Meta', '[']) ||
                      isEqual(keyStack.slice(-2), ['Ctrl', '['])
                        ? styles.ActiveInlineIcon
                        : styles.InlineIcon
                    }
                  />
                </div>
              </Tippy>
              <Tippy
                content={`Condense ( ${keyboardShortcut(
                  '⌘ + ]',
                  'Ctrl + ]',
                )} )`}
              >
                <div
                  className={classNames(
                    styles.InlineTool,
                    (isEqual(keyStack.slice(-2), ['Meta', ']']) ||
                      isEqual(keyStack.slice(-2), ['Ctrl', ']'])) &&
                      styles.ActiveInlineTool,
                    highlightedText.length > 400 && styles.DisabledInlineTool,
                  )}
                  onClick={async () =>
                    await runApiTool(
                      () => runShortenApi(highlightedText),
                      isInlineApiDisabled(highlightedText.length),
                    )
                  }
                >
                  <Minimize
                    className={
                      isEqual(keyStack.slice(-2), ['Meta', ']']) ||
                      isEqual(keyStack.slice(-2), ['Ctrl', ']'])
                        ? styles.BigActiveInlineIcon
                        : styles.BigInlineIcon
                    }
                  />
                </div>
              </Tippy>
              <Tippy
                content={`Instruct ( ${keyboardShortcut(
                  '⌘ + K',
                  'Ctrl + K',
                )} )`}
              >
                <div
                  className={classNames(
                    styles.InlineTool,
                    (isEqual(keyStack.slice(-2), ['Meta', 'K']) ||
                      isEqual(keyStack.slice(-2), ['Ctrl', 'K'])) &&
                      styles.ActiveInlineTool,
                    highlightedText.length > 400 && styles.DisabledInlineTool,
                  )}
                  onClick={async () =>
                    await runApiTool(
                      () => runInstructApi(highlightedText),
                      isInlineApiDisabled(highlightedText.length),
                    )
                  }
                >
                  <Instruct
                    className={
                      isEqual(keyStack.slice(-2), ['Meta', 'K']) ||
                      isEqual(keyStack.slice(-2), ['Ctrl', 'K'])
                        ? styles.BigActiveInlineIcon
                        : styles.BigInlineIcon
                    }
                  />
                </div>
              </Tippy>
              <Tippy
                content={`Simplify ( ${keyboardShortcut(
                  '⌘ + J',
                  'Ctrl + J',
                )} )`}
              >
                <div
                  className={classNames(
                    styles.InlineTool,
                    (isEqual(keyStack.slice(-2), ['Meta', 'J']) ||
                      isEqual(keyStack.slice(-2), ['Ctrl', 'J'])) &&
                      styles.ActiveInlineTool,
                    highlightedText.length > 400 && styles.DisabledInlineTool,
                  )}
                  onClick={async () =>
                    await runApiTool(
                      () => runSimplifyApi(highlightedText),
                      isInlineApiDisabled(highlightedText.length),
                    )
                  }
                >
                  <Simplify className={styles.BigInlineIcon} />
                </div>
              </Tippy>

              <Tippy
                content={
                  highlightedText.length > 400
                    ? 'Highlighted text must be 400 characters or less'
                    : 'Length of highlighted text'
                }
              >
                <div
                  className={classNames(
                    styles.HighlightedLength,
                    highlightedText.length <= 400 && styles.Green,
                    highlightedText.length > 400 && styles.Red,
                  )}
                >
                  {highlightedText.length}
                </div>
              </Tippy>
            </div>
            {highlightedText.length > 400 && (
              <div
                className={styles.InlineIdeasError}
                style={{
                  ...inlineBarDimensions,
                  top: inlineBarDimensions.top + 45,
                }}
              >
                Please select text that is less than 400 characters long. See
                the red number in the toolbar.
              </div>
            )}
            {(inlineIdeas.length > 0 || isInlineLoading) && (
              <div
                className={styles.InlineIdeas}
                style={{
                  ...inlineBarDimensions,
                  top:
                    cursorPosition.y + 550 >= window.screen.height
                      ? inlineBarDimensions.top - 320
                      : inlineBarDimensions.top + 50,
                }}
                ref={inlineIdeasRef}
              >
                {isInlineLoading ? (
                  <div className={styles.InlineLoading}>
                    <div>
                      <Spinner color={colours.black} size={10} />
                    </div>
                    <span>Generating ideas...</span>
                  </div>
                ) : (
                  inlineIdeas.map((idea, i) => (
                    <div key={i}>
                      <div
                        className={classNames(
                          styles.InlineIdea,
                          inlineIdeaPosition === i && styles.ActiveInlineIdea,
                        )}
                        ref={i === inlineIdeaPosition ? inlineRef : undefined}
                        onClick={() => {
                          setContentEditable(content => {
                            const newContent =
                              content.substring(
                                0,
                                selectedTextStartAndEndIndices.start,
                              ) +
                              idea.trim() +
                              content.substring(
                                selectedTextStartAndEndIndices.end,
                              );
                            undoStackRef.current = [
                              ...undoStackRef.current,
                              {
                                before: content,
                                after: newContent,
                              },
                            ];
                            return newContent;
                          });

                          setHighlightedText('');
                          setInlineIdeas([]);
                          setInlineIdeaPosition(0);
                        }}
                      >
                        <span>{idea}</span>
                      </div>
                      {i < inlineIdeas.length - 1 && (
                        <hr className={styles.InlineIdeaSeparator} />
                      )}
                    </div>
                  ))
                )}
              </div>
            )}
          </>
        )}
      <div className={styles.Playground}>
        <div className={styles.Halfs}>
          <div className={styles.Left}>
            <div className={styles.Navbar}>
              <div className={styles.NavbarLeft}>
                <div
                  className={styles.BackArrowContainer}
                  onClick={() => {
                    runSaveApi();
                    history.push(href.dashboard());
                  }}
                >
                  <Tippy content='Save and go back'>
                    <BackArrow className={styles.BackArrowIcon} />
                  </Tippy>
                </div>
                <div className={styles.Hidden} ref={hiddenRef}>
                  {title}
                </div>
                <div className={styles.NameContainer}>
                  <OutsideClickHandler onOutsideClick={runSaveTitle}>
                    <Tippy content='Rename'>
                      <input
                        value={title}
                        style={{
                          width:
                            title.length === 0
                              ? 200
                              : title.length <= 8
                              ? 80
                              : (hiddenRef.current?.clientWidth as number) *
                                1.3,
                        }}
                        ref={inputRef}
                        onChange={e => setTitle(e.target.value)}
                        className={classNames(styles.TasknameInput)}
                        placeholder='Untitled Document'
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            runSaveTitle();
                          } else if (e.key === 'Escape') {
                            setTitle(task.taskName);
                            inputRef.current?.blur();
                          }
                        }}
                      />
                    </Tippy>
                  </OutsideClickHandler>
                </div>
                <SaveState isUnsaved={isUnsaved} />
              </div>
              <div className={styles.SaveStateContainer}>
                <Countdropdown content={contentEditable} />
                <StatsCounter
                  statistics={statistics}
                  isExpired={isExpired}
                  subscriptionType={type}
                />
              </div>
            </div>
            <div className={styles.Container}>
              {tabs.versionHistory === activeTab && (
                <div
                  className={styles.EditorWriteForMeMessage}
                >{`Version History: ${selectedVersionHistoryDate}`}</div>
              )}
              <div className={styles.TextContainer}>
                {writeForMeApiLoading && (
                  <div className={styles.EditorWriteForMeMessage}>
                    Shakespeare is writing for you!
                  </div>
                )}
                {tabs.versionHistory === activeTab ? (
                  <textarea
                    readOnly
                    className={styles.TextArea}
                    value={removeHtmlTags(versionHistoryContentEditable)}
                  />
                ) : (
                  <textarea
                    id='textarea'
                    ref={textAreaRef}
                    value={contentEditable}
                    onChange={handleChange}
                    className={styles.TextArea}
                    placeholder='Write something great today'
                    onMouseUpCapture={onTextAreaChange}
                    onKeyUpCapture={onTextAreaChange}
                    onKeyDownCapture={async e => {
                      setKeyStack(keys => [...keys, e.key]);
                      currentKeyStack.push(e.key);
                      const selection = window.getSelection();
                      const highlighted = selection?.toString() as string;

                      if (shortcutMatch('/', e as unknown as KeyboardEvent)) {
                        e.preventDefault();
                        await runApiTool(
                          () => runRewriteApi(highlighted),
                          isInlineApiDisabled(highlighted.length),
                        );
                      } else if (
                        shortcutMatch('[', e as unknown as KeyboardEvent)
                      ) {
                        e.preventDefault();
                        await runApiTool(
                          () => runExpandApi(highlighted),
                          isInlineApiDisabled(highlighted.length),
                        );
                      } else if (
                        shortcutMatch(']', e as unknown as KeyboardEvent)
                      ) {
                        e.preventDefault();
                        await runApiTool(
                          () => runShortenApi(highlighted),
                          isInlineApiDisabled(highlighted.length),
                        );
                      } else if (
                        shortcutMatch('z', e as unknown as KeyboardEvent)
                      ) {
                        e.preventDefault();
                        runUndo(e as unknown as KeyboardEvent);
                      } else if (
                        shortcutMatch('p', e as unknown as KeyboardEvent)
                      ) {
                        e.preventDefault();
                        setActiveTab(tabs.history);
                      } else if (
                        shortcutMatch('h', e as unknown as KeyboardEvent)
                      ) {
                        e.preventDefault();
                        setActiveTab(tabs.results);
                      } else if (
                        shortcutMatch('l', e as unknown as KeyboardEvent)
                      ) {
                        e.preventDefault();
                        setActiveTab(tabs.liked);
                      } else if (
                        shortcutMatch('p', e as unknown as KeyboardEvent)
                      ) {
                        e.preventDefault();
                        setActiveTab(tabs.history);
                      } else if (
                        shortcutMatch('g', e as unknown as KeyboardEvent)
                      ) {
                        e.preventDefault();
                        setActiveTab(tabs.export);
                      } else if (
                        shortcutMatch('m', e as unknown as KeyboardEvent)
                      ) {
                        e.preventDefault();
                        setActiveTab(tabs.writeForMe);
                      } else if (
                        shortcutMatch('j', e as unknown as KeyboardEvent)
                      ) {
                        e.preventDefault();
                        await runApiTool(
                          () => runSimplifyApi(highlighted),
                          isInlineApiDisabled(highlighted.length),
                        );
                      } else if (
                        shortcutMatch('k', e as unknown as KeyboardEvent)
                      ) {
                        e.preventDefault();
                        await runApiTool(
                          () => runInstructApi(highlighted),
                          isInlineApiDisabled(highlighted.length),
                        );
                      } else if (
                        shortcutMatch('Enter', e as unknown as KeyboardEvent)
                      ) {
                        e.preventDefault();
                        await runWriteForMeApi(writeForMeTypes.inline, true);
                      } else if (e.altKey && e.key === 'Enter') {
                        e.preventDefault();
                        !isRedoDisabled &&
                          (await runWriteForMeApi(
                            writeForMeTypes.redo,
                            task.history?.slice(-1)[0].taskType ===
                              taskTypes.writeForMe,
                          ));
                      } else if (
                        shortcutMatch('i', e as unknown as KeyboardEvent)
                      ) {
                        e.preventDefault();
                        await runWriteForMeApi(writeForMeTypes.inline, false);
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.Tools}>
            <div
              className={classNames(
                styles.Tool,
                tabs.results === activeTab && styles.ActiveTool,
              )}
              onClick={() => setActiveTab(tabs.results)}
            >
              <Tippy
                content={`Use Cases ( ${keyboardShortcut(
                  '⌘ + H',
                  'Ctrl + H',
                )} )`}
              >
                <Home
                  className={classNames(
                    styles.ToolIcon,
                    tabs.results === activeTab && styles.ActiveIcon,
                  )}
                />
              </Tippy>
            </div>

            <div
              className={classNames(
                styles.Tool,
                tabs.writeForMe === activeTab && styles.ActiveTool,
              )}
              onClick={() => setActiveTab(tabs.writeForMe)}
            >
              <Tippy
                content={`Shakespeare AI ( ${keyboardShortcut(
                  '⌘ + M',
                  'Ctrl + M',
                )} )`}
              >
                <WriteForMe
                  className={classNames(
                    styles.ToolIcon,
                    tabs.writeForMe === activeTab && styles.ActiveIcon,
                  )}
                />
              </Tippy>
            </div>

            <div
              className={classNames(
                styles.Tool,
                tabs.liked === activeTab && styles.ActiveTool,
              )}
              onClick={() => setActiveTab(tabs.liked)}
            >
              <Tippy
                content={`Liked Samples ( ${keyboardShortcut(
                  '⌘ + L',
                  'Ctrl + L',
                )} )`}
              >
                <Heart
                  className={classNames(
                    styles.ToolIcon,
                    tabs.liked === activeTab && styles.ActiveIcon,
                  )}
                />
              </Tippy>
            </div>
            <div
              className={classNames(
                styles.Tool,
                tabs.history === activeTab && styles.ActiveTool,
              )}
              onClick={() => setActiveTab(tabs.history)}
            >
              <Tippy
                content={`History ( ${keyboardShortcut('⌘ + P', 'Ctrl + P')} )`}
              >
                <History
                  className={classNames(
                    styles.ToolIcon,
                    tabs.history === activeTab && styles.ActiveIcon,
                  )}
                />
              </Tippy>
            </div>
            <div
              className={classNames(
                styles.Tool,
                tabs.versionHistory === activeTab && styles.ActiveTool,
              )}
              onClick={() => {
                api
                  .getTask({ projectId, taskId })
                  .then(() => setActiveTab(tabs.versionHistory));
              }}
            >
              <Tippy content={`Version History`}>
                <VersionHistory
                  className={classNames(
                    styles.ToolIcon,
                    tabs.versionHistory === activeTab && styles.ActiveIcon,
                  )}
                />
              </Tippy>
            </div>
            <div
              className={classNames(
                styles.Tool,
                tabs.editorSettings === activeTab && styles.ActiveTool,
              )}
              onClick={() => {
                setActiveTab(tabs.editorSettings);
              }}
            >
              <Tippy content='Editor Settings'>
                <EditorSettingsIcon
                  className={classNames(
                    styles.ToolIcon,
                    tabs.editorSettings === activeTab && styles.ActiveIcon,
                  )}
                />
              </Tippy>
            </div>
            <div
              className={classNames(
                styles.Tool,
                tabs.export === activeTab && styles.ActiveTool,
              )}
              onClick={() => setActiveTab(tabs.export)}
            >
              <Tippy
                content={`Export File ( ${keyboardShortcut(
                  '⌘ + G',
                  'Ctrl + G',
                )} )`}
              >
                <Export
                  className={classNames(
                    styles.ToolIcon,
                    tabs.export === activeTab && styles.ActiveIcon,
                  )}
                />
              </Tippy>
            </div>
          </div>
          <div className={styles.Right}>
            {
              {
                [tabs.results]: (
                  <>
                    {showForm && (
                      <div className={styles.TaskContainer}>
                        {!taskType ? (
                          <div className={styles.EmptyContainer}>
                            <button
                              className={styles.SelectTaskButton}
                              onClick={() => setShowModal(true)}
                            >
                              Select Use Case
                            </button>
                          </div>
                        ) : (
                          <div className={styles.FormContainer}>
                            <div className={styles.FormHeaderContainer}>
                              <div className={styles.LeftHeader}>
                                <div className={styles.UseCase}>Use Case</div>
                                <div className={styles.TaskTitle}>
                                  {taskType}
                                </div>
                              </div>
                              <div
                                className={styles.ReplaceIcon}
                                onClick={() => setShowModal(!showModal)}
                              >
                                <Tippy
                                  content={`Replace current use case ( ${keyboardShortcut(
                                    '⌘ + U',
                                    'Ctrl + U',
                                  )} )`}
                                >
                                  <Add className={styles.AddIcon} />
                                </Tippy>
                              </div>
                            </div>
                            <div className={styles.UseCaseForm}>
                              <CopyUseCase
                                taskType={taskType}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                projectId={projectId}
                                taskId={taskId}
                                width={400}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    <div className={styles.HrContainer}>
                      <hr className={styles.Hr} />
                      <div className={styles.ChevronContainer}>
                        <Tippy
                          content={`Collapse the use case form ( ${keyboardShortcut(
                            '⌘ + K',
                            'Ctrl + K',
                          )} )`}
                        >
                          <CircleChevron
                            className={classNames(
                              styles.Chevron,
                              !showForm && styles.UpsideDownChevron,
                            )}
                            onClick={() => setShowForm(!showForm)}
                          />
                        </Tippy>
                      </div>
                    </div>
                    <div className={styles.Generations}>
                      <div className={styles.GenerationsTitleContainer}>
                        <div className={styles.GenerationsTitle}>
                          Generated Samples
                        </div>
                      </div>
                      <div className={styles.Ideas}>
                        {isLoading ? (
                          <div className={styles.Loading}>
                            <div className={styles.Spinner}>
                              <Spinner size={16} color={colours.mainColor} />
                            </div>
                            Generating ideas...
                            <div className={styles.PSA}>
                              This shouldn't take longer than 15 seconds.
                            </div>
                          </div>
                        ) : task.currResults.length > 0 ? (
                          task.currResults.map((res, i) => (
                            <div key={i}>
                              <ResultCard
                                projectId={projectId}
                                taskId={taskId}
                                isLiked={
                                  isLikedIds?.includes(res.id) as boolean
                                }
                                result={res}
                                taskType={task.mostRecentTask as TaskTypes}
                                setIsGettingApiResponse={setIsLoading}
                                setContentEditable={setContentEditable}
                                cursorPosition={
                                  selectedTextStartAndEndIndices.start
                                }
                                undoStackRef={undoStackRef}
                                possibleToGenerateSimilarIdeas
                              />
                              {/** Show separator when the idea isn't empty or it's not the last idea */}
                              {!isEmptyIdea(res.idea) &&
                                i < task.currResults.length - 1 && (
                                  <hr className={styles.Separator} />
                                )}
                            </div>
                          ))
                        ) : (
                          <div className={styles.NoResults}>
                            <div className={styles.NoResultsTitle}>
                              No samples to show just yet...
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ),
                [tabs.liked]: (
                  <div className={styles.LikedSamples}>
                    <div className={styles.LikedTitle}>Liked Samples</div>
                    <div className={styles.Ideas}>
                      {task.liked.length > 0 ? (
                        task.liked.map((res, i) => (
                          <div key={i}>
                            <ResultCard
                              projectId={projectId}
                              taskId={taskId}
                              isLiked
                              result={res}
                              taskType={task.mostRecentTask as TaskTypes}
                              setIsGettingApiResponse={setIsLoading}
                              setContentEditable={setContentEditable}
                              cursorPosition={
                                selectedTextStartAndEndIndices.start
                              }
                              undoStackRef={undoStackRef}
                              possibleToGenerateSimilarIdeas={false}
                            />
                            {/** Show separator when it's not the last idea */}
                            {i < task.currResults.length - 1 && (
                              <hr className={styles.Separator} />
                            )}
                          </div>
                        ))
                      ) : (
                        <div className={styles.NoResults}>
                          <div className={styles.NoResultsTitle}>
                            No liked samples just yet...
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ),
                [tabs.export]: (
                  <div className={styles.ExportContainer}>
                    <div className={styles.LikedTitle}>Export</div>
                    <div className={styles.Export}>
                      <OutsideClickHandler
                        onOutsideClick={() => setOpenDropdown(false)}
                      >
                        <div
                          className={classNames(
                            styles.Dropdown,
                            openDropdown && styles.OpenDropDown,
                          )}
                          onClick={() => setOpenDropdown(!openDropdown)}
                        >
                          <div className={styles.SelectedName}>
                            {exportType}
                          </div>
                          <ChevronDown
                            className={
                              openDropdown ? styles.OpenChevron : styles.Chevron
                            }
                          />
                        </div>
                        {openDropdown && (
                          <div className={styles.DropDownOptions}>
                            {Object.values(exportTypes).map(et => (
                              <div
                                className={classNames(styles.Option)}
                                onClick={() => {
                                  setExportType(et);
                                  setOpenDropdown(false);
                                }}
                                key={et}
                              >
                                <div className={styles.ProjectName}>
                                  <span>{et}</span>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </OutsideClickHandler>
                      <button
                        type='submit'
                        onClick={async () => {
                          switch (exportType) {
                            case exportTypes.html:
                              const htmlFile = fileDownloadHandler(
                                contentEditable,
                                title,
                                'text/html',
                              );
                              saveAs(htmlFile, `${title}`);
                              break;
                            case exportTypes.markdown:
                              const turndownService = new Turndown();
                              const markdown =
                                turndownService.turndown(contentEditable);
                              const markdownFile = fileDownloadHandler(
                                markdown,
                                title,
                                'text/markdown',
                              );
                              saveAs(markdownFile, `${title}`);
                              break;
                            default:
                              const stringifiedJson = await api.convertToDocx(
                                contentEditable,
                              );
                              const buff = Buffer.from(
                                stringifiedJson.buffer,
                                'base64',
                              );
                              const blob = new Blob([buff], {
                                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                              });
                              saveAs(blob, `${title}`);
                              break;
                          }
                        }}
                        className={styles.ExportButton}
                      >
                        Export File
                      </button>
                    </div>
                  </div>
                ),

                [tabs.history]: (
                  <div className={styles.History}>
                    <div className={styles.HistoryTitle}>
                      Generations History
                    </div>
                    <div className={styles.HistoryItems}>
                      {task &&
                        task.history &&
                        task.history
                          .map((item, j) => (
                            <div className={styles.HistoryItem} key={j}>
                              <div className={styles.ItemHeader}>
                                <div>{item.taskType}</div>
                                <div>
                                  {new Date(item.date).toLocaleString()}
                                </div>
                              </div>
                              <div className={styles.Ideas}>
                                {item.results.map((result, i) => (
                                  <div key={i}>
                                    <ResultCard
                                      result={result}
                                      taskId={taskId}
                                      projectId={projectId}
                                      taskType={
                                        task.mostRecentTask as TaskTypes
                                      }
                                      setIsGettingApiResponse={setIsLoading}
                                      isLiked={
                                        isLikedIds?.includes(
                                          result.id,
                                        ) as boolean
                                      }
                                      setContentEditable={setContentEditable}
                                      cursorPosition={
                                        selectedTextStartAndEndIndices.start
                                      }
                                      undoStackRef={undoStackRef}
                                      possibleToGenerateSimilarIdeas={false}
                                    />
                                    <hr className={styles.Separator} />
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))
                          .reverse()}
                    </div>
                  </div>
                ),
                [tabs.writeForMe]: (
                  <div className={styles.WriteForMe}>
                    <div className={styles.WriteForMeTitle}>Shakespeare AI</div>
                    <div className={styles.WriteForMeSubTitle}>
                      Let our AI write for you.
                    </div>
                    <div>
                      <FormInput
                        input={longFormTitle}
                        onChange={e => setLongFormTitle(e.target.value)}
                        title='Title'
                        password={false}
                        placeholderText='Title of the document'
                        className={styles.FormInput}
                        onBlur={() => {
                          api.updateWriteForMe({
                            taskId,
                            projectId,
                            contentBrief: briefRef.current,
                            outputLength: writeForMeLength,
                            title: longFormTitle,
                          });
                        }}
                      />

                      <div className={styles.WriteForMeSubtitle}>
                        Content Brief
                      </div>
                      <TextArea
                        text={contentBrief}
                        setText={setContentBrief}
                        placeholderText='What will you be writing about? By filling this in, you are helping the AI generate more topic-relevant content.'
                        className={styles.Brief}
                        maxLength={300}
                        onBlur={() =>
                          api.updateWriteForMe({
                            taskId,
                            projectId,
                            contentBrief: briefRef.current,
                            outputLength: writeForMeLength,
                            title: longFormTitle,
                          })
                        }
                      />
                    </div>
                    <div>
                      <div className={styles.WriteForMeSubtitle}>
                        Output Lengths
                      </div>
                      <div className={styles.OutputButtonsContainer}>
                        <button
                          className={classNames(
                            styles.Type,
                            writeForMeLength === outputLengths.short &&
                              styles.Selected,
                          )}
                          onClick={() =>
                            setWriteForMeLength(outputLengths.short)
                          }
                        >
                          Short
                        </button>
                        <button
                          className={classNames(
                            styles.Type,
                            writeForMeLength === outputLengths.medium &&
                              styles.Selected,
                          )}
                          onClick={() =>
                            setWriteForMeLength(outputLengths.medium)
                          }
                        >
                          Medium
                        </button>
                        <button
                          className={classNames(
                            styles.Type,
                            writeForMeLength === outputLengths.long &&
                              styles.Selected,
                          )}
                          onClick={() =>
                            setWriteForMeLength(outputLengths.long)
                          }
                        >
                          Long
                        </button>
                      </div>
                    </div>

                    <div className={styles.WriteButtonContainer}>
                      <div className={styles.Buttons}>
                        <Tippy content='Undo'>
                          <div className={styles.Button}>
                            <Undo
                              className={styles.UndoIcon}
                              onClick={() => {
                                if (!writeForMeApiLoading) {
                                  runUndo();
                                }
                              }}
                            />
                            <div className={styles.Shortcut}>
                              <span>{`${keyboardShortcut(
                                '⌘ + Z',
                                'Ctrl + Z',
                              )}`}</span>
                            </div>
                          </div>
                        </Tippy>

                        <div className={styles.Button}>
                          <button
                            disabled={
                              statistics.writeForMeRunsLeft <= 0 ||
                              isSubscriptionExpired(
                                user?.subscription.currentPeriodEnd as string,
                              ) ||
                              writeForMeApiLoading
                            }
                            className={styles.WriteForMeButton}
                            onClick={async () =>
                              await runWriteForMeApi(
                                writeForMeTypes.sidebar,
                                true,
                              )
                            }
                          >
                            {writeForMeApiLoading ? (
                              <Spinner color={colours.white} size={12} />
                            ) : (
                              'Write For Me'
                            )}
                          </button>
                          <div className={styles.Shortcut}>
                            <span>{`${keyboardShortcut(
                              '⌘ + Enter',
                              'Ctrl + Enter',
                            )}`}</span>
                          </div>
                        </div>
                        <div className={styles.Button}>
                          <button
                            disabled={
                              statistics.writeForMeRunsLeft <= 0 ||
                              isSubscriptionExpired(
                                user?.subscription.currentPeriodEnd as string,
                              ) ||
                              writeForMeApiLoading
                            }
                            className={styles.InstructButton}
                            onClick={async () =>
                              await runWriteForMeApi(
                                writeForMeTypes.sidebar,
                                false,
                              )
                            }
                          >
                            {writeForMeApiLoading ? (
                              <Spinner color={colours.white} size={12} />
                            ) : (
                              'Instruct'
                            )}
                          </button>
                          <div className={styles.Shortcut}>
                            <span>{`${keyboardShortcut(
                              '⌘ + I',
                              'Ctrl + I',
                            )}`}</span>
                          </div>
                        </div>
                        <Tippy content='Redo'>
                          <div className={styles.Button}>
                            <Redo
                              className={styles.RedoIcon}
                              onClick={async () =>
                                !isRedoDisabled &&
                                (await runWriteForMeApi(
                                  writeForMeTypes.redo,
                                  task.history?.slice(-1)[0].taskType ===
                                    taskTypes.writeForMe,
                                ))
                              }
                            />
                            <div className={styles.Shortcut}>
                              <span>{`${keyboardShortcut(
                                '⌥ + Enter',
                                'Alt + Enter',
                              )}`}</span>
                            </div>
                          </div>
                        </Tippy>
                      </div>
                    </div>
                  </div>
                ),
                [tabs.versionHistory]: (
                  <div className={styles.VersionHistory}>
                    <div className={styles.WriteForMeTitle}>
                      Version History
                    </div>
                    {byMinuteVersionHistory && (
                      <div>
                        {byMinuteVersionHistory.map((item, i) => (
                          <VersionHistoryItem
                            item={item}
                            setEditorContent={setContentEditable}
                            setShowContent={setVersionHistoryContentEditable}
                            setActiveTab={setActiveTab}
                            setSelectedVersionHistoryDate={
                              setSelectedVersionHistoryDate
                            }
                            selectedVersionHistoryDate={
                              selectedVersionHistoryDate
                            }
                            key={i}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                ),
                [tabs.editorSettings]: (
                  <div className={styles.EditorSettings}>
                    <div className={styles.WriteForMeTitle}>
                      Editor Settings
                    </div>
                    <div className={styles.EditorSettingsSubSection}>
                      Language
                    </div>
                    <LanguageDropdown
                      localEditorSettings={
                        localEditorSettings as EditorSettings
                      }
                      setLocalEditorSettings={setLocalEditorSettings}
                    />
                    <div className={styles.EditorSettingsSubSection}>
                      How many inline ideas to generate at a time
                    </div>
                    <div className={styles.CounterComponent}>
                      <button
                        className={styles.CounterButton}
                        onClick={() =>
                          setLocalEditorSettings(
                            es =>
                              es && { ...es, n: es.n > 1 ? es.n - 1 : es.n },
                          )
                        }
                      >
                        -
                      </button>
                      <div className={styles.N}>{localEditorSettings?.n}</div>
                      <button
                        className={styles.CounterButton}
                        onClick={() =>
                          setLocalEditorSettings(
                            es =>
                              es && { ...es, n: es.n < 5 ? es.n + 1 : es.n },
                          )
                        }
                      >
                        +
                      </button>
                    </div>
                    <div className={styles.EditorSettingsSubSection}>
                      Show Ideas Inline or in Sidebar
                    </div>
                    <div className={styles.Switch}>
                      <button
                        onClick={() =>
                          setLocalEditorSettings(
                            es => es && { ...es, inline: true },
                          )
                        }
                        className={classNames(
                          styles.SwitchItem,
                          localEditorSettings?.inline &&
                            styles.ActiveInlineButton,
                        )}
                      >
                        Inline
                      </button>
                      <button
                        onClick={() =>
                          setLocalEditorSettings(
                            es => es && { ...es, inline: false },
                          )
                        }
                        className={classNames(
                          styles.SwitchItem,
                          !localEditorSettings?.inline &&
                            styles.ActiveInlineButton,
                        )}
                      >
                        Sidebar
                      </button>
                    </div>
                    <button
                      className={styles.SaveEditorSettingsButton}
                      disabled={isEqual(localEditorSettings, editorSettings)}
                      onClick={() =>
                        localEditorSettings &&
                        api.updateEditorSettings(localEditorSettings)
                      }
                    >
                      Save
                    </button>
                  </div>
                ),
              }[activeTab]
            }
          </div>
        </div>
      </div>
      {(showModal ||
        (showTutorial && !giveTour) ||
        (isOutOfRuns && !closeUpgradeModal)) && (
        <div className={styles.Overlay}>
          {showModal && (
            <NewTaskPlaygroundModal
              setShowModal={setShowModal}
              setTaskType={setTaskType}
            />
          )}
          {showTutorial && !giveTour && (
            <WelcomeModal
              setStartTour={setGiveTour}
              setShowModal={setShowTutorial}
            />
          )}
          {isOutOfRuns && !closeUpgradeModal && (
            <UpgradeModal setCloseUpgradeModal={setCloseUpgradeModal} />
          )}
        </div>
      )}
    </>
  );
}
