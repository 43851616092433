import { subscriptionTypes, SubscriptionTypes } from 'src/modules/user';

export function upgradeCta(
  type: SubscriptionTypes,
  isLongForm: boolean,
  longformStat?: number,
): string {
  const isShortUnlimited =
    type === subscriptionTypes.pro ||
    type === subscriptionTypes.appsumo ||
    type === subscriptionTypes.appsumoUnlimitedAnnual ||
    type === subscriptionTypes.appsumoUnlimitedMonthly ||
    type === subscriptionTypes.appsumoSelectStarter ||
    type === subscriptionTypes.appsumoSelectPro;
  const isLongUnlimited =
    type === subscriptionTypes.pro ||
    type === subscriptionTypes.appsumoUnlimitedAnnual ||
    type === subscriptionTypes.appsumoUnlimitedMonthly;
  if (isLongForm) {
    if (isLongUnlimited) {
      return 'Unlimited long-form';
    }
    return `${longformStat} long-form words left`;
  }
  if (isShortUnlimited) {
    return 'Unlimited copywriting runs';
  }
  return 'Copywriting runs left';
}
