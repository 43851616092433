import classNames from 'classnames';
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { ReactComponent as Chevron } from 'src/assets/right-arrow.svg';
import { languages } from 'src/modules/languages/languages';
import styles from './Languagedropdown.module.scss';
import Tippy from '@tippyjs/react';
import { EditorSettings } from 'src/modules/editorSetttings';

function getKeyByValue(object: any, value: any) {
  return Object.keys(object).find(key => object[key] === value);
}

function getValueByKey(object: any, key: any) {
  return object[key];
}

type Props = {
  setLocalEditorSettings: React.Dispatch<
    React.SetStateAction<EditorSettings | undefined>
  >;
  localEditorSettings: EditorSettings;
};

export default function LanguageDropdown({
  setLocalEditorSettings,
  localEditorSettings,
}: Props): JSX.Element {
  const languageNames = Object.keys(languages);
  const [openDropdown, setOpendropdown] = useState(false);

  return (
    <OutsideClickHandler onOutsideClick={() => setOpendropdown(false)}>
      <>
        <Tippy content='Generate ideas in the following language'>
          <div
            className={classNames(
              styles.Count,
              openDropdown && styles.ActiveCount,
            )}
            onClick={() => setOpendropdown(!openDropdown)}
          >
            {localEditorSettings.language
              ? getKeyByValue(languages, localEditorSettings.language)
              : getKeyByValue(languages, languages.english)}
            <Chevron
              className={classNames(
                styles.Chevron,
                openDropdown && styles.OpenChevron,
              )}
            />
          </div>
        </Tippy>
        {openDropdown && (
          <div className={styles.LanguageDropdown}>
            {languageNames.map((name, i) => (
              <div
                className={classNames(
                  styles.Item,
                  name ===
                    getKeyByValue(languages, localEditorSettings.language) &&
                    styles.SelectedItem,
                )}
                key={i}
                onClick={() => {
                  setLocalEditorSettings(
                    es =>
                      es && { ...es, language: getValueByKey(languages, name) },
                  );

                  setOpendropdown(false);
                }}
              >
                <span className={styles.Text}>{name}</span>
              </div>
            ))}
          </div>
        )}
      </>
    </OutsideClickHandler>
  );
}
