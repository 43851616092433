import { Ltd } from 'src/modules/ltd';
import { createActionsBuilder, ExtractActions } from './utils/request';

type LtdStatsMeta = {};
type LtdStatsResponse = Ltd;

export const getltdStats = createActionsBuilder<
  LtdStatsMeta,
  LtdStatsResponse
>()('GET_LTD_STATS_PENDING', 'GET_LTD_STATS_SUCCESS', 'GET_LTD_STATS_FAILURE');
export type GetLtdStatsActions = ExtractActions<typeof getltdStats>;

export type Actions = GetLtdStatsActions;
