export function isEmptyIdea(idea: string): boolean {
  if (
    idea === '\n' ||
    idea === '\n\n' ||
    idea.length === 0 ||
    idea.length === 2 ||
    idea === ' ' ||
    idea === '' ||
    idea === '  '
  ) {
    return true;
  }
  return false;
}
