import { Generation } from 'src/modules/statistics';
import { RootState } from 'src/redux/reducers';

/**
 * Only consume the parts of the store that we need.
 */
type State = Pick<RootState, 'statistics'>;

/**
 * This selector gets the user generation statistics.
 */
export const getStatistics = (state: State): Generation | undefined =>
  state.statistics && state.statistics.statistics;
