import ProductDescriptionForm from 'src/components/TaskForms/ProductDescriptionForm';
import MicrocopyForm from 'src/components/TaskForms/MicrocopyForm';
import BlogIdeaForm from 'src/components/TaskForms/BlogIdeaForm';
import BlogIntroForm from 'src/components/TaskForms/BlogIntroForm';
import CaptionForm from 'src/components/TaskForms/CaptionForm';
import FacebookAdForm from 'src/components/TaskForms/FacebookAdForm';
import GoogleAdForm from 'src/components/TaskForms/GoogleAdForm';
import InstagramAdForm from 'src/components/TaskForms/InstagramAdForm';
import LandingHeroTextForm from 'src/components/TaskForms/LandingHeroTextForm';
import SubheaderTextForm from 'src/components/TaskForms/SubheaderTextForm';
import { taskTypes, TaskTypes } from 'src/modules/task/tasktypes';
import AnnouncementForm from 'src/components/TaskForms/AnnouncementForm';
import BlogOutlineForm from 'src/components/TaskForms/BlogOutlineForm';
import EmailForm from 'src/components/TaskForms/EmailSubjectLineForm';
import LinkedInAdForm from 'src/components/TaskForms/LinkedInAdForm';
import MetaDescriptionForm from 'src/components/TaskForms/MetaDescriptionForm';
import NameGeneratorForm from 'src/components/TaskForms/NameGeneratorForm';
import BulletpointExpanderForm from 'src/components/TaskForms/BulletpointExpanderForm';
import ContentRewriterForm from 'src/components/TaskForms/ContentRewriterForm';
import ListicleForm from 'src/components/TaskForms/ListicleForm';
import SummarizerForm from 'src/components/TaskForms/SummarizerForm';
import SimplifyForm from 'src/components/TaskForms/SimplifyForm';
import ChangeToneForm from 'src/components/TaskForms/ChangeToneForm';
import InstructForm from 'src/components/TaskForms/InstructForm';

type Props = {
  taskType: TaskTypes;
  isLoading: boolean;
  setIsLoading: (a: boolean) => void;
  projectId: string;
  taskId: string;
  width: number;
  setShowTasksModal?: (a: boolean) => void;
};

export default function CopyUseCase({
  taskType,
  isLoading,
  setIsLoading,
  projectId,
  taskId,
  width,
  setShowTasksModal,
}: Props): JSX.Element {
  const props = {
    setShowTasksModal,
    setIsLoading,
    projectId,
    taskId,
    width,
    isLoading,
  };
  switch (taskType) {
    case taskTypes.productDescription:
      return <ProductDescriptionForm {...props} />;
    case taskTypes.blogIdea:
      return <BlogIdeaForm {...props} />;
    case taskTypes.blogIntro:
      return <BlogIntroForm {...props} />;
    case taskTypes.caption:
      return <CaptionForm {...props} />;
    case taskTypes.facebookAd:
      return <FacebookAdForm {...props} />;
    case taskTypes.googleAd:
      return <GoogleAdForm {...props} />;
    case taskTypes.instagramAd:
      return <InstagramAdForm {...props} />;
    case taskTypes.heroText:
      return <LandingHeroTextForm {...props} />;
    case taskTypes.subheaderText:
      return <SubheaderTextForm {...props} />;
    case taskTypes.announcement:
      return <AnnouncementForm {...props} />;
    case taskTypes.blogOutline:
      return <BlogOutlineForm {...props} />;
    case taskTypes.emailSubjectLine:
      return <EmailForm {...props} />;
    case taskTypes.linkedInAd:
      return <LinkedInAdForm {...props} />;
    case taskTypes.metaDescription:
      return <MetaDescriptionForm {...props} />;
    case taskTypes.nameGenerator:
      return <NameGeneratorForm {...props} />;
    case taskTypes.bulletPointExpander:
      return <BulletpointExpanderForm {...props} />;
    case taskTypes.contentRewriter:
      return <ContentRewriterForm {...props} />;
    case taskTypes.listile:
      return <ListicleForm {...props} />;
    case taskTypes.summarizer:
      return <SummarizerForm {...props} />;
    case taskTypes.simplify:
      return <SimplifyForm {...props} />;
    case taskTypes.changeTone:
      return <ChangeToneForm {...props} />;
    case taskTypes.instruct:
      return <InstructForm {...props} />;
    default:
      return <MicrocopyForm {...props} />;
  }
}
