import React from 'react';
import styles from './TextArea.module.scss';
import classNames from 'classnames';

type Props = {
  className: string;
  textClassName?: string;
  title?: string;
  placeholderText: string;
  text: string;
  setText: (a: string) => void;
  error?: string;
  maxLength?: number;
  onBlur?: () => void;
};

export default function TextArea({
  className,
  text,
  setText,
  title,
  placeholderText,
  error,
  maxLength,
  textClassName,
  onBlur,
}: Props): JSX.Element {
  const maxTokenLength = maxLength || 400;
  return (
    <div className={className}>
      {title && <div className={styles.Title}>{title}</div>}
      {error && <div className={styles.Error}>{error}</div>}
      <textarea
        value={text}
        placeholder={placeholderText}
        onChange={e => setText(e.target.value)}
        className={classNames(
          styles.TextArea,
          error && styles.ErrorContainer,
          textClassName,
        )}
        maxLength={maxTokenLength}
        onBlur={onBlur}
      />
      <div className={styles.MaxToken}>
        {text.length} / {maxTokenLength}
      </div>
    </div>
  );
}
