import React from 'react';
import styles from './SaveState.module.scss';

type Props = {
  isUnsaved: boolean;
};

export default function SaveState({ isUnsaved }: Props): JSX.Element {
  return (
    <div className={styles.SaveState}>
      {isUnsaved && <span>Saving...</span>}
      {!isUnsaved && <span className={styles.Animation}>Saved</span>}
    </div>
  );
}
