import { Reducer } from 'redux';
import { Project } from 'src/modules/Project';
import * as project from 'src/redux/actions/project';

/** Redux project state. */
export interface State {
  byProjectid: {
    [projectId: string]: Project;
  };
  projects?: Project[];
}

const initialState: State = {
  byProjectid: {},
};

/**
 * Reducer for the project state.
 * @param state Current project state.
 * @param action Redux action.
 * @returns New project state.
 */
const projectReducer: Reducer<State, project.Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case project.getProject.types.success:
      const { projectId } = action.meta;
      return {
        ...state,
        byProjectid: {
          [projectId]: action.response,
        },
      };
    case project.getProjects.types.success:
      return {
        ...state,
        projects: action.response,
      };
    case project.createProject.types.success:
      return {
        ...state,
        projects: state.projects
          ? [...state.projects, action.response]
          : [action.response],
      };
    case project.updateProjectName.types.success: {
      const { projectId } = action.meta;
      if (!state.projects) {
        return { ...state, projects: [action.response] };
      }
      const idIndex = state.projects.findIndex(
        project => project.projectId === projectId,
      );
      state.projects[idIndex] = action.response;
      return {
        ...state,
        byProjectid: {
          [projectId]: action.response,
        },
        projects: [...state.projects],
      };
    }
    case project.deleteProject.types.success: {
      const { projectId } = action.meta;
      return {
        ...state,
        projects: state.projects?.filter(
          project => project.projectId !== projectId,
        ),
      };
    }
    default:
      return state;
  }
};

export default projectReducer;
