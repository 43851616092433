import { pickValueForDeploymentEnv } from 'src/utils/env';

export const proV2PriceIds = pickValueForDeploymentEnv(
  {
    monthly: 'price_1PCrpwDR2lZhGXZ1RB8eV626',
    annual: 'price_1PCrqnDR2lZhGXZ1uvZstjul',
  },
  {
    monthly: 'price_1PXr8HDR2lZhGXZ1it6l4jTP',
    annual: 'price_1PXr8TDR2lZhGXZ1DXWYwWka',
  },
);

export const starterV2PriceIds = pickValueForDeploymentEnv(
  {
    monthly: 'price_1P303JDR2lZhGXZ1GXhxwVMH',
    annual: 'price_1PXPE1DR2lZhGXZ10nGC1O3D',
  },
  {
    monthly: 'price_1PXr98DR2lZhGXZ13iDvPvrl',
    annual: 'price_1PXr8wDR2lZhGXZ1IBXjQhJI',
  },
);


export const unlimitedV2PriceIds = pickValueForDeploymentEnv(
  {
    monthly: 'price_1PCrrbDR2lZhGXZ10dHxQHfT',
    annual: 'price_1PCrsMDR2lZhGXZ1BLJfLXpS',
  },
  {
    monthly: 'price_1PXr7nDR2lZhGXZ1STjUgbqS',
    annual: 'price_1PXr7yDR2lZhGXZ1o3Xu8DPS',
  },
);



export const proPriceIds = pickValueForDeploymentEnv(
  {
    monthly: 'price_1IK90CBtw7j6HkouwJUX8YyR',
    annual: 'price_1IK90CBtw7j6HkoumYtY9AIU',
  },
  {
    monthly: 'price_1PDBTVDR2lZhGXZ1SPK3FapF',
    annual: 'price_1PDBTVDR2lZhGXZ1851zX1CL',
  },
);

export const startedPriceIds = pickValueForDeploymentEnv(
  {
    monthly: 'price_1INLhzBtw7j6HkoutgZTz6rq',
    annual: 'price_1INLhzBtw7j6HkouSAcrIkjU',
  },
  {
    monthly: 'price_1IegaHBtw7j6Hkoue1cYqi8g',
    annual: 'price_1INLZgBtw7j6HkouteZnxqFL',
  },
);

export const unlimitedShakespearePriceIds = pickValueForDeploymentEnv(
  {
    monthly: 'price_1INLhzBtw7j6HkoutgZTz6rq',
    annual: 'price_1INLhzBtw7j6HkouSAcrIkjU',
  },
  {
    monthly: 'price_1OZM1CDR2lZhGXZ1920CIMJf',
    annual: 'price_1IvIMoBtw7j6HkousE2pbkvh',
  },
);

export const loadPublishableKey = pickValueForDeploymentEnv(
  'pk_test_51IszOADR2lZhGXZ1pN9irZAAGTfxOahIinf5fuFlxeBeb8HD1mKzgwq2p9V9au29ACcOBNmDBTyW6pWPHD5mLWpD00PvXEheNe',
  'pk_live_51IszOADR2lZhGXZ17Kr50lWxJhP9gm8cyqK4il308CB2Roby20hQh97bPARx1Bcu8yhAM2CAnIeXZH9ibr01oReJ00KsVetit7',
);

const liveLtdIds = {
  75: 'price_1OZLQhDR2lZhGXZ1MO2P6jEl',
  100: 'price_1OZLRaDR2lZhGXZ1WUsRiEmB',
  118: 'price_1OZLS1DR2lZhGXZ15khd1l8f',
  125: 'price_1OZLSTDR2lZhGXZ1QanFEREP',
  150: 'price_1OZLriDR2lZhGXZ1mwXliAsM',
  175: 'price_1OZLsCDR2lZhGXZ1aaBNBwO0',
  200: 'price_1OZLtHDR2lZhGXZ1OxyhLyIs',
};

const localLtdIds = {
  75: 'price_1IdjepBtw7j6HkouAuSwFnaG',
  100: 'price_1IdzDCBtw7j6HkouOF1Y2qd5',
  118: 'price_1JMYXhBtw7j6HkouNc0uwSsD',
  125: 'price_1IdzDiBtw7j6HkouPffhh2Ol',
  150: 'price_1IdzDrBtw7j6HkouiYKpSj4U',
  175: 'price_1IdzE1Btw7j6HkouliB58LsF',
  200: 'price_1IdzE9Btw7j6HkouScLkuQOZ',
};

export const ltdPriceIds = pickValueForDeploymentEnv(localLtdIds, liveLtdIds);
