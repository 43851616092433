import { VersionHistory, WriteForMe } from './../../modules/task/index';
import { Reducer } from 'redux';
import { Task } from 'src/modules/task';
import * as task from 'src/redux/actions/task';
import { outputLengths } from 'src/modules/playground';

export type ByMinuteVersionHistory = {
  date: string;
  history: VersionHistory[];
};

/** Redux project state. */
export interface State {
  byId: {
    [projectId: string]: {
      [taskId: string]: Task;
    };
  };
  tasks?: Task[];
  byMinuteVersionHistory: {
    [projectId: string]: {
      [taskId: string]: ByMinuteVersionHistory[];
    };
  };
}

const initialState: State = {
  byId: {},
  byMinuteVersionHistory: {},
};

function seperateByMinute(
  versionHistory: VersionHistory[],
): ByMinuteVersionHistory[] {
  const byMinuteVersionHistory = versionHistory.reduce(
    (version, currentVersionHistory) => {
      const date = new Date(currentVersionHistory.date);
      const datestring = date.toLocaleString().slice(0, 17);
      if (version.filter(item => item.date === datestring).length > 0) {
        return version.map(byMinuteHistory =>
          byMinuteHistory.date === datestring
            ? {
                date: byMinuteHistory.date,
                history: [...byMinuteHistory.history, currentVersionHistory],
              }
            : byMinuteHistory,
        );
      } else {
        version.push({ date: datestring, history: [currentVersionHistory] });
        return version;
      }
    },
    [] as ByMinuteVersionHistory[],
  );
  return byMinuteVersionHistory;
}

/**
 * Reducer for the task state.
 * @param state Current task state.
 * @param action Redux action.
 * @returns New task state.
 */
const taskReducer: Reducer<State, task.Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case task.getTask.types.success: {
      const { projectId, taskId } = action.meta;
      return {
        ...state,
        byId: {
          [projectId]: {
            [taskId]: action.response,
          },
        },
        byMinuteVersionHistory: {
          [projectId]: {
            [taskId]: action.response.versionHistory
              ? seperateByMinute(action.response.versionHistory?.reverse())
              : [],
          },
        },
      };
    }
    case task.getTasks.types.success:
    case task.getTasksByUserId.types.success:
      return {
        ...state,
        tasks: action.response,
      };
    case task.createTask.types.success:
      return {
        ...state,
        tasks: state.tasks
          ? [...state.tasks, action.response]
          : [action.response],
      };
    case task.deleteTask.types.success: {
      const { taskId } = action.meta;
      return {
        ...state,
        tasks: state.tasks
          ? [...state.tasks.filter(task => taskId !== task.taskId)]
          : [],
      };
    }
    case task.generateText.types.success: {
      const { projectId, taskId } = action.meta;
      return {
        ...state,
        byId: {
          [projectId]: {
            [taskId]: {
              ...state.byId[projectId][taskId],
              currResults: action.response,
            },
          },
        },
      };
    }
    case task.updateName.types.success:
    case task.likeResult.types.success:
    case task.unlikeResult.types.success:
    case task.updateTaskInputs.types.success:
    case task.generateInlineText.types.success:
    case task.generateWriteForMeTextMeta.types.success:
    case task.savePlaygroundText.types.success: {
      const { projectId, taskId } = action.meta;
      return {
        ...state,
        byId: {
          [projectId]: {
            [taskId]: action.response,
          },
        },
      };
    }

    case task.updateTranslationLanguage.types.success: {
      const { projectId, taskId } = action.meta;
      return {
        ...state,
        byId: {
          [projectId]: {
            [taskId]: {
              ...state.byId[projectId][taskId],
              language: action.response,
            },
          },
        },
      };
    }

    case task.updateWriteForMe.types.success: {
      const { projectId, taskId } = action.meta;
      const { contentBrief, playgroundText, title } = action.response;
      const writeForMe: WriteForMe = {
        brief: contentBrief,
        title: title,
        outputLength:
          state.byId[projectId][taskId].writeForMe?.outputLength ||
          outputLengths.long,
      };
      return {
        ...state,
        byId: {
          [projectId]: {
            [taskId]: {
              ...state.byId[projectId][taskId],
              writeForMe,
              playgroundText,
            },
          },
        },
      };
    }

    default:
      return state;
  }
};

export default taskReducer;
