import React, { useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import styles from './HelpModal.module.scss';
import { ReactComponent as CloseIcon } from 'src/assets/generalClose.svg';
import { ReactComponent as Chevron } from 'src/assets/right-arrow.svg';
import { ReactComponent as TutorialIcon } from 'src/assets/reading-book.svg';
import { ReactComponent as FacebookIcon } from 'src/assets/facebook.svg';
import { ReactComponent as LetterIcon } from 'src/assets/letter.svg';
import {
  DisappointmentTypes,
  levelOfDisappointment,
} from 'src/modules/feedback';
import { useApi } from 'src/api';
import { getUserId } from 'src/redux/selectors/auth';
import Spinner from '../../Spinner';
import { colours } from 'src/modules/colours';
import ModalWrapper from '../../ModalWrapper';

type ItemProps = {
  onClick: () => void;
  title: string;
  description: string;
  children: JSX.Element;
};

function HelpItem({
  onClick,
  title,
  description,
  children,
}: ItemProps): JSX.Element {
  return (
    <div className={styles.HelpItem} onClick={onClick}>
      <div className={styles.HelpItemLeft}>
        <div className={styles.HelpIconContainer}>{children}</div>
        <div className={styles.ItemText}>
          <div className={styles.ItemTitle}>{title}</div>
          <div className={styles.ItemDescription}>{description}</div>
        </div>
      </div>
      <Chevron className={styles.Chevron} />
    </div>
  );
}

type Props = {
  setShowModal: (a: boolean) => void;
};

export default function HelpModal({ setShowModal }: Props): JSX.Element {
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [isHome, setIsHome] = useState(true);
  const api = useApi();
  const userId = useSelector(getUserId);
  const [type, setType] = useState<DisappointmentTypes | undefined>();

  function FeedbackComponent(): JSX.Element {
    const [feedback, setFeedback] = useState('');
    return (
      <>
        <div className={styles.Header}>
          <div className={styles.Title}>Send feedback</div>
          <CloseIcon
            className={styles.Close}
            onClick={() => setShowModal(false)}
          />
        </div>
        <hr className={styles.Hr} />
        <textarea
          value={feedback}
          placeholder="We'd love to hear how we can improve CopyMuse for you."
          onChange={e => setFeedback(e.target.value)}
          className={styles.Textarea}
          autoFocus
        />
        <div className={styles.Question}>
          How disappointed would you feel if you could no longer use CopyMuse?
        </div>
        <div className={styles.TypesContainer}>
          <div
            className={classNames(
              styles.Type,
              type === levelOfDisappointment.very && styles.Selected,
            )}
            onClick={() => setType(levelOfDisappointment.very)}
          >
            {levelOfDisappointment.very}
          </div>
          <div
            className={classNames(
              styles.Type,
              type === levelOfDisappointment.somewhat && styles.Selected,
            )}
            onClick={() => setType(levelOfDisappointment.somewhat)}
          >
            {levelOfDisappointment.somewhat}
          </div>
          <div
            className={classNames(
              styles.Type,
              type === levelOfDisappointment.notAtAll && styles.Selected,
            )}
            onClick={() => setType(levelOfDisappointment.notAtAll)}
          >
            {levelOfDisappointment.notAtAll}
          </div>
        </div>
        <hr className={styles.Hr} />
        <button
          className={styles.Button}
          disabled={!type}
          onClick={() => {
            if (userId && type) {
              setIsApiLoading(true);
              api
                .createFeedback({
                  userId,
                  description: feedback,
                  disappointmentLevel: type,
                })
                .then(() => {
                  setIsApiLoading(false);
                  setShowModal(false);
                })
                .catch(() => setIsApiLoading(false));
            }
          }}
        >
          {isApiLoading ? (
            <Spinner size={12} color={colours.white} />
          ) : (
            'Send feedback'
          )}
        </button>
      </>
    );
  }

  return (
    <ModalWrapper setShowChildrenModal={setShowModal}>
      <div className={styles.HelpModal}>
        {isHome ? (
          <>
            <div className={styles.Header}>
              <div className={styles.Title}>Help</div>
              <CloseIcon
                className={styles.Close}
                onClick={() => setShowModal(false)}
              />
            </div>
            <hr className={styles.Hr} />
            <div className={styles.ItemsContainer}>
              <HelpItem
                title='CopyMuse Tutorials'
                description='Learn how to get the most out of CopyMuse'
                onClick={() =>
                  window.open(
                    'https://gocopy.notion.site/GoCopy-Tutorials-9ca57e9e5d67484a9e7b6de08916f808',
                    '_blank',
                  )
                }
              >
                <TutorialIcon className={styles.HelpItemIcon} />
              </HelpItem>
              <hr className={styles.Hr} />
              
              <HelpItem
                title='Contact Us'
                description="Let us know if there's an issue or a question"
                onClick={() => setIsHome(false)}
              >
                <LetterIcon className={styles.HelpItemIcon} />
              </HelpItem>
            </div>
          </>
        ) : (
          <FeedbackComponent />
        )}
      </div>
    </ModalWrapper>
  );
}
