import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/api';
import AuthenticationWrapper from 'src/components/AuthenticationWrapper';
import FormInput from 'src/components/FormInput';
import { validatePassword } from 'src/utils/validation';
import styles from './NewPassword.module.scss';
import { getUser } from 'src/redux/selectors/user';
import Spinner from 'src/components/Spinner';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import href from 'src/modules/href';
type Props = {
  resetPasswordId: string;
};

export default function NewPasswordPage({
  resetPasswordId,
}: Props): JSX.Element {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [apiError, setApiError] = useState('');
  const [resetPasswordApiError, setResetPasswordApiError] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const api = useApi();
  const user = useSelector(getUser);
  const history = useHistory();
  const passwordError = validatePassword(password);
  const isSamePassword = password === confirmPassword;
  const samePasswordError = () => {
    if (showErrors) {
      if (!isSamePassword) {
        return 'Passwords do not match';
      }
      return '';
    }
    return '';
  };
  useEffect(() => {
    api
      .getUserByResetId(resetPasswordId)
      .catch((e: AxiosError) =>
        setResetPasswordApiError(e.response?.data.errorMessage),
      );
  }, [api, resetPasswordId]);
  useEffect(() => {
    if (user) {
      api.updateResetPassword(resetPasswordId);
    }
  }, [api, user, resetPasswordId]);
  if (!user || resetPasswordApiError) {
    return (
      <AuthenticationWrapper>
        <div className={styles.NewPasswport}>
          {resetPasswordApiError ? (
            <div>
              <div className={styles.ResetApiError}>
                {resetPasswordApiError}
              </div>
              <button
                className={styles.ResendButton}
                onClick={() => {
                  history.push(href.resetPassword());
                }}
              >
                Reset Password
              </button>
            </div>
          ) : (
            <Spinner size={18} color='#2e1543' />
          )}
        </div>
      </AuthenticationWrapper>
    );
  }
  return (
    <AuthenticationWrapper>
      <div className={styles.NewPasswport}>
        <div className={styles.Title}>Welcome back!</div>
        <div className={styles.Subheader}>Create New Password</div>
        {apiError && <div className={styles.ApiError}>{apiError}</div>}
        <div className={styles.Container}>
          <FormInput
            placeholderText='Enter new password'
            title='New password'
            password
            input={password}
            onChange={e => setPassword(e.target.value)}
            className={styles.FormInput}
            error={showErrors ? passwordError : ''}
          />
        </div>
        <FormInput
          placeholderText='Confirm password'
          title='Confirm password'
          password
          input={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          className={styles.FormInput}
          error={samePasswordError()}
        />
        <button
          className={styles.Button}
          onClick={() => {
            setShowErrors(true);
            if (!passwordError && isSamePassword) {
              setIsLoading(true);
              api
                .updatePassword({ userId: user.userId, password })
                .then(() => {
                  setIsLoading(false);
                  history.push(href.dashboard());
                })
                .catch((e: AxiosError) => {
                  setIsLoading(false);
                  setApiError(e.response?.data);
                });
            }
          }}
        >
          {isLoading ? (
            <Spinner size={12} color='#ffffff' />
          ) : (
            'Create Password'
          )}
        </button>
      </div>
    </AuthenticationWrapper>
  );
}
