import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Spinner from 'src/components/Spinner';
import { colours } from 'src/modules/colours';
import { getStatistics } from 'src/redux/selectors/generation';
import { getUser } from 'src/redux/selectors/user';
import { isSubscriptionExpired } from 'src/utils/date';
import styles from './GenerateButton.module.scss';

type Props = {
  onClick: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
};

export default function GenerateButton({
  onClick,
  isLoading,
  isDisabled,
}: Props): JSX.Element {
  const user = useSelector(getUser);
  const statistics = useSelector(getStatistics);
  const isButtonDisabled =
    isDisabled ||
    isSubscriptionExpired(user?.subscription.currentPeriodEnd as string) ||
    (statistics && statistics.runsLeft <= 0);
  return (
    <button
      className={classNames(
        styles.Generate,
        isButtonDisabled && styles.Disabled,
      )}
      onClick={onClick}
      disabled={isButtonDisabled}
    >
      {isLoading ? <Spinner size={12} color={colours.white} /> : 'Generate'}
    </button>
  );
}
