import React from 'react';
import { render, hydrate } from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Cohere from 'cohere-js';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.module.scss';

Cohere.init('vOdtdwFvkeLWYumfjQ-N9DTz');
Sentry.init({
  dsn:
    'https://6b8032f72dcf4841beae465b8d58a127@o555493.ingest.sentry.io/5685297',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const rootElement = document.getElementById('root');

const StrictModeApp = () => (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
if (rootElement?.hasChildNodes()) {
  hydrate(<StrictModeApp />, rootElement);
} else {
  render(<StrictModeApp />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
