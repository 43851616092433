import { Values } from '../types';

export const languages = {
  bulgarian: 'BG',
  czech: 'CS',
  danish: 'DA',
  german: 'DE',
  greek: 'EL',
  english: 'EN',
  spanish: 'ES',
  estonian: 'ET',
  finnish: 'FI',
  french: 'FR',
  hungarian: 'HU',
  italian: 'IT',
  japanese: 'JA',
  lithuanian: 'LT',
  latvian: 'LV',
  dutch: 'NL',
  polish: 'PL',
  brazilian: 'PT-BR',
  portuguese: 'PT-PT',
  romanian: 'RO',
  russian: 'RU',
  slovak: 'SK',
  slovenian: 'SL',
  swedish: 'SV',
  chinese: 'ZH',
  hindi: 'HI',
  urdu: 'UR',
  filipino: 'TL',
  indonesian: 'ID',
  khmer: 'km',
  vietnamese: 'vi',
} as const;

export type Languages = Values<typeof languages>;
