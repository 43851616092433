import { Authentication } from 'src/modules/authentication';
import { User } from 'src/modules/user';
import { createActionsBuilder, ExtractActions } from './utils/request';

type GetUserMeta = {
  userId: string;
};
type GetUserResponse = User;

export const getUser = createActionsBuilder<GetUserMeta, GetUserResponse>()(
  'GET_USER_PENDING',
  'GET_USER_SUCCESS',
  'GET_USER_FAILURE',
);
export type GetUserActions = ExtractActions<typeof getUser>;

type GetUsersResponse = User[];

export const getUsers = createActionsBuilder<{}, GetUsersResponse>()(
  'GET_USERS_PENDING',
  'GET_USERS_SUCCESS',
  'GET_USERS_FAILURE',
);
export type GetUsersActions = ExtractActions<typeof getUsers>;

type GetUserByEmailMeta = {
  email: string;
};
type GetUserByEmailResponse = User;

export const getUserByEmail = createActionsBuilder<
  GetUserByEmailMeta,
  GetUserByEmailResponse
>()(
  'GET_USER_BY_EMAIL_PENDING',
  'GET_USER_BY_EMAIL_SUCCESS',
  'GET_USER_BY_EMAIL_FAILURE',
);
export type GetUserByEmailActions = ExtractActions<typeof getUserByEmail>;

type GetUserByResetIdMeta = {
  resetId: string;
};
export type GetUserByResetIdResponse = {
  user: User;
  token: string;
};

export const getUserByResetId = createActionsBuilder<
  GetUserByResetIdMeta,
  GetUserByResetIdResponse
>()(
  'GET_USER_BY_RESET_ID_PENDING',
  'GET_USER_BY_RESET_ID_SUCCESS',
  'GET_USER_BY_RESET_ID_FAILURE',
);
export type GetUserByResetIdActions = ExtractActions<typeof getUserByResetId>;

type UpdateUserMeta = User;
type UpdateUserResponse = User;

export const updateUser = createActionsBuilder<
  UpdateUserMeta,
  UpdateUserResponse
>()('UPDATE_USER_PENDING', 'UPDATE_USER_SUCCESS', 'UPDATE_USER_FAILURE');
export type UpdateUserActions = ExtractActions<typeof updateUser>;

type UpdatePasswordMeta = {
  userId: string;
};
type UpdatePasswordResponse = boolean;

export const updatePassword = createActionsBuilder<
  UpdatePasswordMeta,
  UpdatePasswordResponse
>()(
  'UPDATE_PASSWORD_PENDING',
  'UPDATE_PASSWORD_SUCCESS',
  'UPDATE_PASSWORD_FAILURE',
);
export type UpdatePasswordActions = ExtractActions<typeof updatePassword>;

type DeleteUserMeta = {
  userId: string;
};
type DeleteUserResponse = Authentication;

export const deleteUser = createActionsBuilder<
  DeleteUserMeta,
  DeleteUserResponse
>()('DELETE_USER_PENDING', 'DELETE_USER_SUCCESS', 'DELETE_USER_FAILURE');
export type DeleteUserActions = ExtractActions<typeof deleteUser>;

type StackCodeMeta = {
  userId: string;
  code: string;
};

type StackCodeResponse = User;

export const stackCode = createActionsBuilder<
  StackCodeMeta,
  StackCodeResponse
>()('STACK_CODE_PENDING', 'STACK_CODE_SUCCESS', 'STACK_CODE_FAILURE');
export type StackCodeActions = ExtractActions<typeof stackCode>;

export type Actions =
  | GetUserActions
  | GetUserByEmailActions
  | UpdateUserActions
  | GetUserByResetIdActions
  | UpdatePasswordActions
  | DeleteUserActions
  | GetUsersActions
  | StackCodeActions;
