import * as favourites from 'src/redux/actions/favourites';
import { Reducer } from 'redux';
import { Favourite } from 'src/modules/favourites';

export interface State {
  favourites?: Favourite[];
}

const initialState: State = {};

/**
 * Reducer for the activation code state.
 * @param state Current activation code state.
 * @param action Redux action.
 * @returns New activation code state.
 */

const favouritesReducer: Reducer<State, favourites.Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case favourites.createFavourite.types.success:
      const fav = action.meta;
      return {
        ...state,
        favourites: state.favourites ? [...state.favourites, fav] : [fav],
      };
    case favourites.removeFavourite.types.success:
      return {
        ...state,
        favourites: state.favourites
          ? state.favourites?.filter(f => f.taskId !== action.meta.taskId)
          : [],
      };
    case favourites.getFavourites.types.success:
      return {
        ...state,
        favourites: action.response,
      };
    default:
      return state;
  }
};

export default favouritesReducer;
