import { Task } from 'src/modules/task';
import {
  AnnouncementProps,
  BlogIntroProps,
  BlogOutlineProps,
  BulletPointExpanderProps,
  CaptionProps,
  EmailSubjectLineProps,
  FacebookAdProps,
  GoogleAdProps,
  InstagramAdProps,
  LinkedInProps,
  ListicleProps,
  MetaDescriptionProps,
  NameGeneratorProps,
  ProductDescriptionPromtProps,
  SummarizerProps,
  TaskPromptProps,
} from 'src/modules/task/promptProps';
import { taskTypes } from 'src/modules/task/tasktypes';

type FacebookProps = {
  primaryText: string;
  headline: string;
  linkDescription: string;
};

export function extractFacebookParts(text: string): FacebookProps {
  const tokens = text.split(/[\s\n]+/);
  const primaryText = [];
  const headline = [];
  const linkDescription = [];
  let isHeadline = false;
  let isLink = false;
  for (let i = 0; i < tokens.length; i++) {
    const currToken = tokens[i];
    if (
      currToken === 'Link' &&
      (tokens[i + 1] === 'description:' || tokens[i + 1] === 'Description:')
    ) {
      isLink = true;
      isHeadline = false;
    }
    if (currToken === 'Headline:') {
      isHeadline = true;
      isLink = false;
    }
    if (isHeadline) {
      if (currToken !== 'Headline:') {
        headline.push(currToken);
      }
    } else if (isLink) {
      if (
        currToken !== 'Link' &&
        currToken !== 'description:' &&
        currToken !== 'Description:'
      ) {
        linkDescription.push(currToken);
      }
    } else {
      if (currToken !== 'Primary' && currToken !== 'Text:') {
        primaryText.push(currToken);
      }
    }
  }

  return {
    primaryText: primaryText.join(' '),
    headline: headline.join(' '),
    linkDescription: linkDescription.join(' '),
  };
}

type GoogleProps = {
  headline: string;
  description: string;
};

export function extractGoogleParts(text: string): GoogleProps {
  const tokens = text.split(/[\s\n]+/);
  const headline = [];
  const description = [];
  let isDescription = false;
  for (let i = 0; i < tokens.length; i++) {
    const currToken = tokens[i];
    if (currToken === 'Description:') {
      isDescription = true;
    }
    if (isDescription) {
      if (currToken !== 'Description:') {
        description.push(currToken);
      }
    } else {
      headline.push(currToken);
    }
  }
  return {
    headline: headline.join(' '),
    description: description.join(' '),
  };
}

export function createNewProps(task: Task, currIdea: string): TaskPromptProps {
  const { currentInput } = task;
  switch (task.mostRecentTask) {
    case taskTypes.blogIdea: {
      return { ...currentInput, topic: currIdea, type: taskTypes.blogIdea };
    }
    case taskTypes.blogIntro: {
      return {
        ...(currentInput as BlogIntroProps),
        description: currIdea,
        type: taskTypes.blogIntro,
      };
    }
    case taskTypes.caption: {
      return {
        ...(currentInput as CaptionProps),
        topic: currIdea,
        type: taskTypes.caption,
      };
    }
    case taskTypes.facebookAd: {
      const facebookParts = extractFacebookParts(currIdea);
      return {
        ...(currentInput as FacebookAdProps),
        productDescription: facebookParts.primaryText,
        type: taskTypes.facebookAd,
      };
    }
    case taskTypes.googleAd: {
      const googleParts = extractGoogleParts(currIdea);
      return {
        ...(currentInput as GoogleAdProps),
        productDescription: googleParts.description,
        type: taskTypes.googleAd,
      };
    }
    case taskTypes.heroText: {
      return {
        ...currentInput,
        description: currIdea,
        type: taskTypes.heroText,
      };
    }
    case taskTypes.instagramAd: {
      const { productDescription } = currentInput as InstagramAdProps;
      const newDescription = productDescription + currIdea;
      return {
        ...(currentInput as InstagramAdProps),
        productDescription: newDescription,
        type: taskTypes.instagramAd,
      };
    }
    case taskTypes.microcopy: {
      return { ...currentInput, what: currIdea, type: taskTypes.microcopy };
    }
    case taskTypes.productDescription: {
      return {
        ...(currentInput as ProductDescriptionPromtProps),
        productDescription: currIdea,
        type: taskTypes.productDescription,
      };
    }
    case taskTypes.emailSubjectLine: {
      return {
        ...(currentInput as EmailSubjectLineProps),
        motivation: currIdea,
        type: taskTypes.emailSubjectLine,
      };
    }
    case taskTypes.announcement: {
      return {
        ...(currentInput as AnnouncementProps),
        topic: currIdea,
        type: taskTypes.announcement,
      };
    }
    case taskTypes.linkedInAd: {
      return {
        ...(currentInput as LinkedInProps),
        description: currIdea,
        type: taskTypes.linkedInAd,
      };
    }
    case taskTypes.metaDescription: {
      return {
        ...(currentInput as MetaDescriptionProps),
        description: currIdea,
        type: taskTypes.metaDescription,
      };
    }
    case taskTypes.nameGenerator: {
      return {
        ...(currentInput as NameGeneratorProps),
        type: taskTypes.nameGenerator,
      };
    }
    case taskTypes.blogOutline: {
      return {
        ...(currentInput as BlogOutlineProps),
        type: taskTypes.blogOutline,
      };
    }
    case taskTypes.contentRewriter: {
      return {
        type: taskTypes.contentRewriter,
        old: currIdea,
      };
    }
    case taskTypes.bulletPointExpander: {
      return {
        ...(currentInput as BulletPointExpanderProps),
        type: taskTypes.bulletPointExpander,
      };
    }
    case taskTypes.listile: {
      return {
        ...(currentInput as ListicleProps),
        type: taskTypes.listile,
      };
    }
    case taskTypes.summarizer: {
      return {
        ...(currentInput as SummarizerProps),
        type: taskTypes.summarizer,
      };
    }
    default: {
      return {
        ...currentInput,
        description: currIdea,
        type: taskTypes.subheaderText,
      };
    }
  }
}
