import { createActionsBuilder, ExtractActions } from './utils/request';

type CreateFeedbackMeta = {};
type CreateFeedackResponse = string;

export const createFeedback = createActionsBuilder<
  CreateFeedbackMeta,
  CreateFeedackResponse
>()(
  'CREATE_FEEDACK_PENDING',
  'CREATE_FEEDACK_SUCCESS',
  'CREATE_FEEDACK_FAILURE',
);
export type CreateFeedbackActions = ExtractActions<typeof createFeedback>;

export type Actions = CreateFeedbackActions;
