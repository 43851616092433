import { Values } from '../types';

export const taskTypes = {
  productDescription: 'Product Description',
  microcopy: 'Microcopy',
  caption: 'Caption',
  facebookAd: 'Facebook Ad',
  instagramAd: 'Instagram Ad',
  googleAd: 'Google Ad',
  blogIdea: 'Blog Idea',
  blogIntro: 'Blog Intro',
  heroText: 'Landing Page Hero Text',
  subheaderText: 'Subheader Text',
  metaDescription: 'Meta Description',
  blogOutline: 'Blog Outline',
  emailSubjectLine: 'Email Subject Line',
  linkedInAd: 'LinkedIn Ad',
  announcement: 'Announcement',
  nameGenerator: 'Name Generator',
  listile: 'Listicle',
  bulletPointExpander: 'Bullet Point Expander',
  contentRewriter: 'Content Rewriter',
  playground: 'Playground',
  summarizer: 'Summarizer',
  writeForMe: 'Write For Me',
  writeBlogPost: 'Write Blog Post',
  instruct: 'Instruct',
  simplify: 'Simplify',
  changeTone: 'Change Tone',
  contextualInstruct: 'Contextual Instruct',
} as const;
export type TaskTypes = Values<typeof taskTypes>;
