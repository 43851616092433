import classNames from 'classnames';
import React, { useEffect } from 'react';
import Hotkeys from 'react-hot-keys';
import { useSelector } from 'react-redux';
import { useApi } from 'src/api';
import { getUserId } from 'src/redux/selectors/auth';
import PageHelmet from '../PageHelmet';
import styles from './Wrapper.module.scss';

type Props = {
  children: JSX.Element;
  title: string;
  description?: string;
  hotKeys: {
    key: string;
    title: string;
    function: () => void;
    isNew?: boolean;
  }[];
  isOverlayActive: boolean;
  modalInJsx: JSX.Element;
  helmetUrl: string;
};

export default function DashboardWrapper({
  children,
  title,
  description,
  hotKeys,
  isOverlayActive,
  modalInJsx,
  helmetUrl,
}: Props): JSX.Element {
  const userId = useSelector(getUserId);
  const api = useApi();
  useEffect(() => {
    if (userId) {
      api.getUser(userId);
    }
  }, [api, userId]);

  var callbacks: { [key: string]: () => void } = {};

  function add(_case: string, fn: () => void) {
    callbacks[_case] = callbacks[_case] || [];
    callbacks[_case] = fn;
  }

  function pseudoSwitch(value: string) {
    if (callbacks[value]) {
      const fn = callbacks[value];
      fn();
    }
  }

  hotKeys.forEach(hk => add(hk.key, hk.function));

  return (
    <Hotkeys
      keyName={`${hotKeys.map(hotKey => hotKey.key).join(',')}`}
      onKeyDown={(keyName, e) => {
        e.preventDefault();
      }}
      onKeyUp={(keyName, e) => {
        pseudoSwitch(keyName);
      }}
    >
      <>
        <PageHelmet url={helmetUrl} />
        {/* <div className={styles.AnnouncementContainer}>
          <div className={styles.Announcement}>
            We are currently upgrading our databases. You will not be able to
            use our service for the next 5-10 minutes.
          </div>
        </div> */}
        <div className={styles.Wrapper}>
          <div className={styles.Container}>
            <div className={styles.CommandsContainer}>
              <div className={styles.PageIntroduction}>
                <span className={styles.Greetings}>{title}</span>
                {description && (
                  <span className={styles.Description}>{description}</span>
                )}
              </div>
              <hr className={styles.Separator} />
              <div className={styles.Commands}>
                {hotKeys.map(hk => (
                  <div
                    className={styles.Command}
                    key={hk.key}
                    onClick={hk.function}
                  >
                    <div
                      className={classNames(
                        styles.Key,
                        hk.isNew && styles.NewKey,
                      )}
                    >
                      <span>{hk.key}</span>
                    </div>
                    <span
                      className={classNames(
                        styles.KeyTitle,
                        hk.isNew && styles.NewKeyTitle,
                      )}
                    >
                      {hk.title}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.Children}>{children}</div>
          </div>
        </div>
        {isOverlayActive && <div className={styles.Overlay}>{modalInJsx}</div>}
      </>
    </Hotkeys>
  );
}
