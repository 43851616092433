import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './EmailConfirmationPage.module.scss';
import { useApi } from 'src/api';
import { useHistory } from 'react-router-dom';
// import href from 'src/modules/href';
import { useSelector } from 'react-redux';
import href from 'src/modules/href';

import {starterV2PriceIds,loadPublishableKey} from 'src/utils/priceIds';
import { getClientReferenceId } from '../SettingsPage/rewardful';
import { loadStripe } from '@stripe/stripe-js';

import {
  getActivationCode,
  getActivationCodeExpiryDate,
  getActivationUsed,
} from 'src/redux/selectors/activationCode';
import Spinner from 'src/components/Spinner';
import { colours } from 'src/modules/colours';
import axios from 'axios';
import { uuid4 } from '@sentry/utils';

export type EmailConfirmationPageProps = {
  encodedUser: string;
};

type DigitContainerProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const DigitContainer = ({ value, onChange }: DigitContainerProps) => {
  return (
    <div className={styles.DigitContainer}>
      <input
        className={styles.Input}
        type='tel'
        onChange={onChange}
        value={value}
        maxLength={1}
      />
    </div>
  );
};

function checker(value: string): string {
  return value.replace(/\D/, '');
}

const isSameCode = (houseCode: number, inputtedCode: number): boolean =>
  houseCode === inputtedCode;

export default function EmailConfirmationPage({
  encodedUser,
}: EmailConfirmationPageProps): JSX.Element {
  const user = JSON.parse(atob(encodedUser));
  const api = useApi();
  const history = useHistory();
  const [firstDigit, setFirstDigit] = useState('');
  const [secondDigit, setSecondDigit] = useState('');
  const [thirdDigit, setThirdDigit] = useState('');
  const [fourthDigit, setFourthDigit] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const inputtedCode = parseInt(
    firstDigit + secondDigit + thirdDigit + fourthDigit,
  );
  const [expiredLink, setExpiredLink] = useState(false);
  const [isWrongCode, setIsWrongCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isThreat, setIsThreat] = useState(false);
  const code = useSelector(getActivationCode);
  const expiryDate = useSelector(getActivationCodeExpiryDate);
  const alreadyUsed = useSelector(getActivationUsed);

  useEffect(() => {
    api.getActivationCode(user.email).catch(() => setExpiredLink(true));
  }, [api, user]);

  useEffect(() => {
    axios
      .get(
        'https://api.ipdata.co/?api-key=9dbfdec20d9023bd57083d324c71490f6f6e735a8892a738730cd53c',
      )
      .then(res =>
        res.data.threat.is_tor ||
        res.data.threat.is_proxy ||
        res.data.threat.is_anonymous ||
        res.data.threat.is_known_attacker ||
        res.data.threat.is_known_abuser ||
        res.data.threat.is_bogon ||
        res.data.country_name === 'Nigeria' ||
        res.data.country_code === 'NG'
          ? setIsThreat(true)
          : setIpAddress(res.data.ip),
      );
  }, []);

  useEffect(() => {
    if (expiryDate) {
      const currentDate = new Date();
      const codeExpiryDate = new Date(expiryDate);
      if (currentDate > codeExpiryDate || alreadyUsed) {
        setExpiredLink(true);
      }
    }
  }, [expiryDate, alreadyUsed]);

  // This conditional is for while the api call is being made
  if ((!code || !expiryDate) && !expiredLink) {
    return (
      <div className={classNames(styles.EmailConfirmationPage)}>
        <Spinner size={20} color={colours.black} />
      </div>
    );
  }

  return (
    <div className={classNames(styles.EmailConfirmationPage)}>
      {expiredLink ? (
        <div className={styles.Form}>
          <div className={styles.Title}>
            <span>Your activation code has expired.</span>
          </div>
          <div className={styles.SubTitle}>
            <span>
              Please click on the button below to receive a new activation code.
              It should arrive in the next 1 - 3 minutes.
            </span>
          </div>
          <button
            className={styles.SubmitButton}
            onClick={() => {
              const { email } = user;
              api
                .emailConfirmation({ email, link: encodedUser })
                .then(() => setExpiredLink(false));
            }}
          >
            Submit
          </button>
        </div>
      ) : (
        <div className={styles.Form}>
          {isThreat && (
            <div className={styles.Container}>
              <div className={styles.Suspicious}>
                We have detected suspicious activity. You are not allowed to
                create an account until you remove your open proxy or tor node.
              </div>
            </div>
          )}
          <div className={styles.Title}>
            <span>Confirm Your Email</span>
          </div>
          <div className={styles.SubTitle}>
            <span>
              We just sent you an email with a 4 digit code. It should arrive in
              the next 2-3 minutes. Please enter the code below.
            </span>
          </div>
          {isWrongCode && (
            <div className={styles.CodeError}>
              <span>Invalid Activation Code</span>
            </div>
          )}
          <div className={styles.CodeContainer}>
            <DigitContainer
              value={firstDigit}
              onChange={e => setFirstDigit(checker(e.target.value))}
            />
            <DigitContainer
              value={secondDigit}
              onChange={e => setSecondDigit(checker(e.target.value))}
            />
            <DigitContainer
              value={thirdDigit}
              onChange={e => setThirdDigit(checker(e.target.value))}
            />
            <DigitContainer
              value={fourthDigit}
              onChange={e => setFourthDigit(checker(e.target.value))}
            />
          </div>
          <button
            className={styles.SubmitButton}
            disabled={
              isThreat ||
              firstDigit.length === 0 ||
              secondDigit.length === 0 ||
              thirdDigit.length === 0 ||
              fourthDigit.length === 0
            }
            onClick={() => {
              if (expiryDate && code) {
                if (isSameCode(code, inputtedCode)) {
                  api.expireActivationCode(user.email);
                  setIsLoading(true);
                  api
                    .signUp({
                      email: user.email,
                      password: user.password,
                      coupon: user.coupon,
                      code:"split",
                      ipAddress: ipAddress || uuid4(),
                    })
                    .then(res => {
                      history.push(
                        href.playground({
                          projectId: res.taskData.projectId,
                          taskId: res.taskData.taskId,
                        }),
                      );
                      setIsLoading(false);
                    })
                    .catch(e => {
                      setIsLoading(false);
                    });
                } else {
                  setFirstDigit('');
                  setSecondDigit('');
                  setThirdDigit('');
                  setFourthDigit('');
                  setIsWrongCode(true);
                }
              }
            }}
          >
            {isLoading ? (
              <Spinner size={12} color='#ffffff' />
            ) : (
              'Activate  Account'
            )}
          </button>
          <div className={styles.ResendComponent}>
            <span>Did not receive the email?</span>
            <div
              className={styles.Resend}
              onClick={() => {
                api
                  .emailConfirmation({
                    email: user.email,
                    link: encodedUser,
                  })
                  .then(() => api.getActivationCode(user.email));
              }}
            >
              RESEND HERE
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
