import * as auth from 'src/redux/actions/auth';
import { Reducer } from 'redux';

export interface State {
  code?: number;
  expiryDate?: string;
  expired?: boolean;
}

const initialState: State = {};

/**
 * Reducer for the activation code state.
 * @param state Current activation code state.
 * @param action Redux action.
 * @returns New activation code state.
 */

const activationCodeReducer: Reducer<State, auth.Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case auth.getActivationCode.types.success:
      return {
        code: action.response.code,
        expiryDate: action.response.expiryDate,
        expired: action.response.expired,
      };
    default:
      return state;
  }
};

export default activationCodeReducer;
