import React from 'react';
import Cohere from 'cohere-js';
import { useSelector } from 'react-redux';
import { Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom';
import hrefs, { Href } from 'src/modules/href';
import Page404 from 'src/pages/404Page';
import AppsumoPage from 'src/pages/AppsumoPage';
import NewLandingPage from 'src/pages/NewLandingPage';
import NewPasswordPage from 'src/pages/NewPasswordPage';
import PlaygroundPage from 'src/pages/PlaygroundPage';
import PrivacyPolicy from 'src/pages/PrivacyPolicy';
import ResetPasswordPage from 'src/pages/ResetPasswordPage';
import SettingsPage from 'src/pages/SettingsPage';
import SignInPage from 'src/pages/SignInPage';
import SignUpPage from 'src/pages/SignUpPage';
import SignUpPageSplit from 'src/pages/SignUpPageSplit';
import SuccessPage from 'src/pages/SuccessPage';
import TermsOfService from 'src/pages/TermsOfService';
import { getAuth } from 'src/redux/selectors/auth';
import { getUser } from 'src/redux/selectors/user';
import EmailConfirmationPage from 'src/pages/EmailConfirmationPage';
import EmailConfirmationPageSplit from 'src/pages/EmailConfirmationPageSplit';
import Unlimited from 'src/pages/UnlimitedShakespearePage';
import LTDPage from 'src/pages/LTDPage';
import DashboardPage from 'src/pages/DashboardPage';

/* eslint-disable @typescript-eslint/no-explicit-any */
type HrefProps<H extends Href<any>> = H extends () => string
  ? {} // A parameterless href yields an empty props object
  : H extends (params: infer Params) => string
  ? Params // An href with parameters yields those parameters as props
  : never;

/**
 * For a given type of Href, get the search params
 * as an object e.g. ?id=123 gives { id: '123' }
 */
const getUrlSearchParams = <H extends Href<any>>(
  props: RouteComponentProps<HrefProps<H>>,
): Record<HrefProps<H>, string> => {
  const search = props.location.search;
  const urlSearchParams = new URLSearchParams(search);
  const params: Record<string, string> = {};
  for (const [key, value] of urlSearchParams) {
    if (key !== 'ref') {
      params[key] = value;
    }
  }
  return params;
};

/**
 * For a given type of Href, this provides type-safe conversion
 * of url parameters to page component props.
 */
const render =
  <H extends Href<any>>(Component: React.ComponentType<HrefProps<H>>) =>
  (props: RouteComponentProps<HrefProps<H>>): React.ReactNode => {
    return <Component {...props.match.params} {...getUrlSearchParams(props)} />;
  };
/* eslint-enable @typescript-eslint/no-explicit-any */

type PrivateRouteProps<H extends Href<any>> = {
  isAuthenticated: boolean;
  component: React.ComponentType<HrefProps<H>>;
  path: string;
  type: (() => string) | ((params: any) => string);
  redirectPath: string;
  isExact: boolean;
};
const PrivateRoute = <H extends Href<any>>({
  isAuthenticated,
  component,
  path,
  redirectPath,
  type,
  isExact,
}: PrivateRouteProps<H>) => {
  return (
    <Route
      exact={isExact}
      path={path}
      render={
        isAuthenticated
          ? render<typeof type>(component)
          : () => <Redirect to={redirectPath} />
      }
    />
  );
};

/**
 * Defines all client-side routes within the app.
 */
export default function Routes(): JSX.Element {
  const isAuthenticated = useSelector(getAuth);
  const user = useSelector(getUser);
  if (user) {
    Cohere.identify(
      user.userId, // Required: can be any unique ID
      {
        displayName: user.email, // Optional
        email: user.email, // Optional
      },
    );
  }
  return (
    <>
      <Switch>
        <Redirect to={hrefs.landing()} from={'/?ref=producthunt'} />
        <Route
          exact
          path={hrefs.signUp()}
          render={render<typeof hrefs.signUp>(SignUpPage)}
        />
        <Route
          exact
          path={hrefs.signUpSplit()}
          render={render<typeof hrefs.signUpSplit>(SignUpPageSplit)}
        />
        <Route
          exact
          path={hrefs.signIn()}
          render={render<typeof hrefs.signIn>(SignInPage)}
        />
        <Route
          exact
          path={hrefs.landing()}
          render={render<typeof hrefs.landing>(NewLandingPage)}
        />
        <Route
          exact
          path={hrefs.appsumo()}
          render={render<typeof hrefs.appsumo>(AppsumoPage)}
        />
        <Route
          exact
          path={hrefs.resetPassword()}
          render={render<typeof hrefs.resetPassword>(ResetPasswordPage)}
        />
        <Route
          path={hrefs.newPassword({ resetPasswordId: ':resetPasswordId' })}
          render={render(NewPasswordPage)}
        />
        <Route
          path={hrefs.termsOfService()}
          render={render<typeof hrefs.termsOfService>(TermsOfService)}
        />
        <Route
          path={hrefs.privacyPolicy()}
          render={render<typeof hrefs.privacyPolicy>(PrivacyPolicy)}
        />
        <Route
          path={hrefs.facebookDeletion()}
          render={render<typeof hrefs.facebookDeletion>(() => (
            <div>Facebook data successfully deleted!</div>
          ))}
        />
        <Route
          path={hrefs.emailConfirmation({ encodedUser: ':encodedUser' })}
          render={render(EmailConfirmationPage)}
        />
        <Route
          path={hrefs.emailConfirmationSplit({ encodedUser: ':encodedUser' })}
          render={render(EmailConfirmationPageSplit)}
        />
        <PrivateRoute
          isExact
          isAuthenticated={isAuthenticated}
          path={hrefs.ltd()}
          type={hrefs.ltd}
          component={LTDPage}
          redirectPath={hrefs.landing()}
        />

        <PrivateRoute
          isExact
          isAuthenticated={isAuthenticated}
          component={DashboardPage}
          type={hrefs.dashboard}
          path={hrefs.dashboard()}
          redirectPath={hrefs.landing()}
        />
        <PrivateRoute
          isExact
          isAuthenticated={isAuthenticated}
          component={SettingsPage}
          type={hrefs.settings}
          path={hrefs.settings()}
          redirectPath={hrefs.landing()}
        />
        <PrivateRoute
          isExact={false}
          isAuthenticated={isAuthenticated}
          component={SuccessPage}
          type={hrefs.success}
          path={hrefs.success()}
          redirectPath={hrefs.landing()}
        />
        <PrivateRoute
          isExact={true}
          isAuthenticated={isAuthenticated}
          component={Unlimited}
          type={hrefs.unlimitedShakespeare}
          path={hrefs.unlimitedShakespeare()}
          redirectPath={hrefs.landing()}
        />
        <PrivateRoute
          isExact={false}
          isAuthenticated={isAuthenticated}
          component={PlaygroundPage}
          type={hrefs.playground}
          path={hrefs.playground({
            projectId: ':projectId',
            taskId: ':taskId',
          })}
          redirectPath={hrefs.landing()}
        />
        <Route component={Page404} />
      </Switch>
    </>
  );
}
