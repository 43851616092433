import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useApi } from 'src/api';
import HelpModal from 'src/components/Modals/HelpModal';
import DashboardWrapper from 'src/components/NewDashboardWrapper';
import Spinner from 'src/components/Spinner';
import { colours } from 'src/modules/colours';
import hrefs from 'src/modules/href';
import { subscriptionTypes, SubscriptionTypes } from 'src/modules/user';
import { getUser } from 'src/redux/selectors/user';
import {
  loadPublishableKey,
  unlimitedShakespearePriceIds,
} from 'src/utils/priceIds';
import styles from './Unlimited.module.scss';

export default function Unlimited() {
  const history = useHistory();
  const api = useApi();
  const user = useSelector(getUser);
  const [showHelpModal, setsShowHelpModal] = useState(false);
  const [subscriptionType, setSubscriptionType] =
    useState<SubscriptionTypes | undefined>(undefined);
  useEffect(() => {
    if (user) {
      const {
        subscription: { type },
      } = user;
      setSubscriptionType(type);
    }
  }, [user]);
  if (!user) {
    return (
      <DashboardWrapper
        title='Unlimited Shakespeare'
        description='Manage your billing information and subscription plan'
        helmetUrl={hrefs.billing()}
        hotKeys={[
          {
            key: 'H',
            title: 'home',
            function: () => history.push(hrefs.dashboard()),
          },
          {
            key: 'A',
            title: 'account settings',
            function: () => history.push(hrefs.settings()),
          },
          {
            key: 'B',
            title: 'billing',
            function: () => history.push(hrefs.billing()),
          },
          {
            key: 'F',
            title: 'feedback',
            function: () => setsShowHelpModal(true),
          },
        ]}
        isOverlayActive={showHelpModal}
        modalInJsx={<HelpModal setShowModal={setsShowHelpModal} />}
      >
        <div className={styles.Billing}>
          <Spinner size={18} color={colours.black} />
        </div>
      </DashboardWrapper>
    );
  }
  const date = new Date(user.subscription.currentPeriodEnd);
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const formattedDate = date.toLocaleDateString(
    'en-US',
    options as Intl.DateTimeFormatOptions,
  );
  if (
    subscriptionTypes.appsumo !== subscriptionType &&
    subscriptionTypes.appsumoUnlimitedAnnual !== subscriptionType &&
    subscriptionTypes.appsumoUnlimitedMonthly !== subscriptionType
  ) {
    return (
      <DashboardWrapper
        title='Unlimited Shakespeare'
        description='Manage your billing information and subscription plan'
        helmetUrl={hrefs.billing()}
        hotKeys={[
          {
            key: 'H',
            title: 'home',
            function: () => history.push(hrefs.dashboard()),
          },
          {
            key: 'B',
            title: 'billing',
            function: () => history.push(hrefs.billing()),
          },
          {
            key: 'A',
            title: 'account settings',
            function: () => history.push(hrefs.settings()),
          },
          {
            key: 'F',
            title: 'feedback',
            function: () => setsShowHelpModal(true),
          },
        ]}
        isOverlayActive={showHelpModal}
        modalInJsx={<HelpModal setShowModal={setsShowHelpModal} />}
      >
        <div className={styles.PlanContainer}>
          <div className={styles.Subheader}>
            This page is only for LTD customers!
          </div>
        </div>
      </DashboardWrapper>
    );
  }
  return (
    <DashboardWrapper
      title='Unlimited Shakespeare'
      description='Manage your billing information and subscription plan'
      helmetUrl={hrefs.billing()}
      hotKeys={[
        {
          key: 'H',
          title: 'home',
          function: () => history.push(hrefs.dashboard()),
        },
        {
          key: 'B',
          title: 'billing',
          function: () => history.push(hrefs.billing()),
        },
        {
          key: 'A',
          title: 'account settings',
          function: () => history.push(hrefs.settings()),
        },
        {
          key: 'F',
          title: 'feedback',
          function: () => setsShowHelpModal(true),
        },
      ]}
      isOverlayActive={showHelpModal}
      modalInJsx={<HelpModal setShowModal={setsShowHelpModal} />}
    >
      <div className={styles.Billing}>
        <div className={styles.PlanContainer}>
          <div className={styles.Subheader}>Current Plan</div>
          <div className={styles.Currentplan}>
            <div className={styles.Text}>You are currently on the</div>
            <div className={styles.Plan}>{user.subscription.type}</div>
            <div className={styles.Text}>plan.</div>
          </div>
          {(user.subscription.type ===
            subscriptionTypes.appsumoUnlimitedAnnual ||
            user.subscription.type ===
              subscriptionTypes.appsumoUnlimitedMonthly) && (
            <div>
              <div className={styles.ExpiryContainer}>
                <div className={styles.Text}>You're next payment is on</div>
                <div className={styles.Date}>{formattedDate}</div>
              </div>
              <button
                className={styles.Manage}
                onClick={() => {
                  api
                    .createCustomerPortal(user.subscription.customerId)
                    .then(res => {
                      window.location.href = res.url;
                    });
                }}
              >
                Manage your subscription here
              </button>
            </div>
          )}
        </div>
        <hr className={styles.HR} />
        <div className={styles.UnlimitedSection}>
          <div className={styles.Subheader}>Unlimited Shakespeare AI</div>
          {/* <div className={styles.Deal}>
            Get over 20% off by purchasing the Annual plan. This deal ends on
            the 2nd of June 2021.
          </div> */}
          <div className={styles.Prices}>
            <div className={styles.Card}>
              <h3>Monthly</h3>
              <div>$19 per month</div>
              <button
                className={styles.Button}
                onClick={() => {
                  api
                    .createCheckoutSession({
                      priceId: unlimitedShakespearePriceIds.monthly,
                      customerId: user.subscription.customerId,
                    })
                    .then(async data => {
                      const stripe = await loadStripe(loadPublishableKey);
                      stripe?.redirectToCheckout({
                        sessionId: data.sessionId,
                      });
                    });
                }}
              >
                {subscriptionTypes.appsumoUnlimitedMonthly === subscriptionType
                  ? 'Manage'
                  : 'Upgrade'}
              </button>
            </div>
            {/* <div className={styles.Card}>
              <h3>Annual</h3>
              <div className={styles.Deal}>$15 per month</div>
              <button
                className={styles.Button}
                onClick={() => {
                  api
                    .createCheckoutSession({
                      priceId: unlimitedShakespearePriceIds.annual,
                      customerId: user.subscription.customerId,
                    })
                    .then(async data => {
                      const stripe = await loadStripe(loadPublishableKey);
                      stripe?.redirectToCheckout({
                        sessionId: data.sessionId,
                      });
                    });
                }}
              >
                {subscriptionTypes.appsumoUnlimitedAnnual === subscriptionType
                  ? 'Manage'
                  : 'Upgrade'}
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
