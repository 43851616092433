import { Reducer } from 'redux';
import { Ltd } from 'src/modules/ltd';
import * as ltd from 'src/redux/actions/ltd';

/** Redux project state. */
export interface State {
  stats?: Ltd;
}

const initialState: State = {};

/**
 * Reducer for the Ltd state.
 * @param state Current Ltd state.
 * @param action Redux action.
 * @returns New Ltd state.
 */
const ltdReducer: Reducer<State, ltd.Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case ltd.getltdStats.types.success:
      return {
        ...state,
        stats: action.response,
      };
    default:
      return state;
  }
};

export default ltdReducer;
