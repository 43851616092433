import React, { useEffect, useState } from 'react';
import DashboardWrapper from 'src/components/DashboardWrapper';
import { tabs } from 'src/modules/dashboard';
import { ReactComponent as Folder } from 'src/pages/DashboardPage/assets/folder.svg';
import { ReactComponent as Chevron } from 'src/assets/right-arrow.svg';
import NewTaskModal from 'src/components/Modals/TaskModal';
import NewProjectModal from 'src/components/Modals/NewProjectModal';
import HelpModal from 'src/components/Modals/HelpModal';
import UpdateProjectNameModal from 'src/components/Modals/UpdateProjectNameModal';
import styles from './DashboardPage.module.scss';
import { getUserId } from 'src/redux/selectors/auth';
import { useSelector } from 'react-redux';
import { useApi } from 'src/api';
import { getTasks } from 'src/redux/selectors/task';
import { getProjects } from 'src/redux/selectors/project';
import hrefs from 'src/modules/href';
import CreateDropdown from './components/CreateDropdown';
import ProjectItem from './components/ProjectItem';
import FileItem from './components/FileItem';
import { getFavourites } from 'src/redux/selectors/favourites';
import { colours } from 'src/modules/colours';
import Spinner from 'src/components/Spinner';
import { ElfsightWidget } from 'react-elfsight-widget';

import {
  getSharedFolders,
  getSharedProjects,
} from 'src/redux/selectors/shared';

export default function DashboardPage() {
  const sharedFolders = useSelector(getSharedFolders);
  const userId = useSelector(getUserId);
  const projects = useSelector(getProjects);
  const tasks = useSelector(getTasks);
  const favourites = useSelector(getFavourites);
  const sharedProjects = useSelector(getSharedProjects);
  const api = useApi();
  useEffect(() => {
    if (userId) {
      api.getProjects(userId);
      api.getTasksByUserId(userId);
      api.getUser(userId);
      api.getSharedFolders(userId);
      api.getUsers();
    }
  }, [api, userId]);
  useEffect(() => {
    if (userId && sharedFolders) {
      sharedFolders.forEach(f => api.getSharedProject(f.folderId));
    }
  }, [api, userId, sharedFolders]);
  const [newProjectModal, setNewProjectModal] = useState(false);
  const [currentFolder, setCurrentFolder] = useState('');
  const [newTaskModal, setNewTaskModal] = useState(false);
  const [showHelpModal, setsShowHelpModal] = useState(false);
  const [folderMax, setFolderMax] = useState(6);
  const [showUpdateProjectNameModal, setsShowUpdateProjectNameModal] =
    useState(false);
  const [projectIdForUpdateName, setProjectIdForUpdateName] =
    useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  if (!projects || !userId || !tasks || !sharedProjects) {
    return (
      <DashboardWrapper
        tab={tabs.home}
        helmetUrl={hrefs.dashboard()}
        isOverlayActive={
          newProjectModal ||
          newTaskModal ||
          showHelpModal ||
          showUpdateProjectNameModal
        }
        modalInJsx={
          newProjectModal ? (
            <NewProjectModal setNewProjectModal={setNewProjectModal} />
          ) : newTaskModal ? (
            <NewTaskModal setNewTask={setNewTaskModal} />
          ) : showHelpModal ? (
            <HelpModal setShowModal={setsShowHelpModal} />
          ) : (
            <UpdateProjectNameModal
              setModal={setsShowUpdateProjectNameModal}
              projectId={projectIdForUpdateName}
              nameToUpdate={projectName}
            />
          )
        }
      >
        <div>
          <div className={styles.Header}>
            <div className={styles.Title}>
              <span>Home</span>
            </div>
          </div>
          <div className={styles.SectionHeader}>
            <Spinner size={16} color={colours.black} />
          </div>
        </div>
      </DashboardWrapper>
    );
  }
  const favProjectIds = favourites?.map(f => f.projectId);
  const favTaskIds = favourites?.map(f => f.taskId);
  const favouriteTasks = tasks.filter(
    t => favProjectIds?.includes(t.projectId) && favTaskIds?.includes(t.taskId),
  );
  const combinedProjects = [
    ...projects,
    ...sharedProjects.filter(
      sp => !projects.find(p => p.projectId === sp.projectId),
    ),
  ];
  return (
    <DashboardWrapper
      tab={tabs.home}
      helmetUrl={hrefs.dashboard()}
      isOverlayActive={
        newProjectModal ||
        newTaskModal ||
        showHelpModal ||
        showUpdateProjectNameModal
      }
      modalInJsx={
        newProjectModal ? (
          <NewProjectModal setNewProjectModal={setNewProjectModal} />
        ) : newTaskModal ? (
          <NewTaskModal
            setNewTask={setNewTaskModal}
            currentProject={projects.find(
              project => project.projectId === currentFolder,
            )}
          />
        ) : showHelpModal ? (
          <HelpModal setShowModal={setsShowHelpModal} />
        ) : (
          <UpdateProjectNameModal
            setModal={setsShowUpdateProjectNameModal}
            projectId={projectIdForUpdateName}
            nameToUpdate={projectName}
          />
        )
      }
    >
      <div>
        <div className={styles.Header}>
          <div className={styles.Title}>
          <ElfsightWidget widgetID="f67f13a6-e20f-4cda-b581-fd53e5e9b36d" />
            <span>Home</span>
          </div>
          <CreateDropdown
            setNewProjectModal={setNewProjectModal}
            setNewTaskModal={setNewTaskModal}
          />
        </div>
        <div className={styles.FolderSection}>
          <div className={styles.SectionHeader}>
            <div className={styles.LeftSide}>
              <div className={styles.IconContainer}>
                <Folder className={styles.FolderIcon} />
              </div>
              <div
                className={styles.AllFolders}
                onClick={() => setCurrentFolder('')}
              >
                <span>All Folders</span>
              </div>
              {currentFolder.length > 0 && (
                <div className={styles.BreadcrumbContainer}>
                  <Chevron className={styles.BreadcrumbChevron} />
                  <div className={styles.Path}>
                    {projects.find(p => p.projectId === currentFolder)?.name}
                  </div>
                </div>
              )}
            </div>
            {projects.length > 6 && (
              <div
                className={styles.ViewFolder}
                onClick={() => {
                  if (folderMax === 6) {
                    setFolderMax(projects.length);
                  } else {
                    setFolderMax(6);
                  }
                }}
              >
                {folderMax === 6
                  ? `View All (${projects.length})`
                  : 'View Less'}
              </div>
            )}
          </div>
          {!currentFolder && (
            <div className={styles.Folders}>
              {combinedProjects.slice(0, folderMax).map((project, i) => (
                <ProjectItem
                  key={i}
                  project={project}
                  fileCount={
                    tasks?.filter(t => t.projectId === project.projectId)
                      .length as number
                  }
                  setCurrentFolder={setCurrentFolder}
                  setsShowUpdateProjectNameModal={
                    setsShowUpdateProjectNameModal
                  }
                  setProjectName={setProjectName}
                  setProjectId={setProjectIdForUpdateName}
                  members={
                    sharedFolders
                      ?.filter(f => f.members.length > 1)
                      .find(f => f.folderId === project.projectId)?.members
                  }
                  isAdmin={
                    !!sharedFolders?.find(f => f.admin === userId) ||
                    !sharedFolders?.find(f => f.folderId === project.projectId)
                  }
                />
              ))}
            </div>
          )}
        </div>
        <div className={styles.DocumentSection}>
          <div className={styles.SectionHeader}>
            <div className={styles.LeftSide}>
              <div className={styles.IconContainer}>
                <span className={styles.Icon}>F</span>
              </div>
              <div className={styles.FileTitle}>
                <span>{!currentFolder ? 'Favourite Files' : 'Files'}</span>
              </div>
            </div>
          </div>
          <div className={styles.Files}>
            {currentFolder ? (
              tasks?.filter(t => t.projectId === currentFolder).length > 0 ? (
                tasks
                  ?.filter(t => t.projectId === currentFolder)

                  .map((t, i) => (
                    <FileItem
                      key={i}
                      file={t}
                      favourited={favouriteTasks.includes(t)}
                    />
                  ))
              ) : (
                <span className={styles.NoFiles}>
                  No files available in this folder...
                </span>
              )
            ) : favouriteTasks.length > 0 ? (
              favouriteTasks.map((t, i) => (
                <FileItem key={i} file={t} favourited={true} />
              ))
            ) : (
              <span className={styles.NoFiles}>
                You currently haven't favourited any files...
              </span>
            )}
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
