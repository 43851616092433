import React from 'react';
import Logo from '../Logo';
import styles from './AuthenticationWrapper.module.scss';

type Wrapper = {
  children: JSX.Element;
};

export default function AuthenticationWrapper({
  children,
}: Wrapper): JSX.Element {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Navbar}>
        <Logo />
      </div>
      <div className={styles.RightHalf}>{children}</div>
    </div>
  );
}
