import React from 'react';
import styles from './Spinner.module.scss';

type Props = {
  color: string;
  size: number;
};

export default function Spinner({ color, size }: Props) {
  const style = { height: size, width: size, backgroundColor: color };
  return (
    <div className={styles.spinner}>
      <div className={styles.bounce1} style={style}></div>
      <div className={styles.bounce2} style={style}></div>
      <div className={styles.bounce3} style={style}></div>
    </div>
  );
}
