import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router';
import hrefs from 'src/modules/href';
import { Generation } from 'src/modules/statistics';
import { subscriptionTypes, SubscriptionTypes } from 'src/modules/user';
import { upgradeCta } from 'src/utils/upgradeCta';
import styles from './StatsCounter.module.scss';

type Props = {
  statistics: Generation;
  isExpired: boolean;
  subscriptionType: SubscriptionTypes;
};

function kFormatter(num: number) {
  return Math.abs(num) > 999
    ? (Math.abs(num) / 1000).toFixed(1) + 'k'
    : Math.abs(num);
}

export default function StatsCounter({
  statistics,
  isExpired,
  subscriptionType,
}: Props) {
  const history = useHistory();
  const { runsLeft, writeForMeRunsLeft } = statistics;
  function statsMessage(subscriptionType: SubscriptionTypes, stat: number) {
    return isExpired
      ? 'Expired'
      : subscriptionType === subscriptionTypes.pro ||
        subscriptionType === subscriptionTypes.appsumo ||
        subscriptionType === subscriptionTypes.appsumoUnlimitedAnnual ||
        subscriptionType === subscriptionTypes.appsumoUnlimitedMonthly ||
        subscriptionType === subscriptionTypes.appsumoSelectPro ||
        subscriptionType === subscriptionTypes.appsumoSelectStarter
      ? '∞'
      : stat;
  }
  function statsMessageLong(subscriptionType: SubscriptionTypes, stat: number) {
    return isExpired
      ? 'Expired'
      : subscriptionType === subscriptionTypes.pro ||
        subscriptionType === subscriptionTypes.appsumoUnlimitedAnnual ||
        subscriptionType === subscriptionTypes.appsumoUnlimitedMonthly
      ? '∞'
      : kFormatter(stat);
  }
  return (
    <div
      className={classNames(styles.StatsCounter, isExpired && styles.Expired)}
      onClick={() => history.push(hrefs.settings())}
    >
      <Tippy
        theme='light'
        content={
          isExpired
            ? 'Your subscription has expired. Click to resubscribe.'
            : upgradeCta(subscriptionType, false)
        }
      >
        <div
          className={classNames(
            styles.Number,
            isExpired && styles.NumberExpired,
          )}
        >
          {statsMessage(subscriptionType, runsLeft)}
        </div>
      </Tippy>
      <Tippy
        theme='light'
        content={
          isExpired
            ? 'Your subscription has expired. Click to resubscribe.'
            : upgradeCta(subscriptionType, true, writeForMeRunsLeft)
        }
      >
        <div
          className={classNames(
            styles.Number,
            isExpired && styles.NumberExpired,
          )}
        >
          {statsMessageLong(subscriptionType, writeForMeRunsLeft)}
        </div>
      </Tippy>
    </div>
  );
}
