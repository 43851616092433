import { EditorSettings } from '../../modules/editorSetttings/index';
import { createActionsBuilder, ExtractActions } from './utils/request';

type UpdateEditorSettingsMeta = EditorSettings;
type UpdateEditorSettingsResponse = EditorSettings;

export const updateEditorSettings = createActionsBuilder<
  UpdateEditorSettingsMeta,
  UpdateEditorSettingsResponse
>()(
  'UPDATE_EDITOR_SETTINGS_PENDING',
  'UPDATE_EDITOR_SETTINGS_SUCCESS',
  'UPDATE_EDITOR_SETTINGS_FAILURE',
);
export type UpdateEditorSettingsActions = ExtractActions<
  typeof updateEditorSettings
>;

type GetEditorSettingsMeta = { userId: string };
type GetEditorSettingsResponse = EditorSettings;

export const getEditorSettings = createActionsBuilder<
  GetEditorSettingsMeta,
  GetEditorSettingsResponse
>()(
  'GET_EDITOR_SETTINGS_PENDING',
  'GET_EDITOR_SETTINGS_SUCCESS',
  'GET_EDITOR_SETTINGS_FAILURE',
);
export type GetEditorSettingsActions = ExtractActions<typeof getEditorSettings>;

export type Actions = UpdateEditorSettingsActions | GetEditorSettingsActions;
