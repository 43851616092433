/**
 * Function which takes data and creates a markdown file
 * @param content of the file
 * @param filename
 *
 */
export function fileDownloadHandler(
  content: BlobPart,
  fileName: string,
  // Mime Type
  type: string,
): string {
  const file = new File([content], fileName, {
    type,
    endings: 'transparent',
  });
  const url = URL.createObjectURL(file);
  return url;
}
