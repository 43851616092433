import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from 'src/assets/general-close.svg';
import styles from './Tour.module.scss';
import Pulse from '../Pulse';
import { useWindowDimensions } from 'src/utils/windowPosition';
import TestimonialCard from 'src/components/TestimonialCard';
import AimeeImg from 'src/assets/aimeebradshaw.jpeg';
import VitImg from 'src/assets/vit.jpeg';
import AnthonyImg from 'src/assets/anthony.jpeg';
import { ReactComponent as Chevron } from 'src/assets/right-arrow.svg';
import { useApi } from 'src/api';
import { getUser } from 'src/redux/selectors/user';
import { useSelector } from 'react-redux';

type ProgressProps = {
  tourPosition: number;
};

function ProgressBar({ tourPosition }: ProgressProps) {
  return (
    <div className={styles.ProgressBar}>
      <div
        className={classNames(
          styles.Stage,
          tourPosition >= 1 && styles.ActiveStage,
        )}
      ></div>
      <div
        className={classNames(
          styles.Stage,
          tourPosition >= 2 && styles.ActiveStage,
        )}
      ></div>
      <div
        className={classNames(
          styles.Stage,
          tourPosition >= 3 && styles.ActiveStage,
        )}
      ></div>
      <div
        className={classNames(
          styles.Stage,
          tourPosition >= 4 && styles.ActiveStage,
        )}
      ></div>
      <div
        className={classNames(
          styles.Stage,
          tourPosition >= 5 && styles.ActiveStage,
        )}
      ></div>
      <div
        className={classNames(
          styles.Stage,
          tourPosition >= 6 && styles.ActiveStage,
        )}
      ></div>
      <div
        className={classNames(
          styles.Stage,
          tourPosition === 7 && styles.ActiveStage,
        )}
      ></div>
    </div>
  );
}

type ItemProps = {
  showText: boolean;
  setShowText: (a: boolean) => void;
  setTourPosition?: React.Dispatch<React.SetStateAction<number>>;
  pulsePosition: { right?: number; left?: number; y: number };
  messagePosition: { right?: number; left?: number; y: number };
  title: string;
  description: JSX.Element;
  messageHeight?: number;
  tourPosition: number;
  setGiveTour: React.Dispatch<React.SetStateAction<boolean>>;
};

function TourItem({
  showText,
  setShowText,
  setTourPosition,
  setGiveTour,
  pulsePosition,
  messagePosition,
  title,
  description,
  messageHeight,
  tourPosition,
}: ItemProps): JSX.Element {
  const api = useApi();
  const user = useSelector(getUser);
  return (
    <div>
      <Pulse
        left={pulsePosition.left}
        right={pulsePosition.right}
        y={pulsePosition.y}
        onClick={() => setShowText(true)}
      />
      {showText && (
        <div>
          <div
            className={styles.Message}
            style={{
              top: messagePosition.y,
              right: messagePosition.right,
              left: messagePosition.left,
              height: messageHeight,
            }}
          >
            <div className={styles.CloseContainer}>
              <div
                className={styles.Cancel}
                onClick={() => {
                  if (user) {
                    api
                      .updateUser({ ...user, showTutorial: false })
                      .then(() => {
                        setShowText(false);
                        setGiveTour(false);
                      });
                  }
                }}
              >
                Cancel Tour
              </div>
              <div className={styles.Right}>
                <ProgressBar tourPosition={tourPosition} />
                <CloseIcon
                  onClick={() => {
                    setShowText(false);
                    setTourPosition && setTourPosition(pos => pos + 1);
                  }}
                  className={styles.CloseIcon}
                />
              </div>
            </div>
            <div className={styles.Title}>
              <span>{title}</span>
            </div>
            {description}
          </div>
        </div>
      )}
    </div>
  );
}

type Props = {
  currentPosition: number;
  setTourPosition: React.Dispatch<React.SetStateAction<number>>;
  setGiveTour: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function PlaygroundTour({
  currentPosition,
  setTourPosition,
  setGiveTour,
}: Props): JSX.Element {
  const { width, height } = useWindowDimensions();
  const [showText, setShowText] = useState(false);
  const [carouselIdx, setCarouselIdx] = useState(0);
  const api = useApi();
  const user = useSelector(getUser);
  const carouselItems = [
    {
      title: 'Write blog posts fast',
      testimonialCard: (
        <TestimonialCard
          className={styles.Card}
          name='Aimee Bradshaw'
          job='Copywriter at startups.co.uk'
          avatarImg={AimeeImg}
          testimonial="I use CopyMuse's blogging tools daily. The combination of the blog idea, blog intro, and content expander tools really feels like a mini superpower."
        />
      ),
    },
    {
      title: 'Improve the conversation rate of your website',
      testimonialCard: (
        <TestimonialCard
          className={styles.Card}
          name='Vit Jiracek'
          job='Founder of VS Digital Media'
          testimonial='I use the website tools whenever we are running experiments to increase landing page conversions. The rate of good ideas is very high which has made experimentation with our website copy really simple.'
          avatarImg={VitImg}
        />
      ),
    },
    {
      title: 'Increase the click-through rate of your ads',
      testimonialCard: (
        <TestimonialCard
          className={styles.Card}
          name='Anthony Young'
          job='Co-founder of Tyde'
          avatarImg={AnthonyImg}
          testimonial='Paid acquisition is one of our main growth strategies. The facebook and google ad generators are amazing. Not only have they helped us generate more ads faster, but our click-through rates have gone up!'
        />
      ),
    },
  ];
  const defaultProps = {
    showText: showText,
    setShowText: setShowText,
    tourPosition: currentPosition,
    setGiveTour: setGiveTour,
  };
  switch (currentPosition) {
    case 1:
      return (
        <TourItem
          {...defaultProps}
          pulsePosition={{ right: 200, y: 200 }}
          messagePosition={{ y: 190, right: 200 }}
          title='Use Cases'
          description={
            <p className={styles.Info}>
              From creating ads to summaries, we cover a variety of use-cases
              and continue to add the most requested ones. Now, please click on
              the "Select Use Case" button and select the use case "Listicle".
            </p>
          }
        />
      );
    case 2:
      return (
        <TourItem
          {...defaultProps}
          pulsePosition={{ right: 300, y: 80 }}
          messagePosition={{ y: 80, right: 300 }}
          title='Form Data'
          description={
            <p className={styles.Info}>
              Nice job! The form you see here is a how all of our use case forms
              are set up. It is important that you fill in each input field. To
              get the best results, be as precise as possible. Now, please fill
              in the data and press the "Generate" button.
            </p>
          }
        />
      );
    case 3:
      return (
        <TourItem
          {...defaultProps}
          messageHeight={240}
          pulsePosition={{ right: 230, y: 400 }}
          messagePosition={{ y: 400, right: 230 }}
          title='Generated Ideas'
          description={
            <p className={styles.Info}>
              What you currently see is a list of generated ideas. Every run of
              our AI will generate up to 10 ideas. Each idea has 5 commands.
              Hovering over them will indicate what they do. Now, please select
              an idea that you like, copy it to your clipboard, and paste into
              the editor.
            </p>
          }
        />
      );
    case 4:
      return (
        <TourItem
          {...defaultProps}
          messageHeight={220}
          pulsePosition={{ left: width / 2 - 300, y: 200 }}
          messagePosition={{ left: width / 2 - 300, y: 200 }}
          title='Editor'
          description={
            <p className={styles.Info}>
              Great! You currently find yourself in the editor. But it's no
              ordinary editor! Highlight one listicle item using your keyboard
              or mouse. An inline toolbar will pop up. Each buttton on the
              toolbar is hooked up to our AI! Hover over them to learn what they
              do. Now, click the 'Expand' button and see what happens.
            </p>
          }
        />
      );
    case 5:
      return (
        <TourItem
          {...defaultProps}
          setTourPosition={setTourPosition}
          pulsePosition={{ right: 450, y: 600 }}
          messagePosition={{ right: 450, y: 600 }}
          title='Toolbox'
          messageHeight={260}
          description={
            <>
              <p className={styles.Info}>
                Nice one! Clicking one of the ideas will replace the highlighted
                text with it.
              </p>
              <p className={styles.Info}>
                Due to security reasons, you will only be able to run these
                toolbox commands if the highlighted text is less than 400
                characters long. The number can be found on the rar right of the
                toolbar.
              </p>
            </>
          }
        />
      );
    case 6:
      return (
        <TourItem
          {...defaultProps}
          pulsePosition={{ left: 100, y: 50 }}
          messagePosition={{ left: 100, y: 50 }}
          title='Document Title'
          description={
            <p className={styles.Info}>
              Almost there! This is the title of your document. Clicking on it
              will allow you to edit it. Please edit it now.
            </p>
          }
        />
      );
    case 7:
      return (
        <div>
          <Pulse
            left={width / 2}
            y={height / 2 - 200}
            onClick={() => setShowText(true)}
          />
          {showText && (
            <div>
              <div
                className={styles.LastMessage}
                style={{
                  top: height / 2 - 200,
                  left: width / 2 - 400,
                }}
              >
                <div className={styles.ContentContainer}>
                  <div className={styles.Left}>
                    <div className={styles.Title}>
                      <span>Congratulations!</span>
                    </div>
                    <p className={styles.Info}>
                      You have finished the tour. Give yourself a pat on the
                      back! We have introduced you to a few examples of how
                      CopyMuse is used, but there are so many more to explore! We
                      are excited to see how use CopyMuse.
                    </p>
                    <p className={styles.Info}>
                      As a parting gift, here are some examples of how our power
                      users take full advantage of our state-of-the-art AI.
                    </p>
                    <p className={styles.Info}>
                      Thanks for being part of our journey and welcome to the
                      CopyMuse family.
                    </p>
                  </div>
                  <div className={styles.CarouselContainer}>
                    <div>
                      <div className={styles.UsecaseCloseContainer}>
                        <CloseIcon
                          onClick={() => {
                            if (user) {
                              api
                                .updateUser({ ...user, showTutorial: false })
                                .then(() => {
                                  setShowText(false);
                                  setGiveTour(false);
                                });
                            }
                          }}
                          className={styles.UsecaseCloseIcon}
                        />
                      </div>
                      <div className={styles.ItemContainer}>
                        <div className={styles.Cases}>
                          <span>Case Studies</span>
                        </div>
                        <div className={styles.UsecaseTitle}>
                          {carouselItems[carouselIdx].title}
                        </div>
                        <div className={styles.TestimonialContainer}>
                          {carouselItems[carouselIdx].testimonialCard}
                        </div>
                      </div>
                    </div>
                    <div className={styles.Dots}>
                      <Chevron
                        className={styles.Chevron}
                        onClick={() => {
                          setCarouselIdx(idx => (idx === 0 ? 2 : idx - 1));
                        }}
                      />
                      <div
                        className={classNames(
                          styles.Dot,
                          carouselIdx === 0 && styles.ActiveDot,
                        )}
                        onClick={() => setCarouselIdx(0)}
                      ></div>
                      <div
                        className={classNames(
                          styles.Dot,
                          carouselIdx === 1 && styles.ActiveDot,
                        )}
                        onClick={() => setCarouselIdx(1)}
                      ></div>
                      <div
                        className={classNames(
                          styles.Dot,
                          carouselIdx === 2 && styles.ActiveDot,
                        )}
                        onClick={() => setCarouselIdx(2)}
                      ></div>
                      <Chevron
                        className={styles.ChevronRight}
                        onClick={() => {
                          setCarouselIdx(idx => (idx === 2 ? 0 : idx + 1));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    default:
      return <div></div>;
  }
}
