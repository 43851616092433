import Tippy from '@tippyjs/react';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useApi } from 'src/api';
import { ReactComponent as Appsumo } from 'src/assets/AppSumo-Partner-vector.svg';
import AuthenticationWrapper from 'src/components/AuthenticationWrapper';
import FormInput from 'src/components/FormInput';
import Spinner from 'src/components/Spinner';
import href from 'src/modules/href';
import { validateEmail, validatePassword } from 'src/utils/validation';
import styles from './Appsumo.module.scss';

export default function AppsumoPage(): JSX.Element {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [apiError, setApiError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const api = useApi();

  const emailError = validateEmail(email);
  const passwordError = validatePassword(password);
  return (
    <AuthenticationWrapper>
      <>
        <Helmet>
          <meta charSet='utf-8' />
          <title>CopyMuse - AppSumo Partnership</title>
          <link rel='canonical' href={`https://copymuse.ai${href.appsumo()}`} />
        </Helmet>

        <div className={styles.Partnership}>
          <span className={styles.GoCopy}>CopyMuse x</span>
          <Appsumo className={styles.Appsumo} />
        </div>
        <div className={styles.SignUpPage}>
          <div className={styles.Title}>
            <span>Let's Go!</span>
          </div>
          <div className={styles.Subtitle}>
          {/* <span>Writing simpified for free. No credit card required.</span> */}
          <span>Writing simpified for free. </span>
          </div>
          {apiError && <div className={styles.ApiError}>{apiError}</div>}
          <div className={styles.FormInputContainer}>
            <FormInput
              title='E-mail'
              placeholderText='Enter your e-mail address'
              password={false}
              input={email}
              onChange={e => setEmail(e.target.value)}
              className={styles.FormInput}
              error={showErrors ? emailError : ''}
            />
          </div>
          <FormInput
            title='Password'
            placeholderText='Enter your password'
            password
            input={password}
            onChange={e => setPassword(e.target.value)}
            className={styles.FormInput}
            passwordMessage='Minimum of 8 characters'
            error={showErrors ? passwordError : ''}
          />
          <div className={styles.AppsumoContainer}>
            <FormInput
              title='AppSumo Code'
              placeholderText='Enter your code'
              password
              input={code}
              onChange={e => setCode(e.target.value)}
              className={styles.FormInput}
            />
          </div>
          <Tippy content='Woohoo!'>
            <button
              className={styles.CreateAccountButton}
              onClick={() => {
                setShowErrors(true);
                if (!emailError && !passwordError && code.length > 0) {
                  setIsLoading(true);
                  api
                    .signUpAppsumo({ email, password, code })
                    .then(res => {
                      history.push(
                        href.playground({
                          projectId: res.taskData.projectId,
                          taskId: res.taskData.taskId,
                        }),
                      );
                      setIsLoading(false);
                    })
                    .catch((e: AxiosError) => {
                      setIsLoading(false);
                      setApiError(e.response?.data.errorMessage);
                    });
                }
              }}
            >
              {isLoading ? <Spinner size={12} color='#ffffff' /> : 'Get CopyMuse'}
            </button>
          </Tippy>
          <div className={styles.TandC}>
            <span>By clicking “Create Account” you agree to CopyMuse’s </span>
            <span
              className={styles.TermsText}
              onClick={() => history.push(href.termsOfService())}
            >
              Terms of Service
            </span>
            <span> and </span>
            <span
              className={styles.TermsText}
              onClick={() => history.push(href.privacyPolicy())}
            >
              Privacy Policy
            </span>
          </div>
        </div>
        <div className={styles.LogIn}>
          <span>Already have an account?</span>
          <span
            className={styles.LogInText}
            onClick={() => history.push(href.signIn())}
          >
            Log in
          </span>
        </div>
      </>
    </AuthenticationWrapper>
  );
}
