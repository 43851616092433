import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TextArea from 'src/components/TextArea';
import { Task } from 'src/modules/task';
import { ContentRewriterProps } from 'src/modules/task/promptProps';
import { taskTypes } from 'src/modules/task/tasktypes';
import { getTask } from 'src/redux/selectors/task';
import InputContainer from '../InputContainer';
import TaskWrapper from '../TaskWrapper';
import styles from './ContentRewriter.module.scss';

type Props = {
  isLoading: boolean;
  setIsLoading: (a: boolean) => void;
  setShowTasksModal?: (a: boolean) => void;
  projectId: string;
  taskId: string;
  width: number;
};

export default function ContentRewriterForm({
  isLoading,
  setIsLoading,
  setShowTasksModal,
  projectId,
  taskId,
  width,
}: Props) {
  const task = useSelector(getTask(projectId)(taskId));
  const [oldContent, setOldContent] = useState('');
  useEffect(() => {
    if (task) {
      if (task.mostRecentTask === taskTypes.contentRewriter) {
        const { old } = task.currentInput as ContentRewriterProps;
        setOldContent(old);
      }
    }
  }, [task]);
  const currentInputs = {
    old: oldContent,
  };
  const taskPrompt = { type: taskTypes.contentRewriter, ...currentInputs };
  return (
    <div className={styles.Caption}>
      <TaskWrapper
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        checkString={oldContent}
        setShowTasksModal={setShowTasksModal}
        width={width}
        task={task as Task}
        taskPrompt={taskPrompt}
        currentInputs={currentInputs}
      >
        <InputContainer width={width}>
          <TextArea
            text={oldContent}
            setText={setOldContent}
            placeholderText='Add content to be rewritten'
            title='Content'
            className={styles.TextArea}
            textClassName={styles.Area}
            maxLength={400}
          />
        </InputContainer>
      </TaskWrapper>
    </div>
  );
}
