import { Project } from 'src/modules/Project';
import { SharedFolder } from 'src/modules/shared';
import { createActionsBuilder, ExtractActions } from './utils/request';

type GetSharedFolderMeta = { userId: string };
type GetSharedFolderResponse = SharedFolder[];

export const getSharedFolders = createActionsBuilder<
  GetSharedFolderMeta,
  GetSharedFolderResponse
>()(
  'GET_SHARED_FOLDERS_PENDING',
  'GET_SHARED_FOLDERS_SUCCESS',
  'GET_SHARED_FOLDERS_FAILURE',
);
export type GetSharedFolderActions = ExtractActions<typeof getSharedFolders>;

type GetSharedFolderAdminMeta = { userId: string };
type GetSharedFolderAdminResponse = SharedFolder[];

export const getSharedFoldersAdmin = createActionsBuilder<
  GetSharedFolderAdminMeta,
  GetSharedFolderAdminResponse
>()(
  'GET_SHARED_FOLDERS_ADMIN_PENDING',
  'GET_SHARED_FOLDERS_ADMIN_SUCCESS',
  'GET_SHARED_FOLDERS_ADMIN_FAILURE',
);
export type GetSharedFolderAdminActions = ExtractActions<
  typeof getSharedFoldersAdmin
>;

type GetSharedProjectMeta = { projectId: string };
type GetSharedProjectResponse = Project;

export const getSharedProject = createActionsBuilder<
  GetSharedProjectMeta,
  GetSharedProjectResponse
>()(
  'GET_SHARED_PROJECT_PENDING',
  'GET_SHARED_PROJECT_SUCCESS',
  'GET_SHARED_PROJECT_FAILURE',
);
export type GetSharedProjectActions = ExtractActions<typeof getSharedProject>;

type CreateSharedFolderMeta = {
  adminId: string;
  folderId: string;
  newMemberId: string;
};
type CreateSharedFolderResponse = SharedFolder;

export const createSharedFolders = createActionsBuilder<
  CreateSharedFolderMeta,
  CreateSharedFolderResponse
>()(
  'CREATE_SHARED_FOLDER_PENDING',
  'CREATE_SHARED_FOLDER_SUCCESS',
  'CREATE_SHARED_FOLDER_FAILURE',
);
export type CreateSharedFolderActions = ExtractActions<
  typeof createSharedFolders
>;

type AddMemberMeta = {
  adminId: string;
  folderId: string;
  newMemberId: string;
};
type AddMemberResponse = string;

export const addMember = createActionsBuilder<
  AddMemberMeta,
  AddMemberResponse
>()('ADD_MEMBER_PENDING', 'ADD_MEMBER_SUCCESS', 'ADD_MEMBER_FAILURE');
export type AddMemberActions = ExtractActions<typeof addMember>;

type RemoveMemberMeta = {
  adminId: string;
  folderId: string;
  memberId: string;
};
type RemoveMemberResponse = string;

export const removeMember = createActionsBuilder<
  RemoveMemberMeta,
  RemoveMemberResponse
>()('REMOVE_MEMBER_PENDING', 'REMOVE_MEMBER_SUCCESS', 'REMOVE_MEMBER_FAILURE');

export type RemoveMemberActions = ExtractActions<typeof removeMember>;

export type Actions =
  | GetSharedFolderActions
  | CreateSharedFolderActions
  | AddMemberActions
  | RemoveMemberActions
  | GetSharedFolderAdminActions
  | GetSharedProjectActions;
