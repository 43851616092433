import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './Success.module.scss';
import GoCopyIcon from 'src/assets/android-chrome-512x512.png';
import href from 'src/modules/href';
import { useApi } from 'src/api';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/redux/selectors/auth';

export default function SuccessPage() {
  const history = useHistory();
  const api = useApi();
  const userId = useSelector(getUserId);
  const [timeLeft, setTimeLeft] = useState(5);
//this is where to change tracking things

  const thisIsMyCopy = `<!-- Meta Pixel Code -->
  <script>
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '356670329269065');
  fbq('track', 'Subscribe');
  </script>
  <noscript><img height="1" width="1" style="display:none"
  src="https://www.facebook.com/tr?id=356670329269065&ev=PageView&noscript=1"
  /></noscript>
  <!-- End Meta Pixel Code -->
  
  
  <!-- Event snippet for Subscribe conversion page -->
  <script>
    gtag('event', 'conversion', {'send_to': 'AW-10847887569/c4xACOaMrbIDENHB1rQo'});
  </script>`;

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  useEffect(() => {
    if (userId) {
      // run this to update redux store with new user subscription data
      api.getUser(userId);
    }
  }, [api, userId]);

  if (timeLeft === 0) {
    history.push(href.dashboard());
  }
  return (
    <div className={styles.Success}>
      <img alt='gocopy icon' src={GoCopyIcon} className={styles.Image} />
      <div>Payment received!</div>
      <div className={styles.Auto}>
        Automatically redirecting in {timeLeft} seconds...
      </div>
      <div
        onClick={() => history.push(href.dashboard())}
        className={styles.Back}
      >
        Back to dashboard
      </div>
      <div className="content" dangerouslySetInnerHTML={{__html: thisIsMyCopy}}></div>

    </div>
  );
}
