import { OutputData } from '@editorjs/editorjs';
import transforms, { block } from './transforms';
import { ParseFunctionError } from './errors';

type Parser = {
  parse(OutputData: OutputData): any;
  parseBlock(block: block): any;
};

const parser = (plugins = {}): Parser => {
  Object.assign(transforms, plugins);

  return {
    parse: ({ blocks }) => {
      const wrapperBlocks = [
        '<!DOCTYPE html>',
        '<html lang="en">',
        '<head>',
        '<meta charset="UTF-8">',
        '</head>',
        '<body>',
        '</body>',
        '</html>',
      ];
      const parsedBlocks = blocks.map(block => {
        return transforms[block.type]
          ? transforms[block.type](block)
          : ParseFunctionError(block.type);
      });
      wrapperBlocks.splice(7, 0, ...parsedBlocks);
      return wrapperBlocks;
    },

    parseBlock: block => {
      return transforms[block.type]
        ? transforms[block.type](block)
        : ParseFunctionError(block.type);
    },
  };
};

export default parser;
