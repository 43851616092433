import { RootState } from 'src/redux/reducers';

/**
 * Only consume the parts of the store that we need.
 */
type State = Pick<RootState, 'activationCode'>;

/**
 * This selector returns the activation code.
 */
export const getActivationCode = (state: State): number | undefined =>
  state.activationCode.code;

/**
 * This selector returns the expiry date.
 */
export const getActivationCodeExpiryDate = (state: State): string | undefined =>
  state.activationCode.expiryDate;

export const getActivationUsed = (state: State): boolean | undefined =>
  state.activationCode.expired;
