import classNames from 'classnames';
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { ReactComponent as Chevron } from 'src/assets/right-arrow.svg';
import { maybePluralize } from 'src/utils/pluralize';
import styles from './Count.module.scss';

type Props = {
  content: string;
};

export default function Countdropdown({ content }: Props): JSX.Element {
  const tokenCount = content.length === 0 ? 0 : content.split(' ').length;
  const charCount = content.length;
  const [openDropdown, setOpendropdown] = useState(false);
  return (
    <OutsideClickHandler onOutsideClick={() => setOpendropdown(false)}>
      <div
        className={styles.Count}
        onClick={() => setOpendropdown(!openDropdown)}
      >
        <div className={styles.CountContainer}>
          {maybePluralize(tokenCount, 'word')}
          <Chevron
            className={classNames(
              styles.Chevron,
              openDropdown && styles.OpenChevron,
            )}
          />
        </div>
      </div>
      {openDropdown && (
        <div className={styles.Countdropdown}>
          <div className={styles.Item}>
            <span className={styles.Text}>{tokenCount} words</span>
          </div>
          <div className={styles.Item}>
            <span className={styles.Text}>{charCount} characters</span>
          </div>
        </div>
      )}
    </OutsideClickHandler>
  );
}
