type JWTPayload = {
  userId: string;
  iat: number;
  exp: number;
};
export function parseJwt(token: string): JWTPayload {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (error) {
    throw error;
  }
}

export function isTokenExpired(token: string): boolean {
  const parsedToken = parseJwt(token);
  const currentTime = new Date().getTime() / 1000;
  return currentTime > parsedToken.exp;
}
