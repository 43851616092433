import { Project } from 'src/modules/Project';
import { RootState } from 'src/redux/reducers';

/**
 * Only consume the parts of the store that we need.
 */
type State = Pick<RootState, 'project'>;

/**
 * This selector retrieves the whole project object from redux store
 */
export const getProject = (projectId: string) => (
  state: State,
): Project | undefined => state.project.byProjectid[projectId];
/**
 * This selector retrieves all projects from redux store
 */
export const getProjects = (state: State): Project[] | undefined =>
  state.project.projects && state.project.projects;
