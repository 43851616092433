import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './Input.module.scss';
import { ReactComponent as CloseIcon } from 'src/assets/close.svg';

type Props = {
  title: string;
  currentItems: string[];
  setCurrentItems: (a: string[]) => void;
  error?: string;
  width: number;
};

export default function Input({
  title,
  currentItems,
  setCurrentItems,
  width,
  error,
}: Props): JSX.Element {
  const [newItem, setNewItem] = useState('');
  return (
    <div className={styles.Input} style={{ width }}>
      <div className={styles.Title}>{title}</div>
      {error && (
        <div className={styles.Error} style={{ width }}>
          {error}
        </div>
      )}
      <div
        className={classNames(
          error && styles.ErrorContainer,
          styles.InputContainer,
        )}
      >
        {currentItems.map((item, i) => (
          <div className={styles.Item} key={i}>
            {item}
            <CloseIcon
              className={styles.Close}
              onClick={() =>
                setCurrentItems(
                  currentItems.filter(currItem => currItem !== item),
                )
              }
            />
          </div>
        ))}
        <input
          value={newItem}
          onChange={e => setNewItem(e.target.value)}
          className={styles.InputField}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              setCurrentItems([...currentItems, newItem]);
              setNewItem('');
            } else if (e.key === 'Escape') {
              setNewItem('');
            }
          }}
          placeholder={currentItems.length > 0 ? '' : 'Add keywords'}
          style={{
            width: `${newItem.length * 9.5}px`,
            minWidth: currentItems.length > 0 ? 30 : 330,
          }}
        />
      </div>
      {newItem.length > 0 && (
        <div
          className={styles.AddButton}
          style={{ width }}
          onClick={() => {
            setCurrentItems([...currentItems, newItem]);
            setNewItem('');
          }}
        >
          <div className={styles.AddContainer}>
            <span className={styles.AddTitle}>Add "{newItem}"</span>
          </div>
        </div>
      )}
    </div>
  );
}
