import classNames from 'classnames';
import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import hrefs from 'src/modules/href';
import { Task } from 'src/modules/task';
import { ReactComponent as Ellipsis } from 'src/pages/DashboardPage/assets/ellipsis.svg';
import { ReactComponent as Star } from 'src/pages/DashboardPage/assets/star.svg';
import { ReactComponent as FullStar } from 'src/pages/DashboardPage/assets/fullstar.svg';
import { ReactComponent as Trash } from 'src/pages/DashboardPage/assets/trash.svg';
import styles from './FileItem.module.scss';
import { useApi } from 'src/api';
import { getProjects } from 'src/redux/selectors/project';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

type Props = {
  file: Task;
  favourited: boolean;
};

export default function FileItem({ file, favourited }: Props): JSX.Element {
  const tabHistory = useHistory();
  const api = useApi();
  const projects = useSelector(getProjects);
  const [openDropdown, setOpendropdown] = useState(false);
  const {
    taskName,
    // versionHistory,
    // history,
    // dateCreated,
    projectId,
    taskId,
    userId,
  } = file;
  // const lastVersionUpdate = versionHistory
  //   ? versionHistory.length > 0
  //     ? versionHistory.slice(-1)[0].date
  //     : null
  //   : null;
  const colour = useMemo(
    () => projects?.find(p => p.projectId === projectId)?.color,
    [projects, projectId],
  );
  // const lastCopyCreated = history
  //   ? history.length > 0
  //     ? history.slice(-1)[0].date
  //     : null
  //   : null;
  // let lastUpdated;
  // if (lastCopyCreated === null && lastVersionUpdate === null) {
  //   lastUpdated = dateCreated;
  // } else if (lastCopyCreated !== null && lastVersionUpdate === null) {
  //   lastUpdated = lastCopyCreated;
  // } else if (lastCopyCreated === null && lastVersionUpdate !== null) {
  //   lastUpdated = lastVersionUpdate;
  // } else if (lastCopyCreated !== null && lastVersionUpdate !== null) {
  //   const copyDate = new Date(lastCopyCreated);
  //   const versionDate = new Date(lastVersionUpdate);
  //   lastUpdated = copyDate > versionDate ? copyDate : versionDate;
  // }
  // const options = {
  //   year: 'numeric',
  //   month: '2-digit',
  //   day: '2-digit',
  // } as const;
  // const date = new Date(lastUpdated as string);
  // const formattedLastUpdate = date.toLocaleDateString('en-GB', options);
  return (
    <div
      className={styles.FileItem}
      onClick={() => tabHistory.push(hrefs.playground({ projectId, taskId }))}
    >
      <div className={styles.Left}>
        <div className={styles.Icon} style={{ color: colour }}>
          F
        </div>
        <div className={styles.Name}>{taskName}</div>
      </div>
      {/* <div className={styles.Middle}>
        <div className={styles.LastUpdated}>{formattedLastUpdate}</div>
      </div> */}
      <div className={styles.Right}>
        <div
          className={styles.IconContainer}
          onClick={e => {
            e.stopPropagation();
            if (favourited) {
              api.removeFavourite({ taskId, projectId, userId });
            } else {
              api.createFavourite({ taskId, projectId, userId });
            }
          }}
        >
          {favourited ? (
            <FullStar className={styles.FullStar} />
          ) : (
            <Star className={classNames(styles.Star)} />
          )}
        </div>
        <OutsideClickHandler onOutsideClick={() => setOpendropdown(false)}>
          <div
            className={styles.IconContainer}
            onClick={e => {
              e.stopPropagation();
              setOpendropdown(!openDropdown);
            }}
          >
            <Ellipsis className={styles.Ellipsis} />
          </div>
          {openDropdown && (
            <div className={styles.Dropdown}>
              <div
                className={styles.DropdownItem}
                onClick={e => {
                  e.stopPropagation();
                  api.deleteTask({ projectId, taskId });
                  setOpendropdown(false);
                }}
              >
                <Trash className={styles.TrashIcon} />
                <span className={styles.ItemText}>Delete File</span>
              </div>
            </div>
          )}
        </OutsideClickHandler>
      </div>
    </div>
  );
}
