import React, { useState } from 'react';
import GenerateButton from '../GenerateButton';
import { filter } from 'src/utils/filter';
import styles from './TaskWrapper.module.scss';
import { TaskPromptProps } from 'src/modules/task/promptProps';
import { Task } from 'src/modules/task';
import { useSelector } from 'react-redux';
import { useApi } from 'src/api';
import { getUserId } from 'src/redux/selectors/auth';
import { languages } from 'src/modules/languages/languages';
import { selectN } from 'src/utils/selectN';
import { getEditorSettings } from 'src/redux/selectors/editorSettings';

type Props = {
  children: JSX.Element;
  setShowTasksModal?: (a: boolean) => void;
  setIsLoading: (a: boolean) => void;
  isLoading: boolean;
  checkString: string;
  width: number;
  task: Task;
  taskPrompt: TaskPromptProps;
  currentInputs: object;
};

export default function TaskWrapper({
  children,
  setShowTasksModal,
  setIsLoading,
  checkString,
  width,
  isLoading,
  task,
  taskPrompt,
  currentInputs,
}: Props): JSX.Element {
  const api = useApi();
  const userId = useSelector(getUserId);
  const editorSettings = useSelector(getEditorSettings);
  const [buttonClicked, setButtonClicked] = useState(false);
  const { taskId, projectId } = task;
  const isProfanity = filter.isProfane(checkString);
  const isAnyEmptyField = Object.values(currentInputs).some(
    fieldText => fieldText.length === 0,
  );
  function onClick() {
    if (userId) {
      setButtonClicked(true);
      if (!isAnyEmptyField) {
        setIsLoading(true);
        api.updateStatistics(userId, taskPrompt.type);
        api
          .generateIdeas({
            taskId,
            projectId,
            promptProps: taskPrompt,
            n: selectN(taskPrompt.type),
            userId,
            language: editorSettings?.language || languages.english,
          })
          .then(() => setIsLoading(false))
          .catch(() => setIsLoading(false));
        api.updateTaskInputs({
          taskId,
          projectId,
          currentInputs,
          taskType: taskPrompt.type,
        });
      }
    }
  }

  return (
    <div className={styles.TaskWrapper}>
      {isProfanity && (
        <div className={styles.Error} style={{ width }}>
          We have detected profanity in your text. You will not be able to
          generate ideas until it has been removed.
        </div>
      )}
      {children}
      <div className={styles.ButtonsContainer}>
        <GenerateButton
          onClick={onClick}
          isDisabled={isLoading || isProfanity}
        />
        {isAnyEmptyField && buttonClicked && (
          <div className={styles.FieldBlankError}>
            You left a field blank. Please fill it in.
          </div>
        )}
        {setShowTasksModal && (
          <button
            className={styles.ReplaceButton}
            onClick={() => setShowTasksModal(true)}
          >
            Replace task
          </button>
        )}
      </div>
    </div>
  );
}
