import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TextArea from 'src/components/TextArea';
import { Task } from 'src/modules/task';
import { BulletPointExpanderProps } from 'src/modules/task/promptProps';
import { taskTypes } from 'src/modules/task/tasktypes';
import { getTask } from 'src/redux/selectors/task';
import InputContainer from '../InputContainer';
import TaskWrapper from '../TaskWrapper';
import styles from './Bulletpoint.module.scss';

type Props = {
  isLoading: boolean;
  setIsLoading: (a: boolean) => void;
  setShowTasksModal?: (a: boolean) => void;
  projectId: string;
  taskId: string;
  width: number;
};

export default function BulletpointExpanderForm({
  isLoading,
  setIsLoading,
  setShowTasksModal,
  projectId,
  taskId,
  width,
}: Props) {
  const task = useSelector(getTask(projectId)(taskId));
  const [bulletPoint, setBulletPoint] = useState('');
  useEffect(() => {
    if (task) {
      if (task.mostRecentTask === taskTypes.bulletPointExpander) {
        const { bulletPoint } = task.currentInput as BulletPointExpanderProps;
        setBulletPoint(bulletPoint);
      }
    }
  }, [task]);
  const currentInputs = {
    bulletPoint,
  };
  const taskPrompt = { type: taskTypes.bulletPointExpander, ...currentInputs };
  return (
    <div className={styles.Caption}>
      <TaskWrapper
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        checkString={bulletPoint}
        setShowTasksModal={setShowTasksModal}
        width={width}
        task={task as Task}
        taskPrompt={taskPrompt}
        currentInputs={currentInputs}
      >
        <InputContainer width={width}>
          <TextArea
            text={bulletPoint}
            setText={setBulletPoint}
            placeholderText='E.g. SEO is important for growing your online business.'
            title='Bullet Point'
            className={styles.TextArea}
          />
        </InputContainer>
      </TaskWrapper>
    </div>
  );
}
