import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Input from 'src/components/Input';
import TextArea from 'src/components/TextArea';
import { Task } from 'src/modules/task';
import { NameGeneratorProps } from 'src/modules/task/promptProps';
import { taskTypes } from 'src/modules/task/tasktypes';
import { getTask } from 'src/redux/selectors/task';
import InputContainer from '../InputContainer';
import TaskWrapper from '../TaskWrapper';
import styles from './Name.module.scss';

type Props = {
  isLoading: boolean;
  setIsLoading: (a: boolean) => void;
  setShowTasksModal?: (a: boolean) => void;
  projectId: string;
  taskId: string;
  width: number;
};

export default function NameGeneratorForm({
  isLoading,
  setIsLoading,
  setShowTasksModal,
  projectId,
  taskId,
  width,
}: Props) {
  const task = useSelector(getTask(projectId)(taskId));
  const [description, setDescription] = useState('');
  const [keywords, setKeywords] = useState<string[]>([]);

  useEffect(() => {
    if (task) {
      if (task.mostRecentTask === taskTypes.nameGenerator) {
        const {
          description,
          keywords,
        } = task.currentInput as NameGeneratorProps;
        setDescription(description);
        setKeywords(keywords);
      }
    }
  }, [task]);
  const currentInputs = {
    description,
    keywords,
  };
  const taskPrompt = { type: taskTypes.nameGenerator, ...currentInputs };
  return (
    <div className={styles.Facebook}>
      <TaskWrapper
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        checkString={description + ' ' + keywords.join()}
        setShowTasksModal={setShowTasksModal}
        width={width}
        task={task as Task}
        taskPrompt={taskPrompt}
        currentInputs={currentInputs}
      >
        <>
          <InputContainer width={width}>
            <TextArea
              title='Describe your brand/product'
              placeholderText='Add a description'
              text={description}
              setText={setDescription}
              className={styles.TextArea}
            />
          </InputContainer>
          <InputContainer width={width}>
            <Input
              title='Add brand/product keywords'
              currentItems={keywords}
              setCurrentItems={setKeywords}
              width={width}
            />
          </InputContainer>
        </>
      </TaskWrapper>
    </div>
  );
}
