import React, { useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { ReactComponent as CloseIcon } from 'src/assets/generalClose.svg';
import FormInput from '../../FormInput';
import styles from './NewProjectModal.module.scss';
import { useApi } from 'src/api';
import { getUserId } from 'src/redux/selectors/auth';
import ModalWrapper from '../../ModalWrapper';

type Props = {
  setNewProjectModal: (a: boolean) => void;
};

export default function NewProjectModal({
  setNewProjectModal,
}: Props): JSX.Element {
  const [projectName, setProjectName] = useState('');
  const userId = useSelector(getUserId);
  const api = useApi();
  const isDisabled = projectName.length === 0;
  function createProject() {
    if (userId) {
      api.createProject({ userId, projectName });
      setNewProjectModal(false);
    }
  }
  return (
    <ModalWrapper setShowChildrenModal={setNewProjectModal}>
      <div className={styles.NewProjectModal}>
        <div className={styles.Header}>
          <div className={styles.ModalTitle}>Create New Project</div>
          <CloseIcon
            className={styles.Close}
            onClick={() => setNewProjectModal(false)}
          />
        </div>
        <hr className={styles.Hr} />
        <FormInput
          input={projectName}
          onChange={e => setProjectName(e.target.value)}
          password={false}
          className={styles.FormInput}
          onKeyDown={e => {
            if (e.key === 'Enter' && !isDisabled) {
              createProject();
            }
          }}
          placeholderText='Add a project name'
          autoFocus
        />
        <hr className={styles.Hr} />
        <div className={styles.ButtonContainer}>
          <button
            className={classNames(isDisabled && styles.Disabled, styles.Button)}
            disabled={isDisabled}
            onClick={createProject}
          >
            Create
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}
