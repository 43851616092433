import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FacebookAdProps } from 'src/modules/task/promptProps';
import { taskTypes, TaskTypes } from 'src/modules/task/tasktypes';
import { getTask } from 'src/redux/selectors/task';
import styles from './ContentContainer.module.scss';
import { ReactComponent as Globe } from 'src/assets/planet-earth.svg';
import { extractFacebookParts, extractGoogleParts } from './utils';

type Props = {
  taskType: TaskTypes;
  text: string;
};

export default function ContentContainer({ taskType, text }: Props) {
  const params: { projectId: string; taskId: string } = useParams();
  const { projectId, taskId } = params;
  const task = useSelector(getTask(projectId)(taskId));
  const inputs = task?.currentInput as FacebookAdProps;
  switch (taskType) {
    case taskTypes.facebookAd: {
      const { primaryText, headline, linkDescription } = extractFacebookParts(
        text,
      );
      return (
        <div className={styles.FacebookAd}>
          <div className={styles.AvatarContainer}>
            <div className={styles.Circle} />
            <div className={styles.InfoContainer}>
              <div className={styles.Name}>{inputs.companyName}</div>
              <div className={styles.SponsoredContainer}>
                <div className={styles.Sponsored}>Sponsored ·</div>
                <Globe className={styles.Globe} />
              </div>
            </div>
          </div>
          <div className={styles.Text}>{primaryText}</div>
          <div className={styles.Image} />
          <div className={styles.FacebookHeadline}>{headline}</div>
          <div className={styles.Text}>{linkDescription}</div>
        </div>
      );
    }
    case taskTypes.googleAd: {
      const { headline, description } = extractGoogleParts(text);
      return (
        <div className={styles.Ad}>
          <div className={styles.GoogleAd}>Ad</div>
          <div className={styles.GoogleHeadline}>{headline}</div>
          <div className={styles.Text}>{description}</div>
        </div>
      );
    }
    default: {
      // sometimes the model generates a string that starts with an empty space. Use trim() to remove whitespaces.
      return <div className={styles.Text}>{text.trim()}</div>;
    }
  }
}
