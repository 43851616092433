import React from 'react';
import { useHistory } from 'react-router-dom';
import Icon from 'src/assets/android-chrome-512x512.png';
import href from 'src/modules/href';
import styles from './Logo.module.scss';

export default function Logo() {
  const history = useHistory();
  return (
    <div
      className={styles.LogoContainer}
      onClick={() => history.push(href.landing())}
    >
      <img src={Icon} alt='icon' className={styles.Icon} />
      <span className={styles.Logo}>copymuse</span>
    </div>
  );
}
