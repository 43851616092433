import { Values } from '../types';
import { taskTypes } from './tasktypes';

export const tabs = {
  general: 'General',
  ecommerce: 'E-commerce',
  socialmedia: 'Social Media',
  digitalads: 'Digital Ads',
  blogcontent: 'Blog Content',
  websitecontent: 'Website Content',
  email: 'Email',
} as const;
export type Tabs = Values<typeof tabs>;

type TaskProps = {
  [tab in Tabs]: {
    title: string;
    description: string;
  }[];
};

export const tasks: TaskProps = {
  [tabs.general]: [
    {
      title: taskTypes.bulletPointExpander,
      description: 'Turn thoughts into paragraphs.',
    },
    {
      title: taskTypes.contentRewriter,
      description: 'Not happy with how something is written?',
    },
    {
      title: taskTypes.listile,
      description: 'Create a list of content ideas according to a topic.',
    },
    { title: taskTypes.summarizer, description: 'Summarize a piece of text' },
    {
      title: taskTypes.simplify,
      description: 'Explain it so that a second grader understands you',
    },
    {
      title: taskTypes.nameGenerator,
      description: 'Create a name for your next product or company.',
    },
    {
      title: taskTypes.announcement,
      description: 'Create a personal or company announcement.',
    },
    {
      title: taskTypes.changeTone,
      description: 'Change the tone of your content',
    },
    { title: taskTypes.instruct, description: 'Instruct our AI what to do' },
  ],
  [tabs.ecommerce]: [
    {
      title: taskTypes.productDescription,
      description: 'Create the perfect description for your product.',
    },
  ],
  [tabs.socialmedia]: [
    {
      title: taskTypes.caption,
      description: 'Brainstorm facebook, instagram and twitter post ideas.',
    },
  ],
  [tabs.digitalads]: [
    {
      title: taskTypes.facebookAd,
      description: 'Create the perfect header and description.',
    },
    {
      title: taskTypes.instagramAd,
      description:
        'Increase your click-through rate by creating the perfect instagram ad.',
    },
    {
      title: taskTypes.googleAd,
      description: 'Craft the most enticing headline and description.',
    },
    {
      title: taskTypes.linkedInAd,
      description: 'Increase your LinkedIn ad conversions.',
    },
  ],
  [tabs.blogcontent]: [
    {
      title: taskTypes.blogIdea,
      description: 'Struggling to come up with good blog ideas? ',
    },
    {
      title: taskTypes.blogIntro,
      description:
        'The hardest step is always the first one. Make it an easy one.',
    },
    {
      title: taskTypes.blogOutline,
      description: 'Create the outline of your next blog.',
    },
  ],
  [tabs.websitecontent]: [
    {
      title: taskTypes.heroText,
      description:
        'Increase the conversation rate of your landing page with the perfect hero text.',
    },
    {
      title: taskTypes.subheaderText,
      description:
        'Subheaders teach users how your product provides value. Make it a good one.',
    },
    {
      title: taskTypes.metaDescription,
      description:
        "Optimize your site's SEO with the perfect meta description.",
    },
    {
      title: taskTypes.microcopy,
      description:
        'Create small bits of copy in your UI, such as labels and error messages.',
    },
  ],
  [tabs.email]: [
    {
      title: taskTypes.emailSubjectLine,
      description:
        'Increase your email click-through rate with attention-grabbing subject lines.',
    },
  ],
};
