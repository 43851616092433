import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from 'src/assets/generalClose.svg';
import styles from './NewTask.module.scss';
import { Tabs, tabs, tasks } from 'src/modules/task/tabs';
import { TaskTypes } from 'src/modules/task/tasktypes';
import ModalWrapper from '../../ModalWrapper';

type Props = {
  setTaskType: (a: TaskTypes) => void;
  // used to set whether to keep modal open or closed.
  setShowModal: (a: boolean) => void;
};

export default function NewTaskPlaygroundModal({
  setShowModal,
  setTaskType,
}: Props): JSX.Element {
  const [activeTab, setActiveTab] = useState<Tabs>(tabs.general);
  const [selectedTask, setSelectedTask] = useState('');
  const isActive = !!selectedTask;
  const currentTasks = tasks[activeTab];
  return (
    <ModalWrapper setShowChildrenModal={setShowModal}>
      <div className={styles.NewTaskModal}>
        <div className={styles.Top}>
          <div className={styles.Header}>
            <div className={styles.Title}>Select a task</div>
            <CloseIcon
              className={styles.Close}
              onClick={() => setShowModal(false)}
            />
          </div>
          <hr className={styles.Hr} />
          <div className={styles.Tabs}>
            {Object.values(tabs).map(title => (
              <div
                className={classNames(
                  styles.Tab,
                  title === activeTab && styles.ActiveTab,
                )}
                onClick={() => setActiveTab(title)}
                key={title}
              >
                {title}
              </div>
            ))}
          </div>
          <div className={styles.Tasks}>
            {currentTasks.map(task => (
              <div
                className={classNames(
                  styles.Task,
                  selectedTask === task.title && styles.SelelctedTask,
                )}
                onClick={() => setSelectedTask(task.title)}
                key={task.description}
              >
                <div className={styles.TaskTitle}>{task.title}</div>
                <div className={styles.TaskDescription}>{task.description}</div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.Bottom}>
          <hr className={styles.Hr} />
          <div className={styles.ButtonContainer}>
            <button
              className={styles.SelectButton}
              disabled={!isActive}
              onClick={() => {
                setTaskType(selectedTask as TaskTypes);
                setShowModal(false);
              }}
            >
              Select Task
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}
