import React from 'react';
import classNames from 'classnames';
import styles from './TestimonialCard.module.scss';

type Props = {
  className?: string;
  testimonial: string;
  avatarImg: string;
  name: string;
  job: string;
};

export default function TestimonialCard({
  className,
  testimonial,
  avatarImg,
  name,
  job,
}: Props) {
  return (
    <div className={classNames(styles.TestimonialCard, className)}>
      <div className={styles.Testimonial}>{testimonial}</div>
      <div className={styles.AuthorData}>
        <img src={avatarImg} className={styles.Avatar} alt='avatar' />
        <div className={styles.AuthorInfo}>
          <div className={styles.AuthorName}>{name}</div>
          <div className={styles.AuthorJob}>{job}</div>
        </div>
      </div>
    </div>
  );
}
