import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from 'src/assets/generalClose.svg';
import ModalWrapper from 'src/components/ModalWrapper';
import { useApi } from 'src/api';
import styles from './AddTeammate.module.scss';
import FormInput from 'src/components/FormInput';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/redux/selectors/auth';
import { getProjects } from 'src/redux/selectors/project';
import classNames from 'classnames';
import Spinner from 'src/components/Spinner';
import { colours } from 'src/modules/colours';
import { getSharedFoldersAdmin } from 'src/redux/selectors/shared';

type Props = {
  setShowModal: (a: boolean) => void;
};

export default function AddTeammateModal({ setShowModal }: Props): JSX.Element {
  const userId = useSelector(getUserId);
  const projects = useSelector(getProjects);
  const sharedFolders = useSelector(getSharedFoldersAdmin);
  const api = useApi();
  const [email, setEmail] = useState('');
  const [emailDNE, setEmailDNE] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  useEffect(() => {
    if (userId) {
      api.getProjects(userId);
    }
  }, [api, userId]);

  return (
    <ModalWrapper setShowChildrenModal={setShowModal}>
      <div className={styles.Modal}>
        <div className={styles.Header}>
          <div className={styles.Title}>Add teammate</div>
          <CloseIcon
            className={styles.Close}
            onClick={() => setShowModal(false)}
          />
        </div>
        <hr className={styles.Hr} />
        <div className={styles.Subtitle}>
          <div className={styles.Projectsubtitle}>Add email address</div>
          <div className={styles.Description}>
            The email address must belong to an existing user.
          </div>
          {emailDNE && (
            <div className={styles.ErrorMessage}>
              A user with this email address does not exist.
            </div>
          )}
          <FormInput
            input={email}
            onChange={e => setEmail(e.target.value)}
            password={false}
            className={styles.FormInput}
            placeholderText='Email address'
            autoFocus
          />
        </div>

        <div className={styles.Subtitle}>
          <div className={styles.Projectsubtitle}>
            Add folders to share with new member
          </div>
          <div className={styles.Description}>
            Click on the folders that you want to make available to your team
            member.
          </div>
          {projects && (
            <div className={styles.ProjectsList}>
              {projects.map((p, i) => (
                <div
                  className={classNames(
                    styles.Project,
                    selectedProjects.includes(p.projectId) && styles.Selected,
                  )}
                  key={i}
                  onClick={() => {
                    if (!selectedProjects.includes(p.projectId)) {
                      setSelectedProjects([...selectedProjects, p.projectId]);
                    }
                  }}
                >
                  {p.name}
                </div>
              ))}
            </div>
          )}
          <div className={styles.SelectedProjects}>
            {selectedProjects.map((p, i) => (
              <div className={classNames(styles.SelectedProject)} key={i}>
                {projects?.find(pr => pr.projectId === p)?.name}
                <CloseIcon
                  className={styles.SelectedCloseIcon}
                  onClick={() =>
                    setSelectedProjects(selectedProjects.filter(sp => sp !== p))
                  }
                />
              </div>
            ))}
          </div>
        </div>
        <button
          disabled={!email || selectedProjects.length === 0}
          className={styles.AddMember}
          onClick={() => {
            if (userId) {
              setIsLoading(true);
              api
                .getUserByEmail(email)
                .then(user => {
                  selectedProjects.forEach(selectedFolderId => {
                    if (
                      sharedFolders?.find(f => f.folderId === selectedFolderId)
                    ) {
                      api.addMemberToSharedFolder({
                        adminId: userId,
                        newMemberId: user.userId,
                        folderId: selectedFolderId,
                      });
                    } else {
                      api.createSharedFolder({
                        adminId: userId,
                        newMemberId: user.userId,
                        folderId: selectedFolderId,
                      });
                    }
                  });
                  setIsLoading(false);
                  setShowModal(false);
                  setEmailDNE(false);
                })
                .catch(() => {
                  setEmailDNE(true);
                  setIsLoading(false);
                });
            }
          }}
        >
          {isLoading ? (
            <Spinner color={colours.white} size={12} />
          ) : (
            'Add Member'
          )}
        </button>
      </div>
    </ModalWrapper>
  );
}
