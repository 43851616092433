import { Generation } from 'src/modules/statistics';
import { TaskTypes } from 'src/modules/task/tasktypes';
import { createActionsBuilder, ExtractActions } from './utils/request';

type GetStatisticsMeta = { userId: string };

type GetStatisticsResponse = Generation;

export const getStatistics = createActionsBuilder<
  GetStatisticsMeta,
  GetStatisticsResponse
>()(
  'GET_STATISTICS_PENDING',
  'GET_STATISTICS_SUCCESS',
  'GET_STATISTICS_FAILURE',
);
export type GetStatisticsActions = ExtractActions<typeof getStatistics>;

type UpdateStatisticsMeta = {
  type: TaskTypes;
  userId: string;
  generatedWordsCount?: number;
};

type UpdateStatisticsResponse = Generation;

export const updateStatistics = createActionsBuilder<
  UpdateStatisticsMeta,
  UpdateStatisticsResponse
>()(
  'UPDATE_STATISTICS_PENDING',
  'UPDATE_STATISTICS_SUCCESS',
  'UPDATE_STATISTICS_FAILURE',
);
export type UpdateStatisticsActions = ExtractActions<typeof updateStatistics>;

type PlagiarismStatisticsMeta = { userId: string };

type PlagiarismStatisticsResponse = string;

export const plagiarismStatistics = createActionsBuilder<
  PlagiarismStatisticsMeta,
  PlagiarismStatisticsResponse
>()(
  'PLAGIARISM_STATISTICS_UPDATES_PENDING',
  'PLAGIARISM_STATISTICS_UPDATES_SUCCESS',
  'PLAGIARISM_STATISTICS_UPDATES_FAILURE',
);
export type PlagiarismStatisticsActions = ExtractActions<
  typeof plagiarismStatistics
>;

export type Actions =
  | UpdateStatisticsActions
  | GetStatisticsActions
  | PlagiarismStatisticsActions;
