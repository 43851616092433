import { Reducer } from 'redux';
import * as auth from 'src/redux/actions/auth';
import * as user from 'src/redux/actions/user';

/** Redux auth state. */
export interface State {
  token: string | null;
  isAuthenticated: boolean;
  userId: string | null;
  error?: string;
}

const initialState: State = {
  token: null,
  isAuthenticated: false,
  userId: null,
};

/**
 * Reducer for the auth state.
 * @param state Current auth state.
 * @param action Redux action.
 * @returns New auth state.
 */

const authReducer: Reducer<State, auth.Actions | user.Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case auth.signUp.types.success:
    case auth.signUpAppsumo.types.success:
      return {
        token: action.response.token,
        isAuthenticated: action.response.isAuthenticated,
        userId: action.response.userId,
      };
    case auth.signUp.types.failure:
      return {
        ...state,
        error: action.error.message,
      };
    case auth.signIn.types.success:
      return {
        token: action.response.token,
        isAuthenticated: action.response.isAuthenticated,
        userId: action.response.userId,
      };
    case auth.signIn.types.failure:
      return {
        ...state,
        error: action.error.message,
      };
    case auth.thirdPartySignIn.types.success:
      return {
        token: action.response.token,
        isAuthenticated: action.response.isAuthenticated,
        userId: action.response.userId,
      };
    case auth.refreshToken.types.success:
      return {
        ...state,
        token: action.response,
      };
    case auth.resetPassword.types.success:
      return {
        token: action.response.token,
        isAuthenticated: action.response.isAuthenticated,
        userId: action.response.userId,
      };
    case user.getUserByResetId.types.success:
      return {
        ...state,
        token: action.response.token,
      };
    case auth.logout.types.success:
      return {
        token: action.response.token,
        isAuthenticated: action.response.isAuthenticated,
        userId: action.response.userId,
      };
    case user.deleteUser.types.success:
      return {
        token: action.response.token,
        isAuthenticated: action.response.isAuthenticated,
        userId: action.response.userId,
      };
    default:
      return state;
  }
};

export default authReducer;
