import { Reducer } from 'redux';
import { Generation } from 'src/modules/statistics';
import * as statistics from 'src/redux/actions/statistics';

/** Redux statistics state. */
export interface State {
  statistics?: Generation;
}

const initialState: State = {};

/**
 * Reducer for the statistics state.
 * @param state Current statistics state.
 * @param action Redux action.
 * @returns New statistics state.
 */
const statisticsReducer: Reducer<State, statistics.Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case statistics.updateStatistics.types.success:
    case statistics.getStatistics.types.success:
      return {
        ...state,
        statistics: action.response,
      };
    default:
      return state;
  }
};

export default statisticsReducer;
