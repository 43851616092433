import * as shared from 'src/redux/actions/shared';
import { Reducer } from 'redux';
import { SharedFolder } from 'src/modules/shared';
import { Project } from 'src/modules/Project';

export interface State {
  byId?: SharedFolder[];
  byAdmin?: SharedFolder[];
  projects: Project[];
}

const initialState: State = {
  projects: [],
};

/**
 * Reducer for the shared folder state.
 * @param state Current shared folder state.
 * @param action Redux action.
 * @returns New shared folder state.
 */

const sharedFoldersReducer: Reducer<State, shared.Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case shared.createSharedFolders.types.success:
      return {
        ...state,
        byAdmin: state.byAdmin
          ? [...state.byAdmin, action.response]
          : [action.response],
      };
    case shared.getSharedFolders.types.success:
      return {
        ...state,
        byId: action.response,
      };

    case shared.getSharedFoldersAdmin.types.success:
      return {
        ...state,
        byAdmin: action.response,
      };

    case shared.addMember.types.success: {
      const { folderId, newMemberId } = action.meta;

      const updatedFolders = state.byAdmin?.map(f =>
        f.folderId === folderId
          ? { ...f, members: [...f.members, newMemberId] }
          : f,
      );

      return { ...state, byAdmin: updatedFolders };
    }
    case shared.removeMember.types.success: {
      const { folderId, memberId } = action.meta;

      const updatedFolders = state.byAdmin?.map(f =>
        f.folderId === folderId
          ? { ...f, members: f.members.filter(m => m !== memberId) }
          : f,
      );

      return { ...state, byAdmin: updatedFolders };
    }
    case shared.getSharedProject.types.success: {
      return {
        ...state,
        projects: [
          ...state.projects,
          ...(!state.projects.find(
            p => p.projectId === action.response.projectId,
          )
            ? [action.response]
            : []),
        ],
      };
    }
    default:
      return state;
  }
};

export default sharedFoldersReducer;
