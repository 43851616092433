import React, { useState } from 'react';
import { ByMinuteVersionHistory } from 'src/redux/reducers/task';
import styles from './VersionHistoryItem.module.scss';
import { ReactComponent as ChevronDown } from 'src/assets/chevronDownSilver.svg';
import classNames from 'classnames';
import { PlaygroundTabTypes, tabs } from 'src/modules/playground';
import { removeHtmlTags } from 'src/utils/html';

type Props = {
  item: ByMinuteVersionHistory;
  setShowContent: (a: string) => void;
  setEditorContent: (a: string) => void;
  setActiveTab: (a: PlaygroundTabTypes) => void;
  setSelectedVersionHistoryDate: (a: string) => void;
  selectedVersionHistoryDate: string;
};

export default function VersionHistoryItem({
  item,
  setShowContent,
  setEditorContent,
  setActiveTab,
  setSelectedVersionHistoryDate,
  selectedVersionHistoryDate,
}: Props): JSX.Element {
  const [showItems, setShowItems] = useState(false);
  return (
    <div className={styles.VersionHistoryItem}>
      <div className={styles.Header}>
        <div className={styles.HistoryDate}>{item.date}</div>
        <ChevronDown
          className={classNames(
            styles.Chevron,
            showItems && styles.UpsideChevron,
          )}
          onClick={() => setShowItems(!showItems)}
        />
      </div>
      {showItems && (
        <div className={styles.Items}>
          {item.history.map((vh, i) => (
            <div
              key={i}
              className={classNames(styles.Item)}
              onClick={() => {
                setShowContent(vh.editorContent);
                setSelectedVersionHistoryDate(
                  new Date(vh.date).toLocaleString(),
                );
              }}
            >
              <div>{new Date(vh.date).toLocaleString()}</div>
              <div
                className={styles.Restore}
                onClick={() => {
                  setEditorContent(removeHtmlTags(vh.editorContent));
                  setActiveTab(tabs.results);
                  setShowContent('');
                }}
              >
                Restore Version
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
