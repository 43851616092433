import { taskTypes, TaskTypes } from 'src/modules/task/tasktypes';

export function selectN(taskType: TaskTypes): number {
  return taskType === taskTypes.productDescription ||
    taskType === taskTypes.summarizer ||
    taskType === taskTypes.blogIntro ||
    taskType === taskTypes.blogOutline ||
    taskType === taskTypes.bulletPointExpander ||
    taskType === taskTypes.contentRewriter ||
    taskType === taskTypes.simplify ||
    taskType === taskTypes.changeTone ||
    taskTypes.instruct === taskType ||
    taskType === taskTypes.listile
    ? 3
    : 5;
}
