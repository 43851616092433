export function getSelectionDimensions() {
  var sel = document.selection,
    range;
  var width = 0,
    height = 0;
  if (sel) {
    if (sel.type !== 'Control') {
      range = sel.createRange();
      width = range.boundingWidth;
      height = range.boundingHeight;
    }
  } else if (window.getSelection) {
    sel = window.getSelection();
    if (sel.rangeCount) {
      range = sel.getRangeAt(0).cloneRange();
      if (range.getBoundingClientRect) {
        var rect = range.getBoundingClientRect();
        width = rect.right - rect.left;
        height = rect.bottom - rect.top;
      }
    }
  }
  return { width: width, height: height };
}
