import { Authentication } from 'src/modules/authentication';
import { createActionsBuilder, ExtractActions } from './utils/request';

type SignUpMeta = {
  email: string;
};
type AuthResponse = Authentication;

export const signUp = createActionsBuilder<SignUpMeta, AuthResponse>()(
  'SIGNUP_PENDING',
  'SIGNUP_SUCCESS',
  'SIGNUP_FAILURE',
);
export type SignUpActions = ExtractActions<typeof signUp>;

type SignUpAppsumoMeta = {
  email: string;
};

export const signUpAppsumo = createActionsBuilder<
  SignUpAppsumoMeta,
  AuthResponse
>()(
  'SIGNUP_APPSUMO_PENDING',
  'SIGNUP_APPSUMO_SUCCESS',
  'SIGNUP_APPSUMO_FAILURE',
);
export type SignUpAppsumoActions = ExtractActions<typeof signUpAppsumo>;

type SignInMeta = {
  email: string;
};

export const signIn = createActionsBuilder<SignInMeta, AuthResponse>()(
  'SIGNIN_PENDING',
  'SIGNIN_SUCCESS',
  'SIGNIN_FAILURE',
);
export type SignInActions = ExtractActions<typeof signIn>;

type ThirdPartySignInMeta = { email: string };

export const thirdPartySignIn = createActionsBuilder<
  ThirdPartySignInMeta,
  AuthResponse
>()(
  'THIRD_PARTY_SIGNIN_PENDING',
  'THIRD_PARTY_SIGNIN_SUCCESS',
  'THIRD_PARTY_SIGNIN_FAILURE',
);
export type ThirdPartySignInActions = ExtractActions<typeof thirdPartySignIn>;

type RefreshTokenMeta = {
  userId: string;
};

export const refreshToken = createActionsBuilder<RefreshTokenMeta, string>()(
  'REFRESH_TOKEN_PENDING',
  'REFRESH_TOKEN_SUCCESS',
  'REFRESH_TOKEN_FAILURE',
);
export type RefreshTokenActions = ExtractActions<typeof refreshToken>;

type ResetPasswordMeta = {
  email: string;
};

export const resetPassword = createActionsBuilder<
  ResetPasswordMeta,
  AuthResponse
>()(
  'RESET_PASSWORD_PENDING',
  'RESET_PASSWORD_SUCCESS',
  'RESET_PASSWORD_FAILURE',
);
export type ResetPasswordActions = ExtractActions<typeof resetPassword>;

type UpdateResetPasswordMeta = {
  resetId: string;
};

type Response = string;

export const updateResetPassword = createActionsBuilder<
  UpdateResetPasswordMeta,
  Response
>()(
  'UPDATE_RESET_PASSWORD_PENDING',
  'UPDATE_RESET_PASSWORD_SUCCESS',
  'UPDATE_RESET_PASSWORD_FAILURE',
);
export type UpdateResetPasswordActions = ExtractActions<
  typeof updateResetPassword
>;

export const logout = createActionsBuilder<{}, Authentication>()(
  'LOGOUT_PENDING',
  'LOGOUT_SUCCESS',
  'LOGOUT_FAILURE',
);
export type LogoutActions = ExtractActions<typeof logout>;

type EmailConfirmationMeta = {
  email: string;
  link: string;
};

export const emailConfirmation = createActionsBuilder<
  EmailConfirmationMeta,
  boolean
>()(
  'EMAIL_CONFIRMATION_PENDING',
  'EMAIL_CONFIRMATION_SUCCESS',
  'EMAIL_CONFIRMATION_FAILURE',
);
export type EmailConfirmationActions = ExtractActions<typeof emailConfirmation>;

type GetActivationCodeMeta = {
  email: string;
};

type GetActivationCodeResponse = {
  code: number;
  expiryDate: string;
  expired: boolean;
  id: string;
};

export const getActivationCode = createActionsBuilder<
  GetActivationCodeMeta,
  GetActivationCodeResponse
>()(
  'GET_ACTIVATION_CODE_PENDING',
  'GET_ACTIVATION_CODE_SUCCESS',
  'GET_ACTIVATION_CODE_FAILURE',
);
export type GetActivationCodeActions = ExtractActions<typeof getActivationCode>;

type ExpireActivationCodeMeta = {
  id: string;
};

type ExpireActivationCodeResponse = string;

export const expireActivationCode = createActionsBuilder<
  ExpireActivationCodeMeta,
  ExpireActivationCodeResponse
>()(
  'EXPIRE_ACTIVATION_CODE_PENDING',
  'EXPIRE_ACTIVATION_CODE_SUCCESS',
  'EXPIRE_ACTIVATION_CODE_FAILURE',
);
export type ExpireActivationCodeActions = ExtractActions<
  typeof expireActivationCode
>;

export type Actions =
  | SignUpActions
  | SignUpAppsumoActions
  | SignInActions
  | RefreshTokenActions
  | ResetPasswordActions
  | UpdateResetPasswordActions
  | LogoutActions
  | EmailConfirmationActions
  | GetActivationCodeActions
  | ThirdPartySignInActions
  | ExpireActivationCodeActions;
