import { Project } from 'src/modules/Project';
import { SharedFolder } from 'src/modules/shared';
import { RootState } from 'src/redux/reducers';

/**
 * Only consume the parts of the store that we need.
 */
type State = Pick<RootState, 'sharedFolders'>;

export const getSharedFolders = (state: State): SharedFolder[] | undefined =>
  state.sharedFolders.byId;

export const getSharedFoldersAdmin = (
  state: State,
): SharedFolder[] | undefined => state.sharedFolders.byAdmin;

export const getSharedProjects = (state: State): Project[] =>
  state.sharedFolders.projects;
