import { Favourite } from 'src/modules/favourites';
import { createActionsBuilder, ExtractActions } from './utils/request';

type GetFavouritesMeta = { userId: string };
type GetFavouritesResponse = Favourite[];

export const getFavourites = createActionsBuilder<
  GetFavouritesMeta,
  GetFavouritesResponse
>()(
  'GET_FAVOURITES_PENDING',
  'GET_FAVOURITES_SUCCESS',
  'GET_FAVOURITES_FAILURE',
);
export type GetFavouritesActions = ExtractActions<typeof getFavourites>;

type CreateFavouriteMeta = Favourite;
type CreateFavouriteResponse = string;

export const createFavourite = createActionsBuilder<
  CreateFavouriteMeta,
  CreateFavouriteResponse
>()(
  'CREATE_FAVOURITE_PENDING',
  'CREATE_FAVOURITE_SUCCESS',
  'CREATE_FAVOURITE_FAILURE',
);
export type CreateFavouriteActions = ExtractActions<typeof createFavourite>;

type RemoveFavouriteMeta = Favourite;
type RemoveFavouriteResponse = string;

export const removeFavourite = createActionsBuilder<
  RemoveFavouriteMeta,
  RemoveFavouriteResponse
>()(
  'REMOVE_FAVOURITE_PENDING',
  'REMOVE_FAVOURITE_SUCCESS',
  'REMOVE_FAVOURITE_FAILURE',
);
export type RemoveFavouriteActions = ExtractActions<typeof removeFavourite>;

export type Actions =
  | GetFavouritesActions
  | CreateFavouriteActions
  | RemoveFavouriteActions;
