export enum DeploymentEnv {
  Dev = 'dev',
  Prod = 'prod',
}

export function determineDeploymentEnv(
  nodeEnv: typeof process['env']['NODE_ENV'],
): DeploymentEnv {
  if (nodeEnv === 'production') {
    return DeploymentEnv.Prod;
  }
  return DeploymentEnv.Dev;
}

export const DEPLOYMENT_ENV = determineDeploymentEnv(process.env.NODE_ENV);

/**
 * Use the NODE_ENV and REACT_APP_STAGE environment variables to select a value appropriate for
 * dev, test, staging and production environments, defaulting to the local value.
 *
 * @param devValue used in dev mode and as a fallback
 * @param prodValue used in production
 */
export function pickValueForDeploymentEnv(devValue: any, prodValue: any): any {
  switch (DEPLOYMENT_ENV) {
    case DeploymentEnv.Prod:
      return prodValue;
    default:
      return devValue;
  }
}
