import { Reducer } from 'redux';
import { PlagiarismResponse } from 'src/modules/api/responseTypes';
import * as plagiarism from 'src/redux/actions/plagiarism';

/** Redux project state. */
export interface State {
  report?: PlagiarismResponse;
}

const initialState: State = {};

/**
 * Reducer for the plagiarism state.
 * @param state Current plagiarism state.
 * @param action Redux action.
 * @returns New plagiarism state.
 */
const projectReducer: Reducer<State, plagiarism.Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case plagiarism.generatePlagiarismReport.types.success:
      return {
        ...state,
        report: action.response,
      };
    default:
      return state;
  }
};

export default projectReducer;
