import { EditorSettings } from 'src/modules/editorSetttings';

import { RootState } from 'src/redux/reducers';

/**
 * Only consume the parts of the store that we need.
 */
type State = Pick<RootState, 'editorSettings'>;

/**
 * This selector gets the ltd stats.
 */
export const getEditorSettings = (state: State): EditorSettings | undefined =>
  state.editorSettings && state.editorSettings.editorSettings;
